import React, { useEffect, useState} from 'react'
import './Festival.css';
import { Link } from "react-router-dom";
import arrowimg from './image/arrow-icon.png';
import topimg from './image/arrow-up-circle.png';

import { useContext } from "react";
import { Context } from "../../utils/context";
const Festival = () => {
    

    const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);
    const scrollToTop = () => window.scrollTo({ top: 200, behavior: "smooth" });
    const [festivals, setFestival] = useState();
   

    const currentYear = new Date().getFullYear();
    const months = [];

    for (let i = 0; i < 12; i++) {
    const monthName = new Date(currentYear, i, 1).toLocaleString('default', { month: 'long' });
    months.push(monthName + ', ' + currentYear);
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        console.log(section);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

   const getFestivals = async () => {
        const res = await getData('/festivalCalendar');

        
        if(res?.success){
            setFestival(Object.entries(res.data));
            console.log(Object.entries(res.data), 'festivals');
        }
    };

    useEffect(() => {
            getFestivals();
    }, []);
    return (
        <>
            <section className='festival-calender desktop-view-festival-calender'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Festival Calendar</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='col-md-11 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Indian Festivals & Holidays</h5>
                                        <p>India is known for celebrating a wide range of festivals the entire year. People have their roots in all these cultural festivals
                                            connected to different religions, deities, beliefs, and old traditions. While some even hold mythological, historical, and social significance, all of them are celebrated with joy and happiness. Moreover, to seek blessings from the deities associated with some of these festivals, people observe fast, while for some they perform charity and prayers. However it may be, each Indian festival is considered an auspicious occasion.
                                            And, to mark all the festivals and occasions in one place, there is the Indian calendar 2023. </p>
                                        <p>To make it easy and accessible for you, Astrotalk has prepared a list of occasions, festivals, and celebrations for 2023, where not only will you find the upcoming holidays and festivals, but also different types of calendars,
                                            prepared precisely on the basis of the planetary changes, tithi, Purnima, Amavasya, and much more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row  mt-5'>

                    {[...Array(parseInt(12))].map(function (value, index) {
                            return (
                                <div className='col-xl-3 col-lg-4 col-md-6' key={index}>
                                <div className='button-calender text-center mb-4'  onClick={() => scrollToSection(months[index])}>
                                    <div className='btn btn-calender'>
                                    {months[index]}
                                    <img src={arrowimg} className='arrow-img' alt={`Arrow for ${months[index]}`} />
                                    </div>
                                </div>
                                </div>
                            );
                      })}
                        
                    </div>
                    <div className="row">
                        <div> <img src={topimg} alt="top-img" className="top_image" onClick={scrollToTop} type="button" />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-10 mx-auto'>
                            <div className='text-holder text-center mb-5'>
                                <h6>Festivals & Holidays Calendar</h6>
                            </div>
                            {festivals?.map((value, index) => (
                                   
                                        <div className='card mb-3' id={months[index]} key={index}>
                                        <div className='card-body'>
                                            <div className='row'>
                                            <div className='col-lg-4 col-md-12'>
                                                <div className='button-calender1 text-center'>
                                                <div className='btn btn-calender'>
                                                    {months[index]}
                                                    <img src={arrowimg} className='arrow-img' alt={`Arrow for ${months[index]}`} />
                                                </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6'>
                                                <div className='text-holder'>
                                                <h5>Day and Date</h5>
                                                {value[1]?.map((val) => (
                                                <p>{(new Date(val?.date?.iso)).toLocaleDateString('en-US', { day: '2-digit', month: 'short' , year : 'numeric' })}</p>
                                                ))}

                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6'>
                                                <div className='text-holder'>
                                                <h5>Festivals</h5>
                                                {value[1]?.map((val) => (
                                                <p>{val?.name}</p>
                                                ))}
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    ))}

                            {/* <div className='card mb-3 ' id='section2'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12'>
                                            <div className='button-calender-december  text-center'>
                                                <div className='btn btn-calender'>FEBRUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                <p>Monday, February 14</p>
                                                <p>Friday, February 18</p>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                <p>Valentine’s Day</p>
                                                <p>Mahashivratri</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            
                        </div>
                    </div>
                </div>
            </section>

            {/* mobile-view-start */}

            <section className='festival-calender mobile-view-festival-calender'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page"> Festival Calendar</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                        <div className='col-md-11 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='heading-holder my-2'>
                                        <h5>Indian Festivals & Holidays</h5>
                                        <p>India is known for celebrating a wide range of festivals the entire year. People have their roots in all these cultural festivals
                                            connected to different religions, deities, beliefs, and old traditions. While some even hold mythological, historical, and social significance, all of them are celebrated with joy and happiness. Moreover, to seek blessings from the deities associated with some of these festivals, people observe fast, while for some they perform charity and prayers. However it may be, each Indian festival is considered an auspicious occasion.
                                            And, to mark all the festivals and occasions in one place, there is the Indian calendar 2023. </p>
                                        <p>To make it easy and accessible for you, Astrotalk has prepared a list of occasions, festivals, and celebrations for 2023, where not only will you find the upcoming holidays and festivals, but also different types of calendars,
                                            prepared precisely on the basis of the planetary changes, tithi, Purnima, Amavasya, and much more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row festival-row mt-5'>

                    {[...Array(parseInt(12))].map(function (value, index) {
                            return (
                        <div className='col-12'>
                            <Link to={`/festival-january/${index}/${months[index]}`}>
                                <div className='button-calender text-center mb-3' >
                                    <div className='btn btn-calender'>{months[index]}<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                            );
                    })}

                            {/* 
                        <div className='col-12'>
                            <Link to='/festival-february'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section1')}>
                                    <div className='btn btn-calender'>FEBRUARY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-march'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section2')}>
                                    <div className='btn btn-calender'>MARCH FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-april'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section3')}>
                                    <div className='btn btn-calender'>APRIL FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-may'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section4')}>
                                    <div className='btn btn-calender'>MAY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>

                        <div className='col-12'>
                            <Link to='/festival-june'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section5')}>
                                    <div className='btn btn-calender'>JUNE FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-july'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section6')}>
                                    <div className='btn btn-calender'>JULY FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12' >
                            <Link to='/festival-august'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section7')}>
                                    <div className='btn btn-calender'>AUGUST FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-september'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section8')}>
                                    <div className='btn btn-calender'>SEPTEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-october'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section9')}>
                                    <div className='btn btn-calender'>OCTOBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-november'>
                                <div className='button-calender text-center mb-3' onClick={() => scrollToSection('section10')}>
                                    <div className='btn btn-calender '>NOVEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div>
                        <div className='col-12'>
                            <Link to='/festival-december'>
                                <div className='button-calender text-center mb-5' onClick={() => scrollToSection('section11')}>
                                    <div className='btn btn-calender'>DECEMBER FESTIVAL 2023<img src={arrowimg} className='arrow-img ' /></div>
                                </div></Link>
                        </div> */}
                    </div>


                </div>
            </section>
        </>
    )
}

export default Festival