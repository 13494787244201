import React, {useEffect, useState} from 'react'
import '../Festival.css'
import { Link, useParams } from "react-router-dom";
import arrowimg from '../image/arrow-icon.png';

import { useContext } from "react";
import { Context } from "../../../utils/context";
const January = () => {

    const { id, month } = useParams();
    const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);
    const scrollToTop = () => window.scrollTo({ top: 200, behavior: "smooth" });
    const [festivals, setFestival] = useState();


    const getFestivals = async () => {
        var month = parseInt(id)+1;
        const res = await getData(`/festivalCalendar?month=${month}`);

        
        if(res?.success){
            setFestival(res.data);
            console.log(res.data, 'festivalsMonth');
        }
    };


    useEffect(() => {
        getFestivals();
}, []);
    return (
        <>
            <section className='festival-calender mobile-view-festival-calender'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mt-3">
                                    <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                    <li className="breadcrumb-item"><Link to='/festival-calender'> Festival Calendar {'>'}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page"> {month}</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-sm-12 '>
                            <div className='text-holder text-center mt-3 mb-5'>
                                <h6>Festivals & Holidays Calendar</h6>
                            </div>

                            <div className='card mb-3'  >
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-12 '>
                                            <div className='button-calender1  text-center mb-5'>
                                                <div className='btn btn-calender'>{month}<img src={arrowimg} className='arrow-img ' /></div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='text-holder '>
                                                <h5>Day and Date</h5>
                                                {festivals?.map((val) => (
                                                <p>{(new Date(val?.date?.iso)).toLocaleDateString('en-US', { day: '2-digit', month: 'short' , year : 'numeric' })}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='text-holder '>
                                                <h5>Festivals </h5>
                                                {festivals?.map((val) => (
                                                <p>{val?.name}</p>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default January