import React ,{ useState, useEffect } from 'react'
import videoimg from "../Video/login_video.mp4";
import astroVideo from "../Video/astroVideo.mp4";
import successgif from "../../home-page/image/success.gif";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';

import { useContext } from "react";
import { Context } from "../../../utils/context";
const Otp = () => {
    const vidRef = useRef();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const [seconds, setSeconds] = useState(60); // Initial value is 60 seconds
    const [isTimerExpired, setIsTimerExpired] = useState(false);
    const [otpERR, setOtpErr] = useState("");

    const { postData } = useContext(Context);

    const handleResendOtp = async (e) => {
        e.preventDefault();

        var mobile = sessionStorage.getItem('astroMobile');

            const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
            //  console.log(randOtp,'random');
            const res = await postData(`/webforgot?email=${mobile}&otp=${randOtp}`);
            // console.log(res,'ResendOTP');
            if (res?.success) {

                
                sessionStorage.setItem('astroOTP',randOtp);
                setOtpErr("");
                // Handle the resend OTP action here
                // You can reset the timer or make the API request to resend OTP
                setSeconds(60); // Reset the timer to 60 seconds
                setIsTimerExpired(false); // Reset the timer expiration flag

            }else{
                navigate('/login');
            }
        
    };


    
    const [otpValues, setOtpValues] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];


    const handleOtpChange = (index, event) => {
        setOtpErr("");
        const inputValue = event.target.value;
        if (/^[0-9]*$/.test(inputValue) || inputValue === '') {
            otpValues[index] = inputValue;
            setOtpValues([...otpValues]);
            if (inputValue !== '' && index < 3) {
                inputRefs[index + 1].current.focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        setOtpErr("");
        if (event.key === 'Backspace' && index > 0 && otpValues[index] === '') {
            otpValues[index - 1] = '';
            setOtpValues([...otpValues]);
            inputRefs[index - 1].current.focus();
        }
    };



    const verifyOTP = async () => {
        var otp = otpValues[0]+otpValues[1]+otpValues[2]+otpValues[3];
        console.log(otp, "OTP");
            if(otp == sessionStorage.getItem('astroOTP')){


                 let token = sessionStorage.getItem('dummyToken');
                sessionStorage.setItem('astrotoken', token);
                
                    // Remove 'dummyToken'
                  
                console.log(token, 'token');
                // Open the modal
                setShowModal(true);

                // Delay navigation by 2 seconds (2000 milliseconds)
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
                
            }else{
                if(otp == ""){
                    setOtpErr("Please Enter OTP..!");
                }else{
                    setOtpErr("OTP Invalid!");
                }
                
            }

    };


    useEffect(() => { 
        vidRef.current.play(); 

        if(sessionStorage.getItem('dummyToken')){
        }else{
            navigate('/login');
        }
        const decrementTimer = () => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else {
                setIsTimerExpired(true);
                sessionStorage.setItem('astroOTP',"");
            }
        };

        const timerInterval = setInterval(decrementTimer, 1000);

        return () => {
            clearInterval(timerInterval);
        };

    }, [seconds]);

    

  
    return (
        <>
            <section className='Login'>
                <div className='container-fluid'>
                    <div className=" banner-slider ">
                        <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                        <video src={astroVideo} ref={vidRef} muted loop className=" video-class1" />
                        <div className='main_login_section'>
                                <div className="login-page ">

                                    <div className="login-container p-4">
                                        <h2 className="text-center mb-4 Login_heading">Verification</h2>
                                        <p className='heading_info'>Enter the Verification Code</p>
                                        <form>
                                            <div className="form-group">
                                              <form action="" className="mt-5">
                                              {/* <div className='row text-center'>
                                                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                <input className="otp" type="text" maxength="1" />
                                                </div>
                                                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                <input className="otp" type="text"  maxlength="1" />
                                                </div>
                                                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                <input className="otp" type="text"  maxlength="1" />
                                                </div>
                                                <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                <input className="otp" type="text"  maxlength="1" />
                                                </div>
                                              </div> */}


                                                <div className='row text-center'>
                                                                {otpValues.map((value, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'
                                                                    >
                                                                        <input
                                                                            className="otp"
                                                                            type="text"
                                                                            maxLength="1"
                                                                            value={value}
                                                                            onChange={(e) => handleOtpChange(index, e)}
                                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                                            ref={inputRefs[index]}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                </form>
                                                
                                               
                                             
                                        
                    
                  
                    
                
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-4 mx-auto'>
                                                    <div className='Otp_timer mx-auto mt-4'>
                                                      {`${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`}
                                                    </div>
                                                </div>
                                            </div>
                                            {isTimerExpired && (
                                                    <div className='text-center mb-3'>
                                                        <button
                                                            className="btn btn-warning mt-4"
                                                            onClick={handleResendOtp}
                                                        >
                                                            Resend OTP
                                                        </button>
                                                    </div>
                                                )}
                                            <div className='text-center mb-3'>
                                            <span className='text text-danger'>{otpERR}</span>
                                                    <button type="button" className="btn btn-primary login_btn text-center mt-4" onClick={verifyOTP}>Verify</button>
                                                

                                            </div>
                                            <div className='text-center'>
                                                <Link to={"/login"} className='link'>
                                                    <p className='backToLogin_txt'> <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> Back to Login Page</p>

                                                </Link>
                                            </div>
                                        </form>
                                    </div>

                                </div>


                                <div className='row below_common_section mt-4'>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>100% </p>
                                        <p className='text-info'>Privacy</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>10000+  </p>
                                        <p className='text-info'>Tap astrologers of India</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'> 
                                    <div className=''>
                                        <p className='data_txt'>3Cr+ </p>
                                        <p className='text-info'>Happy Customers</p>

                                    </div>
                                </div>

                                 </div>
                        </div>
                          


                    </div>
                </div>
            </section>

             {/* **********<!--call Modal start--> ************/}
             <section className="modal-chat">
                <div 
                className= {showModal ? "modal fade show" : "modal fade"}
                id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <img src={successgif} />
                                </div>
                                <div className="text-holder text-center">
                                    <h5>OTP Verified Successfully...!</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}
        </>
    )
}

export default Otp