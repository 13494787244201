import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from "react-router-dom";
import '../wallet.css';
import cardimg from '../image/debit-card.png';
import payatmimg from '../image/Paytm.png';
import upiimg from '../image/UPI-Logo.png';
import debitimg from '../image/creditcard.png';
import phonepayimg from '../image/phonepay.png';
import CheckLottey from '../check-lottey/CheckLottey';
import useRazorpay from "react-razorpay";


import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../../utils/context";
const WalletPayment = () => {
    const navigate = useNavigate();

    const { postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);
    const [Razorpay, openRazorpay] = useRazorpay();
    const [walletData, setWalletData] = useState();
    const [razorid, setRazorId] = useState();
    const [amount, setAmount] = useState();
    const [showModal, setShowModal] = useState(false);

    const handleRazorpay = async (storedValue) => {
      
        console.log(storedValue, "ghjgfjh");
        const options: RazorpayOptions = {
          key: "rzp_test_I05KCFb1v5JJRc",
          amount: storedValue * 100,
          currency: "INR",
          name: "Astro",
          description: "Test Transaction",
          image:
            "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",
    
          handler: async (res) => {
            await setRazorId(res.razorpay_payment_id);
            handlesButtonClick(res);
            console.log(res, "ttttttttttttttttttttttttttttt");
            
          },
          prefill: {
            name: "Piyush Garg",
            email: "youremail@example.com",
            contact: "9999999999",
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#FF7E00",
          },
        };
    
        const rzpay = new Razorpay(options);
        rzpay.open();
    
      };

      const handlesButtonClick = async (e) => {
        var res = "";
        if(walletData?.couponId == ""){
             res = await postData(`/wallet?amount=${Math.round(parseInt(walletData?.amount))}&razorId=${e.razorpay_payment_id}&discount=${walletData?.discountAmount}`);
        }else{
             res = await postData(`/wallet?amount=${Math.round(parseInt(walletData?.amount))}&razorId=${e.razorpay_payment_id}&coupen_code=${walletData?.couponId}&discount=${walletData?.discountAmount}`);
        }
            if (res?.success) {
                console.log(res.data, 'wallet');
                Cookies.remove('astroUserWallet');

                 // Open the modal
                 setShowModal(true);

                 // Delay navigation by 2 seconds (2000 milliseconds)
                 setTimeout(() => {
                    navigate('/wallet');
                 }, 2000);
                

            }else{
                console.log(res, 'fail');
                   
            }
      };

    
    useEffect(() => { 
        if(Cookies.get('astroUserWallet')){
            console.log(JSON.parse(Cookies.get('astroUserWallet')), 'walletcookie');
            setWalletData(JSON.parse(Cookies.get('astroUserWallet')));
                if(JSON.parse(Cookies.get('astroUserWallet'))?.discountPay != 0){
                    setAmount(JSON.parse(Cookies.get('astroUserWallet'))?.discountPay);
                }else{
                    setAmount(JSON.parse(Cookies.get('astroUserWallet'))?.amount);
                }

        }
        
}, []);
    return (
        <>
            <section className='wallet desk-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item"><Link to='/wallet'>Wallet</Link></li>
                                <li className="breadcrumb-item active" >Payment Information</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row wallet-payment mt-5'>
                        <div className='col-xl-6 col-lg-7 col-md-9 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="payment-div">
                                        <h5 className='mb-3'>Payment Details</h5>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>Total amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹ {amount}</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>GST 18%</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹ {Math.round(0.18 * amount)}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>Total payable amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹ {Math.round(parseInt(amount) + (0.18 * amount))}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={cardimg} className='card-img me-2' /><span className='card-name'>Credit Card</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={payatmimg} className='pay-img me-1' /><span className='card-name'>Paytm</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className=' payment-mode  me-4 mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={upiimg} className='upi-img me-2' /><span className='card-name'>UPI</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={debitimg} className='debit-img me-2' /><span className='card-name'>Debit Card</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={phonepayimg} className='phonepay-img me-2' /><span className='card-name'>Phone Pay</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='button -holder text-center mt-3'>
                                            <button type='button' className='btn btn-proceed' 
                                            onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}
                                            >Proceed</button>
                                             {/* data-bs-toggle="modal" data-bs-target="#exampleModal" */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Modal --> */}
            <div className='procced-modal'>
                <div className={showModal ? "modal fade show" : "modal fade"}
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                 id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body">
                                <CheckLottey />
                                <div className='successfully text-center'>
                                    <button className='btn btn-success1'>Payment Done Successfully</button>
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* mobile-view-start */}
            <section className='wallet mobile-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item"><Link to='/wallet'>Wallet {'>'}</Link></li>
                                <li className="breadcrumb-item active" >Payment Information</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row wallet-payment mt-5'>
                        <div className=' col-md-9 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className="payment-div">

                                        <div className="row">
                                            <div className='col-12'>
                                                <h5 className='mb-1'>Payment Details</h5>
                                                <div className='border-payment mb-2'></div>
                                            </div>
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>Total amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹ {amount}</p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>GST 18%</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹ {Math.round(0.18 * amount)}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="col-6">
                                                <div className='amount'>
                                                    <h6>Total payable amount</h6>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='rupees text-end'>
                                                    <p>₹ {Math.round(parseInt(amount) + (0.18 * amount))}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={cardimg} className='card-img me-1' /><span className='card-name'>Credit Card</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={payatmimg} className='pay-img me-1' /><span className='card-name'>Paytm</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode1  me-sm-5 me-0 mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={upiimg} className='upi-img me-1' /><span className='card-name'>UPI</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode  mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={debitimg} className='debit-img me-1' /><span className='card-name'>Debit Card</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className=' col-sm-4 col-4'>
                                            <div className=' payment-mode mb-3'
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>
                                                <div className='imag-card'>
                                                    <img src={phonepayimg} className='phonepay-img me-2' /><span className='card-name'>Phone Pay</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='button -holder text-center mt-3'>
                                            <button type='button' className='btn btn-proceed' 
                                             onClick={() => {
                                                handleRazorpay(Math.round(parseInt(amount) + (0.18 * amount)));
                                              }}>Proceed</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* mobile-view-end */}
        </>

    )
}

export default WalletPayment