import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoimg from "../image/Astrobooster.png";
import callimg from "../image/phone-call.png";
import chatimg from "../image/material-message.png";
import profileimg from "../image/profile.png";
import searchimg from "../image/search.png";
import walletimg from "../image/wallet.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rightimg from "../image/arrow-back.png";
import droplightimg from "../image/dropright-circle.png";
import hinditimg from "../image/hindi.png";
import marathitimg from "../image/marathi.png";
import loginimg from '../image/login-img.png';
import userimg from '../image/user.png';
import dummyimg from '../image/dummy-user.png';
import Notificationimg from '../image/notification.png';
import wallet1img from '../image/walle-1.png';
import chathisimg from '../image/chat-history.png';
import logoutimg from '../image/logout.png';
import callhisimg from '../image/call-history.png';
import matchimg from '../image/kundali-match-mob.png';
import freekunimg from '../image/freekundali-mob.png';
import horoscopemobimg from '../image/horoscope-mob.png';
import blogmobimg from '../image/blog-mob.png';
import astromolimg from '../image/astromal-mob.png';
import rightcircleimg from "../image/dropright-circle.png";



import { useContext } from "react";
import { Context } from "../../../utils/context";
library.add(fas);
const Header = () => {

const [authToken, setAuthToken] = useState(null);

    const navigate = useNavigate();
    
    const { getData, imageUrl } = useContext(Context)

      const [userData , setUserData] = useState();
      const logout = () => {
        sessionStorage.clear();
        window.location.href = '/';
      }
      

      const getUserData = async () => {
        const res = await getData('/userData');
        console.log(res,'userData');
        if(res?.success){
          setUserData(res.data.user);
        }
      }














  //Frontend CODE
  const [selectedItem, setSelectedItem] = useState(1);
  const location = useLocation();
  const handleChangeColor = (index) => {
    setColour(index)
  }
  const [colour, setColour] = useState(false);
  const handleClickChange = (index) => (
    setSelectedItem(index)

  )

  const handleClick = () => {
    const myElement = document.getElementById("accordionExample");
    myElement.style.display = "none";
  }

 


  useEffect(() => {
    const storedSelectedItem = localStorage.getItem("selectedItem");
    if (storedSelectedItem !== null) {
      setSelectedItem(parseInt(storedSelectedItem));
    }
    getUserData();
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedItem", selectedItem.toString());
  }, [selectedItem]);


  useEffect(() => {
   
    const pathname = location.pathname;
    switch (pathname) {
      case "/":
        setSelectedItem(1);
        break;
      case "/freekundali":
        setSelectedItem(2);
        break;
      case "/Kundali":
        setSelectedItem(3);
        break;
      case "/blog":
        setSelectedItem(6);
        break;
    }

    if(sessionStorage.getItem('astrotoken')){
      setAuthToken(sessionStorage.getItem('astrotoken'));
      sessionStorage.removeItem('dummyToken');
      sessionStorage.removeItem('astroMobile');
    }
  })


  const routesWithBackColor = ['/talk-astrologer', '/chatSection', '/astrologer-profile', '/chat-astrologer'];
  const shouldApplyBackColor = routesWithBackColor.includes(location.pathname);

  const [isToggled, setIsToggled] = useState(false);
  // Function to toggle the state
  const toggle = () => {
    setIsToggled(!isToggled);
  };
  return (
    <>
      <section className="main main-2">
        <div className="container-fluid p-0">
          {/* <!-- start header all section  --> */}
          <div className="header-all-section">
            {/* <!--top-header section start--> */}
            <section className="top-header ">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3 col-5">
                    <div className="logo my-3 text-lg-center text-start">
                      <Link to="/">
                        <img src={logoimg} className="" />
                      </Link>
                    </div>
                  </div>
                  <div className="col-lg-4 col-2 ">
                    <div className="d-flex talk-call-icon justify-content-md-center justify-content-start">
                      <Link to="/talk-astrologer">
                        <div className={`images-header text-center me-3  ${colour === 1 && shouldApplyBackColor ? "back_color" : ""}`} onClick={() => handleChangeColor(1)}>
                          <img src={callimg} className={`location   ${colour === 1 && shouldApplyBackColor ? "back_color1" : ""}`} onClick={() => handleChangeColor(1)} alt="location" />
                        </div>
                      </Link>
                      <div className="text-holder hide-mob-top mt-3">
                        <p>Talk to Astrologers </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-2 ">
                    <div className="d-flex  talk-call-icon">
                      <Link to="/chat-astrologer">
                        <div className={`images-header text-center  me-3   ${colour === 2 && shouldApplyBackColor ? "back_color" : ""}`} onClick={() => handleChangeColor(2)} >
                          <img src={chatimg} className={`location  ${colour === 2 && shouldApplyBackColor ? "back_color1" : ""}`} onClick={() => handleChangeColor(2)} alt="location" />
                        </div>
                      </Link>
                      <div className="text-holder hide-mob-top mt-3">
                        <p > Chat to Astrologers </p>
                      </div>
                    </div>
                  </div>
                      {authToken == null ? (
                  <div className="col-lg-1 col-3">
                    <Link to={"/login"}>
                     <button className="btn login_btn">Login</button>
                    
                    </Link>
                  </div>
                  ) : (
                  <div className="col-lg-2 col-3">
                  
                    <div className="user-button  text-lg-center " >

                      <img src={userData?.profile_pic == null ? dummyimg : imageUrl + userData?.profile_pic} className=" profile-img me-3" onClick={toggle} />
                      {isToggled &&
                        <div className=" "    >

                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">

                                <div className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" >
                                  <div className="d-flex">
                                    <img src={userData?.profile_pic == null ? dummyimg : imageUrl + userData?.profile_pic} className="lang-img ms-2" />
                                    <div className="profile-name">
                                      <p>{userData?.name}</p>
                                      <span>{userData?.mobile_number}</span>
                                    </div>
                                  </div>
                                </div>

                              </h2>

                              <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                <Link to='/profle'>
                                    <div className="text-start mb-3" onClick={handleClick}>
                                      <img src={userimg} className="notify-img me-3" /><span>Profile</span>
                                    </div></Link>
                                  <Link to='/notification'>
                                    <div className="text-start mb-3" onClick={handleClick}>
                                      <img src={Notificationimg} className="notify-img me-3" /><span>Notification</span>
                                    </div></Link>

                                  <Link to='/wallet-transaction'>
                                    <div className=" text-start mb-3 " onClick={handleClick}>
                                      <img src={wallet1img} className="notify-img me-3" /><span>Wallet Transations - ₹0</span>
                                    </div></Link>

                                  <Link to='/chat-history'>
                                    <div className="text-start mb-3" onClick={handleClick}>
                                      <img src={chathisimg} className="notify-img me-3" /><span>Chat History</span>
                                    </div></Link>

                                  <Link to='/call-history'> <div className=" text-start mb-3" onClick={handleClick}>
                                    <img src={callhisimg} className="notify-img me-3" /><span>Call History</span>
                                  </div></Link>

                                  <div className=" text-start mb-2">
                                    <button type="button" className="btn btn-logout p-0" data-bs-toggle="modal" data-bs-target="#logoutModal" onClick={handleClick}>
                                      <img src={logoutimg} className="notify-img me-3" /><span>Logout</span>
                                    </button>

                                  </div>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </section>
            {/* <!-- log out Modal --> */}
            <div className="modal-logout desktop-view-logout-modal">
              <div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    {/* <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div> */}
                    <div className="modal-body">
                      <div className='delete-text text-center'>
                        <h5>Are you sure you want to Logout your Account</h5>
                      </div>
                      <div className='delete-button text-center my-3'>
                        <button className='btn btn-cancle me-2' data-bs-dismiss="modal">No</button>
                        <button className='btn btn-ok' onClick={logout}>Yes</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            {/* <!--top-header section end--> */}


            {/* <!--second-header section start--> */}
            <section className="second-header">
              <div className="container">
                <div className="row">
                  <div className="">
                    <nav className="navbar navbar-expand-lg p-0">
                      <button className="navbar-toggler my-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation" > <span className="navbar-toggler-icon"></span> </button>
                      <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                        <ul className="navbar-nav  ">
                          <li className={`nav-item  ${selectedItem === 1 ? "clicked" : ""}`} onClick={() => handleClickChange(1)}>
                            <Link to="/" className="nav-link active" aria-current="page"> Home</Link>
                          </li>

                          <li className={`nav-item  ${selectedItem === 2 ? "clicked" : ""}`} onClick={() => handleClickChange(2)}>
                            <Link className="nav-link" to="/freekundali">Free Kundali</Link>
                          </li>

                          <li className={`nav-item  ${selectedItem === 3 ? "clicked" : ""}`} onClick={() => handleClickChange(3)}>
                            <Link className="nav-link" to="/Kundali"> Kundali Matching </Link>
                          </li>
                          <li className={`nav-item dropdown  ${selectedItem === 4 ? "clicked" : ""}`} onClick={() => handleClickChange(4)}>
                            <Link className="nav-link dropdown-toggle" role="button"
                              data-bs-toggle="dropdown" aria-expanded="false" > Horoscopes </Link>
                            <ul className="dropdown-menu">
                              <li>
                                <Link className="dropdown-item" to={"/horoscope2023"}> <img src={droplightimg} className="drop-circle mx-2" /> Horoscope 2023</Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/today_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" /> Today's Horoscope </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/weekly_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" /> Weekly Horoscope </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/monthly_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" />
                                  Monthly Horoscope
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/yearly_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" />
                                  Yearly Horoscope
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/daily_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" />
                                  Daily Horoscope
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/tomorrow_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" />
                                  Tomorrow's Horoscope
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/yesterday_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" />
                                  Yesterday's Horoscope
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item" to={"/chinese_Horoscope"}>
                                  <img src={droplightimg} className="drop-circle mx-2" />
                                  Chinese Horoscope
                                </Link>
                              </li>
                            </ul>
                          </li>

                          <li className={`nav-item dropdown  ${selectedItem === 5 ? "clicked" : ""}`} onClick={() => handleClickChange(5)}>
                            <Link className="nav-link">Astromall</Link>
                          </li>
                          <li className={`nav-item dropdown  ${selectedItem === 6 ? "clicked" : ""}`} onClick={() => handleClickChange(6)}>
                            <Link className="nav-link" to="/blog/0">
                              Blog
                            </Link>
                          </li>
                          <li className="nav-item dropdown">
                            <Link className="nav-link2  dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
                              Eng
                            </Link>
                            <ul className="dropdown-menu">
                              <li>
                                <Link className="dropdown-item">
                                  <img src={hinditimg} className="lang-img mx-2" />
                                  Hindi
                                </Link>
                              </li>
                              <li>
                                <Link className="dropdown-item">
                                  <img src={marathitimg} className="lang-img mx-2" />
                                  Marathi
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="search-box navbar-nav">
                        <div className="input-group">
                          <input type="text" className="form-control"
                            placeholder="Search here..."
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                          />
                          <span className="input-group-text" id="basic-addon1">
                            <img src={searchimg} className="search-icon" alt="" />
                          </span>
                        </div>
                      </div>
                      <Link to='/wallet'>
                        <div className="shopping-box">
                          <div className="img-holder text-center d-flex">
                            <div className="ms-3 me-2">
                              <img src={walletimg} className="wallet-img" alt="" />
                            </div>
                            <div>
                              <span className="wallet">Wallet</span>
                            </div>
                          </div>
                        </div>
                      </Link>

                    </nav>
                  </div>
                </div>
              </div>
            </section>
            {/* <!--second-header section end--> */}
          </div>
          {/* <!-- end header all section  --> */}
        </div>
      </section>

      {/* mobile-view-start */}
      <section className="mobile-view-app">
        <div className="container-fluid">
          <div className="row">
            <div className="col-2">
              <div className=" my-3  ">
                <button className="btn menuBtn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasmobileapp" aria-controls="offcanvasExample" >
                  <FontAwesomeIcon icon="fa-solid fa-bars" />
                </button>
              </div>
            </div>
            <div className="col-5 ">
              <div className="d-flex mt-3 mobile-view-line justify-content-start">
                <Link to="/talk-astrologer">
                  <div className={`images-header text-center me-md-3 me-sm-1 me-1  ${colour === 1 && shouldApplyBackColor ? "back_color" : ""}`} onClick={() => handleChangeColor(1)}>
                    <img src={callimg} className={`location   ${colour === 1 && shouldApplyBackColor ? "back_color1" : ""}`} onClick={() => handleChangeColor(1)} alt="location" />
                  </div>
                </Link>
                <div className="text-holder hide-mob-top mt-2">
                  <p>Talk to Astrologers </p>
                </div>
              </div>
            </div>
            <div className=" col-5 ">
              <div className="d-flex mt-3 ">
                <Link to="/chat-astrologer">
                  <div className={`images-header text-center  me-md-3 me-sm-1 me-1   ${colour === 2 && shouldApplyBackColor ? "back_color" : ""}`} onClick={() => handleChangeColor(2)} >
                    <img src={chatimg} className={`location  ${colour === 2 && shouldApplyBackColor ? "back_color1" : ""}`} onClick={() => handleChangeColor(2)} alt="location" />
                  </div>
                </Link>
                <div className="text-holder hide-mob-top mt-2">
                  <p > Chat to Astrologers</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      {/* mobile app view offcanvas start */}
      <div className="mobile-app-offcanvas">
        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasmobileapp" aria-labelledby="offcanvasExampleLabel">
          <div className="offcanvas-header">
            {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel">Offcanvas</h5> */}
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          
          <div className="offcanvas-body pt-0">
            <div className="row">
               {authToken == null ? (
                  <div className="col-lg-1 col-3">
                    <Link to={"/login"}>
                     <button className="btn login_btn" data-bs-dismiss="offcanvas">Login</button>
                    
                    </Link>
                  </div>
                  ) : (
                    
              <div className="d-flex">
                <Link to='/profle'>
                <div className="user-button1 my-3" data-bs-dismiss="offcanvas" aria-label="Close">
                  <img src={userData?.profile_pic == null ? dummyimg : imageUrl + userData?.profile_pic} className="profile-img me-3" />
                </div>
                </Link>
                <div className="name-holder my-3">
                  <p>{userData?.name}</p>
                  <span>{userData?.mobile_number}</span>
                </div>
              </div>
              
                  )}
              <div className='col-12'>
                <div className="main-heading mb-3">
                  <div className="line me-1 mt-1"></div>
                  <div className="square"></div>
                  <div className="line ms-1 mt-1"></div>
                </div>
              </div>
              <div className="all-pages">
                <Link to='/notification' >
                  <div className="d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button ">
                      <img src={Notificationimg} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>Notification</p>
                    </div>
                  </div>
                </Link>
                {authToken != null && (
                <Link to='/wallet' >
                  <div className="d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button ">
                      <img src={wallet1img} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>Wallet Transations<span> - ₹0</span></p>

                    </div>
                  </div>
                </Link>
                )}
                <Link to='/kundali'>
                  <div className="d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button ">
                      <img src={matchimg} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>kundali Matching</p>
                    </div>
                  </div></Link>

                <Link to='/freekundali'>
                  <div className="d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button ">
                      <img src={freekunimg} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>Free kundali</p>
                    </div>
                  </div></Link>

                <div className=" text-start mb-3" data-bs-dismiss="offcanvas" aria-label="Close">
                  <button type="button" className="btn btn-logout p-0" data-bs-toggle="modal" data-bs-target="#horoscopeModal1">
                    <img src={horoscopemobimg} className="notify-img me-3" /><span className="page-holder ">Horoscopes</span>
                  </button>
                </div>

                <Link to='/blog/0' >
                  <div className="d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button">
                      <img src={blogmobimg} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>Blog</p>
                    </div>
                  </div></Link>

                <div className="d-flex">
                  <div className="user-button">
                    <img src={astromolimg} className="notify-img me-3" />
                  </div>
                  <div className="page-holder ">
                    <p>Astromall</p>
                  </div>
                </div>
                {authToken != null && (
                  <>
                <Link to='/chat-history'>
                  <div className="d-flex " data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button">
                      <img src={chathisimg} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>Chat History</p>
                    </div>
                  </div></Link>

                <Link to='/call-history'>
                  <div className="d-flex" data-bs-dismiss="offcanvas" aria-label="Close">
                    <div className="user-button">
                      <img src={callhisimg} className="notify-img me-3" />
                    </div>
                    <div className="page-holder ">
                      <p>Call History</p>
                    </div>
                  </div></Link>
                  </>
                  )}
                  {authToken != null && (
                <div className=" text-start mb-2" data-bs-dismiss="offcanvas" aria-label="Close">
                  <button type="button" className="btn btn-logout p-0" data-bs-toggle="modal" data-bs-target="#logoutModal1">
                    <img src={logoutimg} className="notify-img me-3" /><span className="page-holder ">Logout</span>
                  </button>
                </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- log out Modal --> */}
        <div className="modal-mobview ">
          <div class="modal fade" id="logoutModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                {/* <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div> */}
                <div className="modal-body">
                  <div className='delete-text text-center'>
                    <h5>Are you sure you want to Logout your Account</h5>
                  </div>
                  <div className='delete-button text-center mt-2'>
                    <button className='btn btn-cancle me-2'>No</button>
                    <button className='btn btn-ok' >Yes</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/* <!--top-header section end--> */}

      </div>

      {/* mobile app view offcanvas end */}
      <div className="horoscope-modal ">
        <div class="modal fade" id="horoscopeModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
              <div class="modal-header">
                {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <Link to="/horoscope2023"> <div className="button-horoscope1  mb-3" data-bs-dismiss="modal" aria-label="Close" >
                  <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Horoscope 2023</button>
                </div></Link>

                <Link to={"/today_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Today's Horoscope</button>
                  </div></Link>

                <Link to={"/weekly_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Weekly Horoscope</button>
                  </div></Link>

                <Link to={"/monthly_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Monthly Horoscope</button>
                  </div>
                </Link>

                <Link to={"/yearly_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Yearly Horoscope</button>
                  </div>
                </Link>

                <Link to={"/daily_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Daily Horoscope</button>
                  </div></Link>

                <Link to={"/tomorrow_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Tomorrow's Horoscope</button>
                  </div>
                </Link>

                <Link to={"/yesterday_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Yesterday's Horoscope</button>
                  </div></Link>

                <Link to={"/chinese_Horoscope"}>
                  <div className="button-horoscope mb-3" data-bs-dismiss="modal" aria-label="Close">
                    <button className="btn btn-horoscope"><img src={rightcircleimg} className="drop-img me-2" />Chinese Horoscope</button>
                  </div></Link>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
