import React, { useRef, useState, useEffect } from 'react'
import videoimg from "../Video/login_video.mp4"
import changegif from "../../home-page/image/change.gif";
import { Link, useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

import { useContext } from "react";
import { Context } from "../../../utils/context";
const NewPassword = () => {
   
    
    const { postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const [passwordErr, setPasswordErr] = useState("");
    const [conPasswordErr, setConPasswordErr] = useState("");


    const handleValidation = async () => {
        var password = document.getElementById('password').value;
        var con_password = document.getElementById('con_password').value;

        // Initialize an array to store error messages
        var errors = [];


        if (password === "") {
            setPasswordErr('Password is required');
            errors.push('Password is required');
        }

        if (con_password === "") {
            setConPasswordErr('Confirmation Password is required');
            errors.push('Confirmation Password is required');
        }
        else if (password !== con_password) {
            setConPasswordErr('Password and Confirmation Password do not match');
            errors.push('Password and Confirmation Password do not match');
        }

        if (errors.length === 0) {
            console.log( password + " " + con_password, 'forms');
            var username = sessionStorage.getItem('astroEmail');
            const res = await postData(`/changePassword?username=${username}&password=${password}`);
            console.log(res, 'register'); 
            if (res?.success) { 
                
            // Clear the "astroOTP" item from session storage
            sessionStorage.removeItem('astroOTP');

            // Clear the "astroEmail" item from session storage
            sessionStorage.removeItem('astroEmail');
            // Open the modal
            setShowModal(true);

            // Delay navigation by 2 seconds (2000 milliseconds)
            setTimeout(() => {
                navigate('/login');
            }, 2000);
                    

            }else{
                console.log(res.response.data.message, 'fail');
                navigate('/forgetPassword');
            }

        } 

        
       
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setshowConPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConPasswordVisibility = () => {
    setshowConPassword(!showConPassword);
  };
    const vidRef = useRef();

    useEffect(() => { 
        vidRef.current.play(); 

        if(sessionStorage.getItem('astroEmail')){
        }else{
            navigate('/forgetPassword');
        }
    }, []);




  return (
    <>
    <section className='Login'>
                <div className='container-fluid'>
                    <div className=" banner-slider ">
                        <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                        <div className="main_login_section ">
                        <div className="login-page ">

                            <div className="login-container p-4">
                                <h2 className="text-center mb-4 Login_heading">New Password</h2>
                              
                                <form>
                                    <div className="form-group mb-3">
                                        <label for="exampleInputEmail1" class="form-label email_label">Enter New Password</label>
                                        <div className="inputField">
                                            <div className="input-container" >
                                            <div className='row'>
                                                        <div className='col-lg-1 col-2'  onClick={togglePasswordVisibility}>
                                                            <span className="input-icon">
                                                                
                                                                <FontAwesomeIcon icon={showPassword ? faUnlock : faLock} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type={showPassword ? 'text' : 'password'} id="password" className="content_input_field form-control mb-2" placeholder="Password" onChange={() => (setPasswordErr(""))} />

                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                    </div>
                                    <span className='text text-danger'>{passwordErr && (passwordErr)}</span>

                                    <div className="form-group">
                                        <label for="exampleInputEmail1" class="form-label email_label">Confirm New Password</label>
                                        <div className="inputField">
                                            <div className="input-container" >
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2' onClick={toggleConPasswordVisibility}>
                                                            <span className="input-icon">
                                                                <FontAwesomeIcon icon={showConPassword ? faUnlock : faLock} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type={showConPassword ? 'text' : 'password'}  id="con_password" className="content_input_field form-control mb-2" placeholder="Confirm Password" onChange={() => (setConPasswordErr(""))} />
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                    </div>
                                    <span className='text text-danger'>{conPasswordErr && (conPasswordErr)}</span>

                                    <div className='text-center mb-3'>
                                        

                                            <button type="button" className="btn btn-primary login_btn text-center mt-2" onClick={handleValidation}>Reset Password</button>
                                        

                                    </div>
                                    <div className='text-center'>
                                        <Link to={"/login"} className='link'>
                                            <p className='backToLogin_txt'> <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> Back to Login Page</p>

                                        </Link>
                                    </div>
                                </form>
                            </div>

                        </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* **********<!--call Modal start--> ************/}
            <section className="modal-chat">
                <div 
                className= {showModal ? "modal fade show" : "modal fade"}
                id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <img src={changegif} />
                                </div>
                                <div className="text-holder text-center">
                                    <h5>Password Changed Successfully...!</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}

    </>
  )
}

export default NewPassword