import React from "react";
import { useState, useEffect } from "react";
import '../home.css';
import { Link } from "react-router-dom";
import blog2 from "../image/blog2.png";
import blog1 from "../image/blog1.png";
import blog3 from "../image/blog3.png";
import arrow from "../image/forward.png";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import { Pagination, Autoplay } from 'swiper/modules';



import { useContext } from "react";
import { Context } from "../../../utils/context";
const LatestBlog = () => {


  const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context)

  const [Latestblogs, setLatestblogs] = useState();

  const getLatestBlog = async () => {
    const res = await getData("/latestBlog")
    if (res?.success) {
      console.log(res.data, 'latestBlog');
      setLatestblogs(res.data.blogs.data);
    }
  };


  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 50) : text}
        <span className="mt-1 ReadMore_txt"
        >{isReadMore ? "..." : ""} </span>
      </p>
    );
  };
  useEffect(() => {

    getLatestBlog();

  }, []);
  return (
    <>
      <section className="LatestBlog desk-top-view-latestblog">
        <div className="container">
          <div className="row">
            <div className='heading-holder text-center mt-2'>
              <h2>Our<span className='ms-2'>Latest Blog</span> </h2>
            </div>
            <div className="main-heading">
              <div className="line me-2"></div>
              <div className="square"></div>
              <div className="line ms-2"></div>
            </div>
            <div className="info my-3">
              <p>  Consectetur adipiscing elit, sed do eiusmod tempor incididuesd
                eentiut labore etesde dolore magna aliquapspendisse and the
                gravida.
              </p>
            </div>
          </div>
          <div className="row ">
            {Latestblogs?.map((val, index) => (
              index < 3 && (
                <div className="col-lg-4 col-md-6">

                  <Link to={`/readMore/${val?.id}`}>  <div className="card card-section mb-4">
                    <div className="img-section">
                      <img src={imageUrl + val?.image} className="card-img-top blog-img" alt="..." />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title my-3">
                        {val?.heading}
                      </h5>
                      <p className="card-text">
                        <ReadMore>
                          {val?.description}
                        </ReadMore>


                      </p>
                      <div className="mb-2">
                        <span className="read-more">Read More</span>
                        <img src={arrow} alt="arrow_img" className="arrow_img ms-2" />
                      </div>

                    </div>
                  </div>
                  </Link>

                </div>
              )
            ))}


            {/* <div className="col-lg-4 col-md-6">
              <Link to='/blog'> <div className="card mb-4">
                <div className="img-section">
                  <img src={blog3} className="card-img-top" alt="..." />
                </div>

                <div className="card-body">
                  <h5 className="card-title my-3">
                    Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut
                    Labore.
                  </h5>
                  <p className="card-text">
                    Consectetur adipiscing elit, sed desdo eiu smod tempor
                    incididuesdeentiut labore etesde doloesire esdesdeges magna
                    aliquapspendisse and the gravida.....
                  </p>
                  <div className="mb-2">
                    <span className="read-more">Read More</span>
                    <img src={arrow} alt="arrow_img" className="arrow_img ms-2" />
                  </div>
                </div>
              </div></Link>
            </div> */}

          </div>
        </div>
      </section>

      {/***** * mobilw-view-start ******/}
      <section className='LatestBlog'>
        <div className='container'>
          <div className='row LatestBlog-swiper-mobile-app'>
            <div className='heading-holder text-center mt-5'>
              <h2>Our<span className='ms-2'>Latest Blog</span> </h2>
            </div>
            <div className="main-heading">
              <div className="line me-2"></div>
              <div className="square"></div>
              <div className="line ms-2"></div>
            </div>
            <div className="info mt-2">
              <p>  Consectetur adipiscing elit, sed do eiusmod tempor incididuesd
                eentiut labore etesde dolore magna aliquapspendisse and the
                gravida.
              </p>
            </div>
            <Swiper className=''

              spaceBetween={10}

              pagination={{ clickable: true }}
              modules={[Autoplay, Pagination]}
              navigation={false}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                360: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                567: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },

              }}
            >
              {Latestblogs?.map((val, index) => (
                index < 3 && (
                  <SwiperSlide >
                    <div className="card  mb-5">
                      <Link to={`/readMore/${val?.id}`}>
                        <div className="img-section">
                          <img src={imageUrl + val?.image} className="card-img-top blog-img" alt="..." />
                          <div className="date-txt">
                            {(new Date(val?.created_at)).toLocaleDateString('en-US', { day: '2-digit' })} <br />
                            {(new Date(val?.created_at)).toLocaleDateString('en-US', { month: 'short' })}
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title">
                            {val?.heading}
                          </h5>
                          <p className="card-text">
                            <ReadMore>
                              {val?.description}
                            </ReadMore>
                          </p>
                          <span className="read-more">Read More</span>
                          <img src={arrow} alt="arrow_img" className="arrow_img ms-2" />
                          {/* <div className=''>
                      <button className='btn btn-read'>Read More  <img src={arrow} alt="arrow_img" className="arrow_img" /></button>
                    </div> */}
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                )
              ))}
              {/* <SwiperSlide >
                <div className="card mb-5">
                  <div className="img-section">
                    <img src={blog3} className="card-img-top" alt="..." />
                    <div className="date-txt">
                      25 <br /> Jan
                    </div>
                  </div>

                  <div className="card-body">
                    <h5 className="card-title">
                      Consectetur Adipiscing Elit Sedeius Mod Tempor Incididunt Ut
                      Labore.
                    </h5>
                    <p className="card-text">
                      Consectetur adipiscing elit, sed desdo eiu smod tempor
                      incididuesdeentiut labore etesde doloesire esdesdeges magna
                      aliquapspendisse and the gravida.....
                    </p>
                    <span className="read-more">Read More</span>
                    <img src={arrow} alt="arrow_img" className="arrow_img ms-2" />
                  </div>
                </div>
              </SwiperSlide> */}

            </Swiper>
          </div>
        </div>

      </section>
    </>
  );
};

export default LatestBlog;