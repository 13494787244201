import React, { useEffect, useRef, useState } from 'react';
import "./Login.css"
import videoimg from "../Video/login_video.mp4"
import astroVideo from "../Video/astroVideo.mp4"
import { Link, useNavigate } from 'react-router-dom';
import successgif from "../../home-page/image/success.gif";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

import { useContext } from "react";
import { Context } from "../../../utils/context";
const Login = () => {


    const { postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);
    const [mobileErr, setMobileErr] = useState("");
    const [hidepassword, sethidePassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    
    const navigate = useNavigate();

    const loginClick = async () => {
        var mobile = document.getElementById('mobile').value;

        // Initialize an array to store error messages
        var errors = [];



        if (mobile.trim() === "") {
            setMobileErr('Mobile is required');
            errors.push('Mobile is required');
        }
        else if (!/^\d{10}$/.test(mobile)) {
            setMobileErr('Mobile should be a 10-digit number');
            errors.push('Mobile should be a 10-digit number');
        }


       


        if (errors.length === 0) {
            
            console.log(mobile + " ", 'forms');
            const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
             console.log(randOtp,'random');
            const res = await postData(`/login?mobile=${mobile}&otp=${randOtp}`);
            if (res?.success) {
                console.log(res.data, 'login');

                sessionStorage.setItem('astroMobile',mobile);
                sessionStorage.setItem('astroOTP',randOtp);
                let dummytoken = res.data.token;
                sessionStorage.setItem('dummyToken',dummytoken);

                let userid = res.data.user.id;
                sessionStorage.setItem('astrouserid', userid);
                console.log(userid, 'userid');

                // let token = res.data.token;
                // sessionStorage.setItem('astrotoken', token);
                // console.log(token, 'token');
                // window.location.href = '/';
                 // Open the modal
                 setShowModal(true);

                 // Delay navigation by 2 seconds (2000 milliseconds)
                 setTimeout(() => {
                     navigate('/otp');
                 }, 2000);

            } else {
                console.log(res, 'fail');
                setMobileErr(res.response.data.data);
            }

        }
    };


    const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

    const vidRef = useRef();


    useEffect(() => {
        vidRef.current.play();
    }, []);
    return (
        <>
            <section className='Login'>
                <div className='container-fluid'>
                    <div className=" banner-slider ">
                        <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                        <video src={astroVideo} ref={vidRef} muted loop className=" video-class1" />
                        <div className='main_login_section'>
                            <div className="login-page ">

                                <div className="login-container p-4">
                                    <h2 className="text-center mb-4 Login_heading">Login</h2>
                                    <p className='heading_info'>Hey, Enter your details to get sign in to your account</p>
                                    <form>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label email_label">Email</label>
                                            <div className='inputField mb-2'>
                                               <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2'>
                                                            <span className="input-icon">
                                                                <FontAwesomeIcon icon={faPhone} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                           <input type="text" id="mobile" minlength="10" maxlength="10" name="mobile" className="content_input_field form-control mb-2" placeholder="Enter the Mobile Number here"  onKeyDown={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    if (event.key != 'Backspace')
                                                                        event.preventDefault();
                                                                }
                                                            }} onChange={() => (setMobileErr(""))} />

                                                        </div>
                                                    </div>

                                                </div>
                                                

                                            </div>
                                            <span className='text text-danger'>{mobileErr && (mobileErr)}</span>
                                        </div>
                                        {/* <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label password_label">Password</label>
                                            <div className='inputField'>

                                                <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2'  onClick={togglePasswordVisibility}>
                                                            <span className="input-icon">
                                                                
                                                                <FontAwesomeIcon icon={showPassword ? faUnlock : faLock} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type={showPassword ? 'text' : 'password'} id="password" className="content_input_field form-control mb-2" placeholder="Password"   onChange={() => (setPasswordErr(""))}/>
                                                        </div>
                                                    </div>

                                                </div>
                                                

                                            </div>
                                            <span className='text text-danger'>{passwordErr && (passwordErr)}</span>
                                            <div>
                                                <Link to={"/forgetPassword"} className="link">
                                                    <p className='text-end forget_password_txt'>Forget Password ?</p>

                                                </Link>
                                            </div>
                                        </div> */}
                                        <div className='text-center mb-3'>
                                            <button type="button" className="btn btn-primary login_btn text-center" onClick={loginClick}>Login</button>

                                        </div>
                                        {/* <div className='text-center'>
                                            <Link to={"/register"}>
                                                <button type="button" className="btn btn-primary Register_btn text-center">
                                                    Register
                                                </button>
                                            </Link>

                                        </div> */}
                                    </form>
                                </div>



                            </div>
                            <div className='row below_common_section mt-4'>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>100% </p>
                                        <p className='text-info'>Privacy</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>10000+  </p>
                                        <p className='text-info'>Tap astrologers of India</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className=''>
                                        <p className='data_txt'>3Cr+ </p>
                                        <p className='text-info'>Happy Customers</p>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>


            {/* **********<!--call Modal start--> ************/}
            <section className="modal-chat">
                <div 
                className= {showModal ? "modal fade show" : "modal fade"}
                id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <img src={successgif} />
                                </div>
                                <div className="text-holder text-center">
                                    <h5>OTP Sent Successfully...!</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}


        </>
    )
}

export default Login