import axios from "axios";

const formDataClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  mode: "no-cors",
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
});

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  mode: "no-cors",
  headers: {
    Authorization: ``,
    Accept: "application/json",
    "Content-Type": "application/json multipart/form-data",
  },
});

formDataClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("astrotoken");
    if (token) {
      config.headers["Authorization"] = "Bearer "+ token;
    }
    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);

export default formDataClient;
