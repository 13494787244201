import React , {useEffect, useState} from 'react';
import './wallet.css'
import { Link, useNavigate} from "react-router-dom";
import CoupenCard from './coupen-card/CoupenCard';



import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context";
const Wallet = () => {


    const { getData, postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);

        const navigate = useNavigate();
        const [wallet, setWallet] = useState();
        const [coupons, setCoupons] = useState();
        const [showErr, setShowErr] = useState("");
        const [couponErr, setCouponErr] = useState("");
        const [couponSuccess, setCouponSuccess] = useState("");
        const [coupon, setCoupon] = useState("");
        const [rechargeAmount, setRechargeAmount] = useState(0);
        const [discountedAmount, setDiscountedAmount] = useState(0);
        const [discountPay, setDiscountedPay] = useState(0);
        const [couponCode, setCouponCode] = useState("");
        const [couponId, setCouponId] = useState("");

        const getWallet = async () => {
            const res = await getData("/wallet")
            if (res?.success) {
                console.log(res.data, 'wallet');
                setWallet(res.data.wallet);
            }
        };

        const  proceedWallet = async () => {
            console.log(rechargeAmount);
                if(rechargeAmount != 0){
                    var walletData = {};
                    walletData['amount'] = rechargeAmount;
                    walletData['discountAmount'] = discountedAmount;
                    walletData['discountPay'] = discountPay;
                    walletData['couponId'] = couponId;

                    Cookies.set('astroUserWallet', JSON.stringify(walletData), { expires: 1 });

                    navigate('/wallet-payment');
                }else{
                    setShowErr("Enter Amount to add in Wallet");
                }
                    
        };


        const getCoupons = async () => {
            const res = await getData("/coupen")
            if (res?.success) {
                console.log(res.data, 'coupons');
                setCoupons(res.data.coupen);
            }
        };

const couponApply = async (code) => {
            const couponId = code;
            var amount = rechargeAmount;

            if(amount != 0){
            const res = await getData(`/coupenvalid?code=${couponId}&amount=${amount}`);
                        if(res?.success){
                            setCouponErr();
                            console.log(res.data.coupen,"coupon");
                            setCoupon(res.data.coupen);
                            setCouponCode(res.data.coupen.code);
                            setCouponId(res.data.coupen.id);
                            if(res.data.coupen.type === "percentage"){
                                    var discountAmount = amount * (res.data.coupen.discount/100);
                                    var discount = amount - discountAmount;


                            }else{
                                    var discountAmount = res.data.coupen.total_amount;
                                    var discount = amount - discountAmount;
                            }

                            setDiscountedAmount(discountAmount);
                            setDiscountedPay(discount);
                            


                            // setCouponSuccess("Coupon Applied! </br> You Got ₹ " + discountAmount + " OFF </br> You Will Have to Pay ₹ " + discount);
                            setCouponSuccess(
                                <div>
                                  Coupon Applied! <br />
                                  You Got ₹ {discountAmount} OFF <br />
                                  You Will Have to Pay ₹ {discount}
                                </div>
                              );
                              
                        }else{
                            console.log(res?.response,'fails');
                                setCouponErr(res?.response?.data?.message);
                                setCouponSuccess("");
                                setCouponCode("");
                                setDiscountedAmount(0);
                                setDiscountedPay(0);
                                setCoupon("");
                        }
            }else{
                setShowErr("Enter Amount to add in Wallet");
                setCouponErr("");
                                setCouponSuccess("");
                                setCouponCode("");
                                setDiscountedAmount(0);
                                setDiscountedPay(0); 
                                setCoupon("");
            }

};

const handleChange = async (e) => {

    setShowErr("");
     setRechargeAmount(e.target.value);
};

const codeChange = async (e) => {

    setCouponErr("");
     setCouponCode(e.target.value);
     setCouponSuccess("");
     setDiscountedAmount(0);
     setDiscountedPay(0); 
     setCoupon("");
};



    useEffect(() => { 
            // if(sessionStorage.getItem('astrotoken') == null){
            //         navigate('/login'); 
            // }else{
                getWallet();
                getCoupons();
            // }
            
    }, []);
    return (
        <>
            <section className='wallet desk-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item active">Wallet</li>
                            </ol>
                        </nav>
                    </div>
                    <div className='row'>
                         <div className='start-wallet-coupen'>
                            
                            <div className='apply-coupen text-center'>
                                <h4>Wallet Balance  :  ₹ {wallet?.amount == null ? "0.00" : wallet?.amount}</h4>
                                <p>Recharge Your Wallet Here</p>
                                <span className='text text-danger text-center'>{showErr ? showErr : ""}</span>
                            </div>
                            
                        </div>
                        <div className='start-wallet-coupen'>
                            <div className='col-lg-4 col-md-6 mx-auto'>
                                <div className="input-group mb-5">
                                    <input type="text" className="form-control" id="amount" placeholder="Enter amount" aria-label="Recipient's username" aria-describedby="basic-addon2"  
                                    onKeyDown={(event) => {
                                          if (!/[0-9]/.test(event.key)) {
                                          if(event.key != 'Backspace')
                                           event.preventDefault();
                                              }
                                      }}
                                      onChange={handleChange}
                                      />
                                    {/* <Link to='/wallet-payment'>   */}
                                    <span className="input-group-text" id="basic-addon2" onClick={proceedWallet}>Proceed</span>
                                    {/* </Link> */}
                                </div>
                            </div>
                            
                            <div className='apply-coupen text-center'>
                                <h4>Apply Coupon</h4>
                                <p>Take advantage of the coupon and receive a discount enter coupon code below</p>
                                <span className='text text-danger text-center'>{couponErr ? couponErr : ""}</span>
                                <span className='text text-success text-center'>{couponSuccess ? couponSuccess : ""}</span>
                            </div>
                            <div className='col-lg-4 col-md-6 mx-auto'>
                                <div className="input-group my-3">
                                    <input type="text" className="form-control" value={couponCode} id="couponCode" placeholder="Enter coupon code" aria-label="Recipient's username" aria-describedby="basic-addon2" 
                                    onChange={codeChange}
                                    />
                                    <span className="input-group-text" id="basic-addon2"
                                    onClick={() => { 
                                        couponApply(couponCode)
                                    }}
                                    >Apply</span>
                                </div>
                            </div>
                            <div className='apply-coupen text-center mb-5'>
                                <h4>or choose from below</h4>
                            </div>
                        </div>

                        <div className='row'>
                            {coupons?.map((val, index) => (

                                (val?.coupon_history[0]?.occur < val?.use_per_customer) ? (
                            <div className="col-lg-4 col-md-6 mb-3">

                                <div class="coupon-div">
                                    <div class="row">
                                        <div class="col-6">
                                            <input class="input-coupon" placeholder="" value={val?.code} />
                                        </div>
                                        <div class="col-6">
                                            <div className='apply-button text-end'>
                                                <button className='btn btn-apply'
                                                 onClick={() => {
                                                    couponApply(val?.code)
                                                }}
                                                >APPLY</button>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="flat-p">{val?.coupen_message}</h6>
                                    <p class="plat-p-subtext">{val?.coupen_info}</p>
                                </div>

                            </div>
                                ) : (
                                    val?.coupon_history.length == 0 &&(
                                    <div className="col-lg-4 col-md-6 mb-3">

                                    <div class="coupon-div">
                                        <div class="row">
                                            <div class="col-6">
                                                <input class="input-coupon" placeholder="" value={val?.code} />
                                            </div>
                                            <div class="col-6">
                                                <div className='apply-button text-end'>
                                                    <button className='btn btn-apply'
                                                     onClick={() => {
                                                        couponApply(val?.code)
                                                    }}
                                                    >APPLY</button>
                                                </div>
                                            </div>
                                        </div>
                                        <h6 class="flat-p">{val?.coupen_message}</h6>
                                        <p class="plat-p-subtext">{val?.coupen_info}</p>
                                    </div>
    
                                </div>
                                    )
                                )

                                
                                            ))}
                            {/* <div className="col-lg-4 col-md-6 mb-3">
                                <CoupenCard />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-3">
                                <CoupenCard />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-3">
                                <CoupenCard />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-3">
                                <CoupenCard />
                            </div>
                            <div className="col-lg-4 col-md-6 mb-3">
                                <CoupenCard />
                            </div> */}
                        </div>
                    </div>

                </div>
            </section>

            <section className='wallet mobile-top-view-wallet'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb my-3">
                                    <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                    <li className="breadcrumb-item active">Wallet</li>
                                </ol>
                            </nav>
                        </div>
                        <div className='col-6'>
                            <Link to='/wallet-transaction'>
                                <div className=' text-end my-3 '>
                                    <button className='btn btn-history'>wallet History</button>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className='row'>
                         <div className='start-wallet-coupen'>
                            
                            <div className='apply-coupen text-center'>
                                <h4>Wallet Balance  :  ₹ {wallet?.amount == null ? "0.00" : wallet?.amount}</h4>
                                <p>Recharge Your Wallet Here</p>
                                <span className='text text-danger text-center'>{showErr ? showErr : ""}</span>
                            </div>
                            
                        </div>
                        <div className='start-wallet-coupen'>
                            <div className='col-lg-4 col-md-6 mx-auto'>
                                <div className="input-group mb-4">
                                    <input type="number" className="form-control" placeholder="Enter amount" aria-label="Recipient's username" aria-describedby="basic-addon2" 
                                     onKeyDown={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                        if(event.key != 'Backspace')
                                         event.preventDefault();
                                            }
                                    }} 
                                    onChange={handleChange}
                                    />
                                      <span className="input-group-text ps-3" id="basic-addon2"  onClick={proceedWallet} >Proceed</span>
                                </div>
                            </div>
                            <div className='apply-coupen text-center'>
                                <h4>Apply Coupon</h4>
                                <p>Take advantage of the coupon and receive a discount enter coupon code below</p>
                                <span className='text text-danger text-center'>{couponErr ? couponErr : ""}</span>
                                <span className='text text-success text-center'>{couponSuccess ? couponSuccess : ""}</span>
                            </div>
                            <div className='col-lg-4 col-md-6 mx-auto'>
                                <div className="input-group mt-3">
                                    <input type="text" className="form-control"  value={couponCode} placeholder="Enter coupon code" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={codeChange} />
                                    <span className="input-group-text ps-4" id="basic-addon2"
                                    onClick={() => {
                                        couponApply(couponCode)
                                    }}
                                    >Apply</span>
                                </div>
                            </div>
                            <div className='apply-coupen text-center mt-1 mb-5'>
                                <h4>or choose from below</h4>
                            </div>
                        </div>


                        {coupons?.map((val, index) => (

                                (val?.coupon_history[0]?.occur < val?.use_per_customer) ? (
                            <div className="col-sm-6 col-6 mb-3">

                                <div class="coupon-div">
                                    <div class="row">
                                        <div class="col-6">
                                            <input class="input-coupon" placeholder="" value={val?.code} />
                                        </div>
                                        <div class="col-6">
                                            <div className='apply-button text-end'>
                                                <button className='btn btn-apply'
                                                onClick={() => {
                                                    couponApply(val?.code)
                                                }}
                                                >APPLY</button>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="flat-p">{val?.coupen_message}</h6>
                                    <p class="plat-p-subtext">{val?.coupen_info}</p>
                                </div>

                            </div>
                                ) : (
                                    <div className="col-sm-6 col-6 mb-3">

                                <div class="coupon-div">
                                    <div class="row">
                                        <div class="col-6">
                                            <input class="input-coupon" placeholder="" value={val?.code} />
                                        </div>
                                        <div class="col-6">
                                            <div className='apply-button text-end'>
                                                <button className='btn btn-apply'
                                                onClick={() => {
                                                    couponApply(val?.code)
                                                }}
                                                >APPLY</button>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 class="flat-p">{val?.coupen_message}</h6>
                                    <p class="plat-p-subtext">{val?.coupen_info}</p>
                                </div>

                            </div> 
                            )
                            ))}
                        {/* <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6  mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div>
                        <div className="col-sm-6 col-6 mb-3">
                            <CoupenCard />
                        </div> */}

                    </div>

                </div>
            </section>
        </>
    )
}

export default Wallet