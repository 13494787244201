import React from "react";
import { useState, useEffect } from "react";
import SweetPagination from "sweetpagination";
import "./Blog.css";
import Category_icon from "./Images/Group 22562.png";
import card_img from "./Images/img4.png";
import arrowimg from "./Images/arrow-down.png";
import img_1 from "../Blog/Images/Untitled design.png";
import img_2 from "../Blog/Images/numerology-collage-concept.png";
import img_3 from "../Blog/Images/are-love-horoscopes-accurate-1.png";
import img_4 from "../Blog/Images/shiva-nataraja-with-diwali-lights.png";
import img_5 from "../Blog/Images/3s75XsSMoZxTyoZViBiQKd-1200-80-1.png";
import img_6 from "../Blog/Images/golden-engagement-ring-hand.png";
import img_7 from "../Blog/Images/fairytale-aurora-graphics-enchanting-elements-magical-charm.png";
import img_8 from "../Blog/Images/romantic-playful-couple-having-fun-nature.png";
import { Link, useParams } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-tabs/style/react-tabs.css';



import { useContext } from "react";
import { Context } from "../../utils/context";
const Blog = () => {


  
  const { id } = useParams();
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const [currentPageData, setCurrentPageData] = useState(new Array(1).fill());
  // Example items, to simulate fetching from another resources.
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context)

  const [blogs, setBlogs] = useState();
  const [pageLinks, setPageLinks] = useState();
  const [blogCategory, setBlogCategory] = useState();
  const [category, setCategory] = useState();
  const getBlogs = async () => {
    const res = await getData("/blogCategory")
    if (res?.success) {
      console.log(res.data, 'BlogsCategory');
      setBlogCategory(res.data.category);
      setCategory(res.data.category[0].id);
      
      if(id != 0){
        getCategoryBlogs(id);
      }else{
        getCategoryBlogs(res.data.category[0].id);
      }
    }
  };

  const getCategoryBlogs = async (id, page) => {
    var res = "";
    if (page) {
      res = await getData(`/categoryBlog?category_id=${id}&${page}`)
    } else {
      res = await getData(`/categoryBlog?category_id=${id}`)
    }

    if (res?.success) {
      console.log(res.data.blogs.links, 'Blogs');
      setBlogs(res.data.blogs.data);
      setPageLinks(res.data.blogs.links);
      setCategory(id);

    }
  };


  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 200) : text}
        <span className="mt-1 ReadMore_txt"
        >{isReadMore ? "..." : ""} </span>
      </p>
    );
  };
  useEffect(() => {

    getBlogs();
    
    

  }, []);


  return (
    <>
      <section className="Blog mb-5 desktop-view-blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-4">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ms-5">
                  <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to='/kundali-match'>Blog</Link>
                  </li>

                </ol>
              </nav>
            </div>
          </div>
          <div className="row me-0 ms-0">
            <div className="col-xl-3 col-lg-4 col-md-6  p-0">
              <div className="card-sidebar pb-5">
                <div className="categary-class pt-3">
                  <img src={Category_icon} alt="Category_icon" className="Category_icon" /><span className="categories-txt"> Categories</span>
                  <div className="icon-txt my-2"></div>
                </div>
                {/* <div className="dashed-line mt-1"></div> */}

                <div className="nav flex-column nav-pills me-3 mt-3 card-body sidebar-section" id="v-pills-tab" role="tablist" aria-orientation="vertical" >
                {blogCategory?.map((val, index) => {
                      const isActive = id == 0 ? index == 0 : id == val?.id;
                      const className = isActive ? "nav-link active" : "nav-link";

                      return (
                        <button
                          className={className}
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected={isActive}
                          onClick={() => {
                            getCategoryBlogs(val?.id);
                          }}
                        >
                          {val?.name}
                        </button>
                      );
                    })}



                  {/* <button className="nav-link" id="v-pills-Tarat-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Tarat"
                    type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" >
                    Tarat
                  </button> */}


                </div>
              </div>
            </div>



            <div className="col-xl-8 col-lg-8 col-md-12 mx-auto">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Autoplay, Pagination]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                // clickable= {true}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
              // autoplay={{
              //   delay: 5000,
              //   disableOnInteraction: false,
              //   loop: true,

              // }}                  
              >

                {/* <div className="row"> */}
                <SwiperSlide>
                  <div className="tab-content" id="v-pills-tabContent">

                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                      tabindex="0"
                    >
                      <div className="row">

                        {blogs?.map((value, index) => (


                          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                            <div className="card mb-4">
                              <img src={imageUrl + value?.image} className="card-section-img" alt="image"
                              />
                              <div className="card-upper ps-3">
                                <p className="card-upper-heading">
                                  {value?.heading}
                                </p>
                                <p className="card-upper-info scale-in-ver-bottom">
                                  {value?.description}
                                </p>

                                <p>
                                  <Link to={`/readMore/${value?.id}`} className="readMore-txt">
                                    Read More &nbsp;
                                    <img src={arrowimg} className="arrow_img" alt="" />

                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>
                              <p className="card-upper-info scale-in-ver-bottom">
                                Lorem ipsum dolor sit amet, consetetur sadipscing
                                elitr, sed diam nonumy eirmod tempor invidunt ut
                                labore et dolore magna.
                              </p>

                              <p>
                                <Link to={"/readMore"} className="readMore-txt">
                                  Read More &nbsp;
                                  <img src={arrowimg} className="arrow_img" alt="" />

                                </Link>
                              </p>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                {/* </div> */}
              </Swiper>

              <div className="col-md-12 mt-5 text-center">
                <div className="pagination ">
                  {pageLinks?.map((val) => (
                    <>
                      {val?.label === "&laquo; Previous" && (
                        <button
                          className="ms-3 previous"
                          onClick={() => {
                            getCategoryBlogs(category, val?.url.split("?")[1]);
                          }}
                        >
                          {htmlToReactParser.parse(val?.label)}
                        </button>
                      )}

                      {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                        <button
                          className={val?.active ? "ms-3 active number" : "ms-3 number"}
                          onClick={() => {
                            getCategoryBlogs(category, val?.url.split("?")[1]);
                          }}
                        >
                          {htmlToReactParser.parse(val?.label)}
                        </button>
                      )}

                      {val?.label === "Next &raquo;" && (
                        <button
                          className="ms-3 next"
                          onClick={() => {
                            getCategoryBlogs(category, val?.url.split("?")[1]);
                          }}
                        >
                          {htmlToReactParser.parse(val?.label)}
                        </button>
                      )}
                    </>
                  ))}



                </div>
              </div>
            </div>


          </div>
        </div>
      </section>

      {/* mobile-view-start */}
      <section className="Blog  mobile-view-blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-3">
                  <li class="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link >Blog</Link>
                  </li>

                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="d-flex ps-0 mb-2">
              <div>
                <img src={Category_icon} className="Category_icon me-2" alt="Category_icon " />
              </div>

              <div>
                <h3 className="mt-1 text-center categories-txt">
                  Categories
                </h3>
              </div>
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="nav sideBoxShadowblog nav-pills">
                <Swiper
                  modules={[Navigation, Pagination]}
                  pagination={true}
                  clickable={true}
                  navigation={false}
                  loop={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    320: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    400: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                    425: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    567: {
                      slidesPerView: 5,
                      // spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 5,
                      // spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 5,
                      // spaceBetween: 10,
                    },

                  }}

                  className="" >
                  {blogCategory?.map((val, index) => (
                    <SwiperSlide className='day-swiper'>
                      <Nav.Item >
                        <Nav.Link
                          eventKey={val?.id}
                          className="nav-link mt-2"
                          onClick={() => {
                            getCategoryBlogs(val?.id);
                          }}
                        >
                          {val?.name}
                        </Nav.Link>
                      </Nav.Item>
                    </SwiperSlide>
                  ))}
                  {/* <SwiperSlide className='day-swiper'>
                    <Nav.Item >
                      <Nav.Link eventKey="second" className="nav-link mt-2">
                        Tarat
                      </Nav.Link>
                    </Nav.Item>
                  </SwiperSlide> */}

                </Swiper>
              </Nav>


              <Tab.Content>
                <Tab.Pane eventKey={category}>
                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Autoplay, Pagination]}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation={false}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}

                  >
                    <SwiperSlide className="">
                      <div className="row mt-4">
                        {blogs?.map((value, index) => (
                          <div className=" col-sm-6 col-6">
                            <div className="card mb-4">
                              <img src={imageUrl + value?.image} className="card-section-img" alt="image" />
                              <div className="card-upper ps-3">
                                <p className="card-upper-heading">
                                  {value?.heading}
                                </p>


                                <Link to={`/readMore/${value?.id}`} className="readMore-txt">
                                  Read More &nbsp;
                                  <img src={arrowimg} className="arrow_img" alt="" />

                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Top 7 Most Socially Active Zodiac Signs
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img
                              src={img_5}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                What is Dukha as per Yoga Vasistha?
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-5">
                            <img
                              src={img_4}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Who are Rahu?
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                      <div className="row mt-4">
                        <div className="col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_7} className="card-section-img" alt="image" />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img
                              src={img_5}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-5">
                            <img
                              src={img_4}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>

                      </div>
                    </SwiperSlide> */}
                  </Swiper>
                </Tab.Pane>

                <div className="col-md-12 mt-5 text-center">
                  <div className="pagination ">
                    {pageLinks?.map((val) => (
                      <>
                        {val?.label === "&laquo; Previous" && (
                          <button
                            className="ms-3 previous"
                            onClick={() => {
                              getCategoryBlogs(category, val?.url.split("?")[1]);
                            }}
                          >
                            {htmlToReactParser.parse(val?.label)}
                          </button>
                        )}

                        {val?.label !== "&laquo; Previous" && val?.label !== "Next &raquo;" && (
                          <button
                            className={val?.active ? "ms-3 active number" : "ms-3 number"}
                            onClick={() => {
                              getCategoryBlogs(category, val?.url.split("?")[1]);
                            }}
                          >
                            {htmlToReactParser.parse(val?.label)}
                          </button>
                        )}

                        {val?.label === "Next &raquo;" && (
                          <button
                            className="ms-3 next"
                            onClick={() => {
                              getCategoryBlogs(category, val?.url.split("?")[1]);
                            }}
                          >
                            {htmlToReactParser.parse(val?.label)}
                          </button>
                        )}
                      </>
                    ))}



                  </div>
                </div>


                {/* <Tab.Pane eventKey="second">
                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Autoplay, Pagination]}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation={false}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}

                  >
                    <SwiperSlide className="">
                      <div className="row mt-4">
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_7} className="card-section-img" alt="image" />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img
                              src={img_5}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-5">
                            <img
                              src={img_4}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>

                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row mt-4">
                        <div className="col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_7} className="card-section-img" alt="image" />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>

                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img
                              src={img_5}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-5">
                            <img
                              src={img_4}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>

                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Autoplay, Pagination]}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation={false}
                    pagination={{ clickable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}

                  >
                    <SwiperSlide className="">
                      <div className="row mt-4">
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_7} className="card-section-img" alt="image" />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>

                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>

                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img
                              src={img_5}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-5">
                            <img
                              src={img_4}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>

                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row mt-4">
                        <div className="col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_7} className="card-section-img" alt="image" />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>

                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img src={img_2} className="card-section-img" alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-4">
                            <img
                              src={img_5}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className=" col-sm-6 col-6">
                          <div className="card mb-5">
                            <img
                              src={img_4}
                              className="card-section-img"
                              alt="image"
                            />
                            <div className="card-upper ps-3">
                              <p className="card-upper-heading">
                                Tarot and Numerlogy
                              </p>


                              <Link to={"/readMore"} className="readMore-txt">
                                Read More &nbsp;
                                <img src={arrowimg} className="arrow_img" alt="" />

                              </Link>
                            </div>
                          </div>
                        </div>

                      </div>
                    </SwiperSlide>
                  </Swiper>
                </Tab.Pane>
                <Tab.Pane eventKey="four">

                </Tab.Pane>
                <Tab.Pane eventKey="fifth">

                </Tab.Pane>

                <Tab.Pane eventKey="six">
                </Tab.Pane>
                <Tab.Pane eventKey="seven">
                </Tab.Pane>
                <Tab.Pane eventKey="eight">
                </Tab.Pane>
                <Tab.Pane eventKey="nine">
                </Tab.Pane>
                <Tab.Pane eventKey="ten">
                </Tab.Pane>
                <Tab.Pane eventKey="eleven">
                </Tab.Pane>
                <Tab.Pane eventKey="twelve">
                </Tab.Pane>
                <Tab.Pane eventKey="thirteen">
                </Tab.Pane>
                <Tab.Pane eventKey="fourteen">
                </Tab.Pane>
                <Tab.Pane eventKey="fifteen">
                </Tab.Pane>
                <Tab.Pane eventKey="sixteen">
                </Tab.Pane>
                <Tab.Pane eventKey="seventeen">
                </Tab.Pane> */}
              </Tab.Content>


            </Tab.Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
