import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";


import {
  getData,
  postData,
  getEditData,
  editData,
  editStatusData,
  postFormData,
  deleteData,
} from "./api";

export const Context = createContext();

const AppContext = ({ children }) => {
  const IMG_URL = "https://astro-booster-backend.profcymabackend.com/";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [freeKundli, setFreeKundli] = useState(true);
  const [ReactLoader, setReactLoader] = useState(true);
  const [filterData, setFilterData] = useState();
  const [astrologers, setAstrologer] = useState();
  const [talkFilterURL, settalkFilterURL] = useState("/astrologerDetails");
  const [chatFilterURL, setchatFilterURL] = useState("/astrologerDetails");
  // const imageUrl = "https://astro.profcyma.org/";
  const imageUrl = "https://astro-booster-backend.profcymabackend.com/";
  return (
    <Context.Provider
      value={{
        // minLength,
        // maxLength,
        getData,
        postData,
        postFormData,
        getEditData,
        editData,
        editStatusData,
        deleteData,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        // Kundli Data
        ReactLoader,
        setReactLoader,
        imageUrl,
        freeKundli,
        setFreeKundli,
        filterData,
        setFilterData,
        astrologers,
        setAstrologer,
        talkFilterURL,
        settalkFilterURL,
        chatFilterURL,
        setchatFilterURL,
        // ErrorNotify,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
