import React from 'react'
import './App.css'
import { Route, Routes } from "react-router-dom";
import Header from './component/home-page/header/Header';
import Home from './component/home-page/Home';
import ChatAstrolger from './component/chat-astrologer/ChatAstrolger';
import TalkAstrologer from './component/talk-to-astrologer/TalkAstrologer'
import Footer from './component/home-page/Footer/Footer';
import AStrologerProfile from './component/Astrologer-profile/AStrologerProfile';
import Kundali from './component/Kundali/Kundali';
import KundliMatching from './component/KundliMatching/KundliMatching';
import Blog from './component/Blog/Blog';
import ReadMore from './component/Blog/ReadMore/ReadMore'
import FreeKundali from './component/free-kundali/FreeKundali';
import Free_Kundli from './component/free-kundali/Free_Kundli_tabs/Free_Kundli'
import SecondKundali from './component/free-kundali/Second-kundali/SecondKundali';
import KpKundali from './component/free-kundali/kp-kundali/KpKundali';
import Kundali_History from './component/Kundali/Kundli_modal/Kundli_History'
import NoReviewAstrologer from './component/Astrologer-profile/no-review-astrologer/NoReviewAstrologer';
import ChatSection from './component/Astrologer-profile/ChatSection/ChatSection'
import Wallet from './component/wallet/Wallet';
import WalletPayment from './component/wallet/wallet-payment/WalletPayment';
import Festival from './component/festival/Festival';
import Horoscope2023 from './component/Horoscopes/Horoscope2023/Horoscope2023';
import Today_Horoscope from './component/Horoscopes/Today_Horoscope/Today_Horoscope';
import Yearly_Horoscope from './component/Horoscopes/Yearly_Horoscope/Yearly_Horoscope';
import Tomorrow_Horoscope from './component/Horoscopes/Tomorrow_Horoscope/Tomorrow_Horoscope';
import Weekly_Horoscope from './component/Horoscopes/Weekly_Horoscope/Weekly_Horoscope';
import Monthly_Horoscope from './component/Horoscopes/Monthly_Horoscope/Monthly_Horoscope';
import Daily_Horoscope from './component/Horoscopes/Daily_Horoscope/Daily_Horoscope';
import Yesterday_Horoscope from './component/Horoscopes/Yesterday_Horoscope/Yesterday_Horoscope';
import Chinese_Horoscope from './component/Horoscopes/Chinese_Horoscope/Chinese_Horoscope';
import OxAnimal from './component/Horoscopes/all-chinese-inner-pages/ox-animal-page/OxAnimal';
import Tiger from './component/Horoscopes/all-chinese-inner-pages/tiger-page/Tiger';
import Rabbit from './component/Horoscopes/all-chinese-inner-pages/rabbit/Rabbit';
import Aries_horoscope from './component/Horoscopes/Slider_pages/Aries_horoscope/Aries_horoscope';
import Tarus_horoscope from './component/Horoscopes/Slider_pages/Tarus_horoscope/Tarus_horoscope';
import Gemini_horoscope from './component/Horoscopes/Slider_pages/Gemini_horoscope/Gemini_horoscope';
import Cancer_horoscope from './component/Horoscopes/Slider_pages/Cancer_horoscope/Cancer_horoscope';
import Free_Report from './component/free-kundali/Free_Report/Free_Report';
import Notification from './component/profile/notification/Notification';
import WalletTranctionProfile from './component/profile/wallet-tranction/WalletTranctionProfile';
import ChatHistory from './component/profile/chat-history/ChatHistory';
import DishaReview from './component/talk-to-astrologer/disha-review/DishaReview';
import CallHistory from './component/profile/call-history/CallHistory';
import FreeAshtakvarga from './component/free-kundali/free-ashtakvarga/FreeAshtakvarga';
import FreeChart from './component/free-kundali/free-chart/FreeChart';
import Dasha from './component/free-kundali/Dasha/Dasha'
import ChatSecondScreen from './component/Astrologer-profile/ChatSection/ChatSecondScreen';
import ChatSectiondisha from './component/chat-astrologer/ChatSectiondisha/ChatSectiondisha';
import ChatSecondScreendisha from './component/chat-astrologer/ChatSectiondisha/ChatSecondScreendisha';
import TodayPanchang from './component/Horoscopes/today-panchang/TodayPanchang';
import Compatibility from './component/Horoscopes/compatibility/Compatibility';
import January from './component/festival/mobile-view-festivalcalender/January';
import February from './component/festival/mobile-view-festivalcalender/February';
import MarchFestival from './component/festival/mobile-view-festivalcalender/MarchFestival';
import AprilFestival from './component/festival/mobile-view-festivalcalender/AprilFestival';
import MayFestival from './component/festival/mobile-view-festivalcalender/MayFestival';
import JuneFestival from './component/festival/mobile-view-festivalcalender/JuneFestival';
import JulyFestival from './component/festival/mobile-view-festivalcalender/JulyFestival';
import AugustFestival from './component/festival/mobile-view-festivalcalender/AugustFestival';
import SeptemberFestival from './component/festival/mobile-view-festivalcalender/SeptemberFestival';
import OctoberFestival from './component/festival/mobile-view-festivalcalender/OctoberFestival';
import NovemberFestival from './component/festival/mobile-view-festivalcalender/NovemberFestival';
import DecemberFestival from './component/festival/mobile-view-festivalcalender/DecemberFestival';
import Dragon from './component/Horoscopes/all-chinese-inner-pages/dragon-page/Dragon';
import Snake from './component/Horoscopes/all-chinese-inner-pages/snake-page/Snake';
import Horse from './component/Horoscopes/all-chinese-inner-pages/horse-page/Horse';
import Ram from './component/Horoscopes/all-chinese-inner-pages/Ram-page/Ram';
import Monkey from './component/Horoscopes/all-chinese-inner-pages/Monkey-page/Monkey';
import Rooster from './component/Horoscopes/all-chinese-inner-pages/Rooster-page/Rooster';
import Dog from './component/Horoscopes/all-chinese-inner-pages/dog-page/Dog';
import Pig from './component/Horoscopes/all-chinese-inner-pages/pig-page/Pig';
import Rat from './component/Horoscopes/all-chinese-inner-pages/Rat-page/Rat';
import ChatHistoryProfile from './component/Astrologer-profile/ChatSection/ChatHistoryProfile';
import Login from './component/Authentication/Login/Login';
import Register from './component/Authentication/Register/Register';
import ForgetPassword from './component/Authentication/ForgetPassword/ForgetPassword';
import Otp from './component/Authentication/Otp/Otp';
import NewPassword from './component/Authentication/NewPassword/NewPassword';
import Profile from './component/profile/Profile/Profile';
import EditProfile from './component/profile/Profile/EditProfile/EditProfile';
import AppContext from './utils/context'
const App = () => {
  return (
    <>
    <AppContext>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat-astrologer" element={<ChatAstrolger />} />
        <Route path="/talk-astrologer" element={<TalkAstrologer />} />
        <Route path="/astrologer-profile/:id" element={<AStrologerProfile />} />
        <Route path="/kundali" element={<Kundali />} />
        <Route path="/kundali-match" element={<KundliMatching />} />
        <Route path="/blog/:id" element={<Blog />} />
        <Route path="/readMore/:id" element={<ReadMore />} />
        <Route path="/freekundali" element={<FreeKundali />} />
        <Route path="/Basic-Kundali" element={<Free_Kundli />} />
        <Route path="/second-Kundali" element={<SecondKundali />} />
        <Route path="/kp-Kundali" element={<KpKundali />} />
        <Route path="/Kundali-history" element={<Kundali_History />} />
        <Route path="/no-review-profile" element={<NoReviewAstrologer />} />
        <Route path="/chat-section" element={<ChatSection />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/wallet-payment" element={<WalletPayment />} />
        <Route path="/festival-calender" element={<Festival />} />
        <Route path="/horoscope2023" element={<Horoscope2023 />} />
        <Route path="/yearly_Horoscope" element={<Yearly_Horoscope />} />
        <Route path="/today_Horoscope" element={<Today_Horoscope />} />
        <Route path="/tomorrow_Horoscope" element={<Tomorrow_Horoscope />} />
        <Route path="/weekly_Horoscope" element={<Weekly_Horoscope />} />
        <Route path="/monthly_Horoscope" element={<Monthly_Horoscope />} />
        <Route path="/daily_Horoscope" element={<Daily_Horoscope />} />
        <Route path="/yesterday_Horoscope" element={<Yesterday_Horoscope />} />
        <Route path="/chinese_Horoscope" element={<Chinese_Horoscope />} />
        <Route path="/today-panchang" element={<TodayPanchang />} />
        <Route path="/Compatibility" element={<Compatibility />} />
        <Route path="/festival-calender" element={<Festival />} />
        <Route path="/ox-animal" element={<OxAnimal />} />
        <Route path="/tiger" element={<Tiger />} />
        <Route path="/rabbit" element={< Rabbit />} />
        <Route path="/dragon-chinese" element={<Dragon />} />
        <Route path="/snake-chinese" element={<Snake />} />
        <Route path="/horse-chinese" element={<Horse />} />
        <Route path="/ram-chinese" element={<Ram />} />
        <Route path="/monkey-chinese" element={<Monkey />} />
        <Route path="/rooster-chinese" element={<Rooster/>} />
        <Route path="/dog-chinese" element={<Dog/>} />
        <Route path="/pig-chinese" element={<Pig/>} />
        <Route path="/rat-chinese" element={<Rat/>} />
        <Route path="/aries_horoscope/:id" element={<Aries_horoscope />} />
        <Route path="/tarus_horoscope" element={<Tarus_horoscope />} />
        <Route path="/gemini_horoscope" element={<Gemini_horoscope />} />
        <Route path="/cancer_horoscope" element={<Cancer_horoscope />} />
        <Route path="/free_Report" element={<Free_Report />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/wallet-transaction" element={<WalletTranctionProfile />} />
        <Route path="/chat-history" element={<ChatHistory />} />
        <Route path="/disha-review" element={<DishaReview />} />
        <Route path="/call-history" element={<CallHistory />} />
        <Route path="/free-ashtkvarga" element={<FreeAshtakvarga />} />
        <Route path="/free-chart" element={<FreeChart />} />
        <Route path="/free-dasha" element={<Dasha />} />
        <Route path="/chat-second" element={<ChatSecondScreen />} />
        <Route path="/chat-section-disha" element={<ChatSectiondisha />} />
        <Route path="/chat-second-disha" element={<ChatSecondScreendisha />} />
        <Route path="/festival-january/:id/:month" element={<January />} />
        <Route path="/festival-february" element={<February />} />
        <Route path="/festival-march" element={<MarchFestival />} />
        <Route path="/festival-april" element={<AprilFestival />} />
        <Route path="/festival-may" element={<MayFestival />} />
        <Route path="/festival-june" element={<JuneFestival />} />
        <Route path="/festival-july" element={<JulyFestival />} />
        <Route path="/festival-august" element={<AugustFestival />} />
        <Route path="/festival-september" element={<SeptemberFestival />} />
        <Route path="/festival-october" element={<OctoberFestival />} />
        <Route path="/festival-november" element={<NovemberFestival />} />
        <Route path="/festival-december" element={<DecemberFestival />} />
        <Route path="/ChatHistory-profile" element={<ChatHistoryProfile />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/newPassword" element={<NewPassword />} />
        <Route path='/profle' element={<Profile/>}/>
        <Route path='/editprofile' element={<EditProfile/>}/>
      </Routes>
      <Footer />
      </AppContext>
    </>
  )
}

export default App