import React, {useState, useEffect} from 'react'
import './AstrologerProfile.css';
import '../chat-astrologer/Chatastrolger.css'
import { Link, useParams } from "react-router-dom";
import profileimg from './Image/businessman-black-suit.png';
import callimg from './Image/ios-call.png';
import chatimg from './Image/chatbubbles.png';
// import rateimg from './Image/md-star.png';
import chatmodalimg from './Image/phone-call.png';
import daysarrowimg from './Image/dropright-circle.png';
import commentimg from './Image/comment-1.png';
import rateimg1 from './Image/star-outline1.png'
import rateimg from './Image/star-outline.png'
import SubmitModal1 from './submit-modal/SubmitModal1';
import CallModalAstro from './call-modal-astro/CallModalAstro';
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import dummyimg from '../home-page/image/dummy-user.png';
import CheckLottey from '../wallet/check-lottey/CheckLottey';



import { useContext } from "react";
import { Context } from "../../utils/context";
import { postData } from '../../utils/api';
const AStrologerProfile = () => {


    const  { id } = useParams();
    const [authToken, setAuthToken] = useState(null);
    const { getData,postData, imageUrl } = useContext(Context);
    const [astrologer, setAstrologerDetail] = useState();
    const [rating, setRating] = useState(1);
    const [message, setMessage] = useState("");
    const [messageErr, setMessageErr] = useState("");
    const [name, setName] = useState("");
    const [nameErr, setNameErr] = useState("");
    const [reviewaccess, setReviewAccess] = useState(false);
    const [reviews, setReview] = useState();
    const [showModal, setShowModal] = useState(false);



    const getAstrologerDetails = async () => {
      
    
              const res = await getData(`/astrologerDetails/${id}`);
        
        if (res?.success) {
            console.log(res.data, 'astrologerDetail');
            setAstrologerDetail(res.data.astrologer);
            getReview(res.data.astrologer.id);
            setReview(res.data.reviews);
        }
    };

    const getReview = async (id) => {
      
    
        const res = await getData(`/getReview?astrologer_id=${id}`);
        console.log(res, 'userReview');
        if (res?.success) {
            setReviewAccess(false);
        }else{
            setReviewAccess(true);
        }
        };


    const handleSubmit = async () => {
      
        var error = 0;
        if(message.trim() === ""){
            setMessageErr("Message Required...!");
            error++;
        }
        else if(message.length < 70){
            setMessageErr("Message should be atleast of 75 to 100 words...!");
            error++;
        }
        if(name.trim() === ""){
            setNameErr("Name Required...!");
            error++;
        }

        if(error > 0){
            return;
        }

        console.log(rating + " " + message,'review');

            const res = await postData(`/astrologerReview?star=${rating}&message=${message}&name=${name}&astrologer_id=${astrologer?.id}`);

            if(res?.success){
                setShowModal(true);
                setTimeout(() => {
                    window.location.href = `/astrologer-profile/${astrologer?.id}`;
                }, 2000);
            }

     };

    



    useEffect(() => {

        getAstrologerDetails();
        
        if(sessionStorage.getItem('astrotoken')){
            setAuthToken(sessionStorage.getItem('astrotoken'));
          }

    }, []);
    return (
        <>
            <section className="chat-astrologer desktop-view-astrologer-profile">
                <div className="container">
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                <li className="breadcrumb-item "><Link to='/talk-astrologer'> Talk to Astrologers</Link></li>
                                <li className="breadcrumb-item active" > Paresh Kumar</li>
                            </ol>
                        </nav>

                        <div className='col-md-12'>
                            <div className='heading-holder text-center'>
                                <h2>Our<span className='ms-3'>Experts</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div className="line me-3"></div>
                                <div className="square"></div>
                                <div className="line ms-3"></div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-xl-3 col-lg-4 col-md-5'>
                            <div className='image-profile text-xl-start text-lg-start text-md-center text-center'>
                                <img src={imageUrl + astrologer?.profile_pic} className='bussiness-man-image' />
                            </div>
                        </div>
                        <div className='col-xl-5 col-lg-5 col-md-7'>
                            <div className='profile-details mt-2'>
                                <h3>{astrologer?.name}</h3>
                                <p>
                                {astrologer?.astrologer_skill?.map((value, index) => (
                                                                    <>
                                                                        {value?.skill?.name}
                                                                        {index < astrologer?.astrologer_skill.length - 1 && (
                                                                            <span>,</span>
                                                                        )}
                                                                    </>
                                                                ))}
                                    </p>
                                <p>Exp : {astrologer?.astrologer_detail?.experience} Years</p>
                                <span >Available</span>
                            </div>


                            <div className='button-profile my-3'>
                                <button className='btn btn-call me-2'><img src={callimg} className='chat-img me-2 mt-2' /> Call Rate <span className='ms-3'>150/min</span></button>
                                <button className='btn btn-call me-2'><img src={chatimg} className='chat-img me-2 mt-2' /> Chat Rate <span className='ms-3'>170/min</span></button>
                                <button className='btn btn-call'><img src={rateimg} className='chat-img me-2 mt-2' /> Rating <span className='ms-3'>4.96</span></button>
                            </div>

                        </div>
                        <div className='col-xl-4 col-lg-3 '>
                            <div className='chat-holder text-lg-end text-md-center text-center'>
                                <Link to='/chat-section'> <button type="button" className='btn btn-chat1' >Chat</button></Link>
                            </div>
                            <div className='chat-holder text-lg-end text-md-center text-center mt-3'>
                                <button type="button" className='btn btn-call' data-bs-toggle="modal" data-bs-target="#callModal1">Call</button>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='about mt-2'>
                                <h4>ABOUT :</h4>
                                <p>I am a Commerce Graduate from University of North Bengal, Besides topping from Institute of Vedic Astrology (A+),
                                    I also attended Vedic Vastu workshop From The Art of Living International Centre, Bengaluru. I also received appreciation
                                    awards from the Institute of Vedic Astrology for contribution in Vedic Science. And my spiritual journeybegan in the early years of my Adulthood.
                                    I developed a natural interest in astrology and after rigorous meditation and practice I began practicing the science of Astrology, and Vaastu professionally.
                                    Over years I have helped many Friends, Family members Individuals, Industrialists, Business Owners, Clients for all common issues of life related to health, wealth, job,
                                    career, sickness, marital issues and material success.
                                    I like to advise all without any bias regarding the social strata and like to keep the solutions simple.</p>
                            </div>
                            <div className='row  availability-days mt-3'>
                                <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6 '>
                                    <div className='availability mb-3'>
                                        <div className='btn btn-availability py-3'>Availability</div>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                    <div className=' days-card mb-3'>
                                        <div className='days-body'>
                                            <div className='days-img'>
                                                <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Monday</span>
                                                <div className='border-days ms-3'></div>
                                            </div>
                                            <div className='timming ms-3'>
                                                <p>09:30 AM - 02:00 PM</p>
                                                <p>04:30 PM - 09:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                    <div className=' days-card mb-3'>
                                        <div className='days-body'>
                                            <div className='days-img'>
                                                <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Tuesday</span>
                                                <div className='border-days ms-3'></div>
                                            </div>
                                            <div className='timming ms-3'>
                                                <p>09:30 AM - 02:00 PM</p>
                                                <p>04:30 PM - 09:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                    <div className=' days-card mb-3'>
                                        <div className='days-body'>
                                            <div className='days-img'>
                                                <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Wednesday</span>
                                                <div className='border-days ms-3'></div>
                                            </div>
                                            <div className='timming ms-3'>
                                                <p>09:30 AM - 02:00 PM</p>
                                                <p>04:30 PM - 09:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                    <div className=' days-card mb-3'>
                                        <div className='days-body'>
                                            <div className='days-img'>
                                                <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Thursday</span>
                                                <div className='border-days ms-3'></div>
                                            </div>
                                            <div className='timming ms-3'>
                                                <p>09:30 AM - 02:00 PM</p>
                                                <p>04:30 PM - 09:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-2 col-lg-3 col-md-4 col-sm-6'>
                                    <div className=' days-card mb-3'>
                                        <div className='days-body'>
                                            <div className='days-img'>
                                                <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Friday</span>
                                                <div className='border-days ms-3'></div>
                                            </div>
                                            <div className='timming ms-3'>
                                                <p>09:30 AM - 02:00 PM</p>
                                                <p>04:30 PM - 09:00 PM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </section>
            

            <SubmitModal1 />
            <CallModalAstro />
            {/* desktop-view-end */}


            {/* mobile-view-start */}
            <section className="chat-astrologer mobile-view-astrologer-profile">
                <div className="container">
                    <div className='row'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mt-3">
                                <li className="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                                <li className="breadcrumb-item "><Link to='/talk-astrologer'> Talk to Astrologers {'>'}</Link></li>
                                <li className="breadcrumb-item active" > Paresh Kumar</li>
                            </ol>
                        </nav>

                        <div className='col-md-12 mb-4'>
                            <div className='heading-holder text-center'>
                                <h2>Our<span className='ms-1'>Experts</span> </h2>
                            </div>
                            <div className="main-heading mt-1">
                                <div className="line me-2"></div>
                                <div className="square"></div>
                                <div className="line ms-2"></div>
                            </div>
                        </div>
                    </div>

                    <div className='row '>
                        <div className=' col-sm-12'>
                            <div className='image-profile text-xl-start text-lg-start text-md-center text-center'>
                                <img src={profileimg} className='bussiness-man-image' />
                            </div>
                        </div>
                        <div className='col-sm-8 col-8'>
                            <div className='profile-details mt-3'>
                                <h3>PARESH KUMAR</h3>
                                <p>Vedic, Palmistry, Face Reading<br /> English, Hindi & Marathi</p>
                                <p>Exp : 15 Years</p>
                                <span >Available</span>
                            </div>
                        </div>

                        <div className='col-sm-4 col-4'>
                            <div className='chat-holder text-lg-end text-md-center text-center mt-3'>
                                <Link to='/chat-section'> <button type="button" className='btn btn-chat1' >Chat</button></Link>
                            </div>
                            <div className='chat-holder text-lg-end text-md-center text-center mt-1'>
                                <button type="button" className='btn btn-call' data-bs-toggle="modal" data-bs-target="#callModal1">Call</button>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-4'>
                                <div className='button-profile '>
                                    <div className='d-flex p-1'>
                                        <div>
                                            <img src={callimg} className='chat-img me-2 mt-2' />
                                        </div>
                                        <div>
                                            <p>Call Rate</p>
                                            <span>150/min</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='button-profile '>
                                    <div className='d-flex p-1'>
                                        <div>
                                            <img src={chatimg} className='chat-img me-2 mt-2' />
                                        </div>
                                        <div>
                                            <p>Chat Rate</p>
                                            <span>150/min</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='button-profile '>
                                    <div className='d-flex p-1'>
                                        <div>
                                            <img src={rateimg} className='chat-img me-2 mt-2' />
                                        </div>
                                        <div>
                                            <p>Rating</p>
                                            <span>4.96</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='about mt-4'>
                                <h4>ABOUT :</h4>
                                <p>I am a Commerce Graduate from University of North Bengal, Besides topping from Institute of Vedic Astrology (A+),
                                    I also attended Vedic Vastu workshop From The Art of Living International Centre, Bengaluru. I also received appreciation
                                    awards from the Institute of Vedic Astrology for contribution in Vedic Science. And my spiritual journeybegan in the early years of my Adulthood.
                                    I developed a natural interest in astrology and after rigorous meditation and practice I began practicing the science of Astrology, and Vaastu professionally.
                                    Over years I have helped many Friends, Family members Individuals, Industrialists, Business Owners, Clients for all common issues of life related to health, wealth, job,
                                    career, sickness, marital issues and material success.
                                    I like to advise all without any bias regarding the social strata and like to keep the solutions simple.</p>
                            </div>
                        </div>
                        <div className='row   mt-3'>
                            <div className=' col-sm-12 '>
                                <div className='availability mb-3'>
                                    <div className='btn btn-availability '>Availability</div>
                                </div>
                            </div>

                            <div className='col-md-12 mt-2'>
                                <Swiper
                                    modules={[Navigation, Autoplay]}
                                    pagination={false}
                                    navigation={false}
                                    loop={true}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        420: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        567: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 10,
                                        },

                                    }}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                    // onSlideChange={() => console.log("slide change")}
                                // autoplay={{
                                //   delay: 2500,
                                //   disableOnInteraction: false,
                                //   loop: true,
                                //   pauseOnMouseEnter: true,
                                // }}
                                >
                                    <SwiperSlide className='days-swiper'>
                                        <div className=' days-card mb-2'>
                                            <div className='days-body'>
                                                <div className='days-img'>
                                                    <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Monday</span>
                                                    <div className='border-days ms-3'></div>
                                                </div>
                                                <div className='timming ms-3'>
                                                    <p>09:30 AM - 02:00 PM</p>
                                                    <p>04:30 PM - 09:00 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>


                                    <SwiperSlide className='days-swiper'>
                                        <div className=' days-card mb-2'>
                                            <div className='days-body'>
                                                <div className='days-img'>
                                                    <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Tuesday</span>
                                                    <div className='border-days ms-3'></div>
                                                </div>
                                                <div className='timming ms-3'>
                                                    <p>09:30 AM - 02:00 PM</p>
                                                    <p>04:30 PM - 09:00 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide className='days-swiper'>

                                        <div className=' days-card mb-2'>
                                            <div className='days-body'>
                                                <div className='days-img'>
                                                    <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Wednesday</span>
                                                    <div className='border-days ms-3'></div>
                                                </div>
                                                <div className='timming ms-3'>
                                                    <p>09:30 AM - 02:00 PM</p>
                                                    <p>04:30 PM - 09:00 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='days-swiper'>
                                        <div className=' days-card mb-2'>
                                            <div className='days-body'>
                                                <div className='days-img'>
                                                    <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Thursday</span>
                                                    <div className='border-days ms-3'></div>
                                                </div>
                                                <div className='timming ms-3'>
                                                    <p>09:30 AM - 02:00 PM</p>
                                                    <p>04:30 PM - 09:00 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide className='days-swiper'>
                                        <div className=' days-card mb-2'>
                                            <div className='days-body'>
                                                <div className='days-img'>
                                                    <img src={daysarrowimg} className='days-arrow-img me-2' /><span>Friday</span>
                                                    <div className='border-days ms-3'></div>
                                                </div>
                                                <div className='timming ms-3'>
                                                    <p>09:30 AM - 02:00 PM</p>
                                                    <p>04:30 PM - 09:00 PM</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>

                    {/* <div className='row all-comment mt-4'>
                        <div className='col-md-12'>
                            <div className="main-heading mt-3">
                                <div className="line1 me-3"></div>
                                <div className="square"></div>
                                <div className="line1 ms-3"></div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='heading-comment mt-5'>
                                <h5>ALL COMMENTS</h5>
                                <div className='heading-border'></div>
                            </div>
                            <div className='heading-comment mt-4 mb-0'>
                                <Link to='/no-review-profile'>  <h5>No Review</h5></Link>
                            </div>
                            <hr />
                            <div className='heading-comment mt-4'>
                                <h6>ADD A REVIEW</h6>
                                <p className='mb-1'>Your email address will not be published. Required fields are marked *</p>
                                <p className='mb-1'>Your rating*</p>
                            </div>
                            <div className='d-flex'>
                                <div className='rating-comment px-2'>
                                    <img src={rateimg1} className='rating-image ' />
                                </div>
                                <div className='rating-comment px-2'>
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                </div>
                                <div className='rating-comment px-2'>
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                </div>
                                <div className='rating-comment px-2'>
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                </div>
                                <div className='rating-comment1 px-2'>
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                    <img src={rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />
                                </div>
                            </div>


                            <form className="row mt-5">
                                <div className='heading-comment'>
                                    <p>Your review*</p>
                                </div>
                                <div className="mb-3">
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" placeholder='Your Message'></textarea>
                                </div>
                                <div className="mb-3">
                                    <input type="text" className="form-control from-input" placeholder="Name*" />
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control from-input" placeholder="Email*" />
                                </div>
                                <div className='col-11'>
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label" for="flexCheckDefault">
                                        Save my name, email, and website in this browser for the next time I comment.
                                    </label>
                                </div>
                                </div>
                                <div className="col-12 mb-5">
                                    <button type="button" className="btn btn-reply" >Submit</button>
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
            </section >

            <section className="chat-astrologer">
                <div className="container">
                    <div className='row all-comment mt-4'>
                        <div className='col-md-12'>
                            <div className="main-heading mt-3">
                                <div className="line1 me-3"></div>
                                <div className="square"></div>
                                <div className="line1 ms-3"></div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='heading-comment mt-5'>
                                <h5>ALL COMMENTS</h5>
                                <div className='heading-border'></div>
                            </div>
                            {reviews?.map((val) => (
                            <div className='d-flex comment-border mt-4'>
                                <div className='image-comment'>
                                    <img src={val?.user?.profile_pic == null ? dummyimg : imageUrl + val?.user?.profile_pic} className='comment-profile-img me-3' />
                                </div>
                                <div className='name-comment mb-3'>
                                    <h4>{val?.name}</h4>
                                    <div className='d-flex'>
                                        <div className='date-comment pt-1'>
                                            <p>
                                            {(new Date(val?.created_at)).toLocaleDateString('en-US', { day: '2-digit' })} 
                                            {(new Date(val?.created_at)).toLocaleDateString('en-US', { month: 'short' })} ,
                                            {(new Date(val?.created_at)).toLocaleDateString('en-US', {  year: 'numeric' })}
                                            </p>
                                        </div>
                                        <div className='rating-comment1 ms-2'>
                                            {[...Array(parseInt(val?.star))].map(function () {
                                            return <img src={rateimg} className='rating-image me-1' />;
                                            })}
                                             {[...Array(parseInt(5 - val?.star))].map(function () {
                                            return <img src={rateimg1} className='rating-image me-1' />;
                                            })}
                                        </div>
                                    </div>
                                    <span>{val?.message}</span>

                                    {/* <div className='d-flex mt-4'>
                                        <div className='image-comment'>
                                            <img src={commentimg} className='comment-profile-img me-3' />
                                        </div>
                                        <div className='name-comment '>
                                            <h4>Paresh Kumar</h4>
                                            <div className='date-comment'>
                                                <p>May 17, 2023</p>
                                            </div>
                                            <span>Thank you🙏. best wishes.</span>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                            ))}

                            
                            {reviews?.length == 0 && (
                            <div className='heading-comment mt-4'>
                               <h5>No Review</h5>
                            </div>
                            )}

                            
                            {authToken != null && reviewaccess &&(
                               
                                <>
                                 <hr />
                            <div className='heading-comment mt-4'>
                                <h5>ADD A REVIEW</h5>
                                <p>Your email address will not be published. Required fields are marked *</p>
                                <p>Your rating*</p>
                            </div>
                            <div className='d-flex'>
                                <div className='rating-comment px-2' onClick={() => setRating(1)}>
                                   
                                    {[...Array(parseInt(1))].map(function () {
                                      return <img src={rating == 1 ? rateimg : rateimg1} className='rating-image ' />;
                                    })}
                                </div>
                                <div className='rating-comment px-2' onClick={() => setRating(2)}>
                                    
                                    {[...Array(parseInt(2))].map(function () {
                                      return <img src={rating == 2 ? rateimg : rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />;
                                    })}
                                </div>
                                <div className='rating-comment px-2' onClick={() => setRating(3)}>
                                {[...Array(parseInt(3))].map(function () {
                                      return <img src={rating == 3 ? rateimg : rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />;
                                    })}
                                </div>
                                <div className='rating-comment px-2' onClick={() => setRating(4)}>
                                {[...Array(parseInt(4))].map(function () {
                                      return <img src={rating == 4 ? rateimg : rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />;
                                    })}
                                </div>
                                <div className='rating-comment1 px-2' onClick={() => setRating(5)}>
                                {[...Array(parseInt(5))].map(function () {
                                      return <img src={rating == 5 ? rateimg : rateimg1} className='rating-image me-lg-1 me-md-1 me-sm-1 me-0' />;
                                    })}
                                </div>
                            </div>


                            <form className="row mt-3">
                                <div className='heading-comment'>
                                    <p>Your review*</p>
                                </div>
                                <div className="mb-3">
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                    onChange={(e) => {
                                        setMessage(e.target.value);
                                        setMessageErr("");
                                    }}
                                    placeholder="Your Message"
                                    ></textarea>

                                </div>
                                <span className='text text-danger'>{messageErr}</span>
                                <div className="mb-3">
                                    <input type="text" className="form-control from-input" onChange={(e) => {
                                        setName(e.target.value);
                                        setNameErr("");
                                    }} placeholder="Name*" />
                                </div>
                                <span className='text text-danger'>{nameErr}</span>
                                {/* <div className="mb-3">
                                    <input type="email" className="form-control from-input" placeholder="Email*" />
                                </div>
                                <div className="form-check mb-3 ms-2">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label className="form-check-label ms-3" for="flexCheckDefault">
                                        Save my name, email, and website in this browser for the next time I comment.
                                    </label>
                                </div> */}
                                <div className="col-12 ">
                                    <button type="button" className="btn btn-reply" onClick={handleSubmit}>Submit</button>
                                    {/* data-bs-toggle="modal" data-bs-target="#submitModal1" */}
                                </div>
                            </form>
                            </>
                            )}
                        </div>
                    </div>
                </div>
            </section>


             {/* <!-- Modal --> */}
             <div className='procced-modal'>
                <div className={showModal ? "modal fade show" : "modal fade"}
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                 id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            {/* <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div> */}
                            <div className="modal-body">
                                <CheckLottey />
                                <div className='successfully text-center'>
                                    <button className='btn btn-success1'>Review Posted Successfully</button>
                                </div>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AStrologerProfile