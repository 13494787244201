// import axios from "axios";
import axiosClient from "./ApiInstance.js";
import formDataClient from "./formInstance.js";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postFormData = async (url, data) => {
  try {
    const response = await formDataClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};



export const getEditData = async (url) => {
  try {
    const response = await axiosClient.get(process.env.REACT_APP_BASE_URL + url);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const editData = async (url, data) => {
  try {
    const response = await axiosClient.post(
      process.env.REACT_APP_BASE_URL + url,
      data
    );

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const editStatusData = async (url) => {
  try {
    const response = await axiosClient.get(process.env.REACT_APP_BASE_URL + url);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosClient.get(process.env.REACT_APP_BASE_URL + url);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
