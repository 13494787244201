import React, { useEffect, useRef, useState } from 'react'
import videoimg from "../Video/login_video.mp4"
import successgif from "../../home-page/image/success.gif";
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import astroVideo from "../Video/astroVideo.mp4"
import { useContext } from "react";
import { Context } from "../../../utils/context";

const ForgetPassword = () => {
    const vidRef = useRef();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);


    const { postData } = useContext(Context);
    const [emailErr, setEmailErr] = useState("");

    const handleSubmit = async () => {
        var email = document.getElementById('email').value;

        // Initialize an array to store error messages
        var errors = [];

        

        if (email.trim() === "") {
            setEmailErr('Email is required');
            errors.push('Email is required');
        }
        else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailErr('Email is not valid');
            errors.push('Email is not valid');
        }




        if (errors.length === 0) {
            console.log(email + " ", 'forms');
            const randOtp = Math.floor(1000 + Math.random() * 9000).toString();
             console.log(randOtp,'random');
            const res = await postData(`/webforgot?email=${email}&otp=${randOtp}`);
            console.log(res,'ForgotOTP');
            if (res?.success) {

                sessionStorage.setItem('astroOTP',randOtp);
                sessionStorage.setItem('astroEmail',email);

                // Open the modal
                setShowModal(true);

                // Delay navigation by 2 seconds (2000 milliseconds)
                setTimeout(() => {
                    navigate('/otp');
                }, 2000);
                

            }else{
                console.log(res.response.data.message.email, 'fail');
                if(res.response.data.message.email){
                    setEmailErr("Invalid Email...!");
                }
                
            }

        } 
    };



    useEffect(() => { vidRef.current.play(); }, []);
    return (
        <>
            <section className='Login'>
                <div className='container-fluid'>
                    <div className=" banner-slider ">
                        <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                        <video src={astroVideo} ref={vidRef} muted loop className=" video-class1" />
                        <div className="main_login_section ">
                            <div className="login-page ">

                                <div className="login-container p-4">
                                    <h2 className="text-center mb-4 Login_heading">Forgot your password?</h2>
                                    <p className='heading_info'>Enter your email below to receive your password <br /> reset instructions</p>
                                    
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label email_label">Email</label>
                                            <div className="inputField">
                                                <div className="input-container">
                                                    <span className="input-icon_forget">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </span>
                                                    <input type="email" className="content_input_field form-control" id="email" placeholder="Enter the email here"   onChange={() => (setEmailErr(""))}/>
                                                </div>
                                            </div>
                                            <span className='text text-danger'>{emailErr && (emailErr)}</span>
                                        </div>

                                        <div className='text-center mb-3'>
                                            

                                                <button type="submit" className="btn btn-primary login_btn text-center mt-2" onClick={handleSubmit}>Send</button>
                                            

                                        </div>
                                        <div className='text-center'>
                                            <Link to={"/login"} className='link'>
                                                <p className='backToLogin_txt'> <FontAwesomeIcon icon={faArrowLeft} className='me-2' /> Back to Login Page</p>

                                            </Link>
                                        </div>
                                    
                                </div>

                            </div>

                            <div className='row below_common_section mt-4'>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>100% </p>
                                        <p className='text-info'>Privacy</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>10000+  </p>
                                        <p className='text-info'>Tap astrologers of India</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'> 
                                    <div className=''>
                                        <p className='data_txt'>3Cr+ </p>
                                        <p className='text-info'>Happy Customers</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* **********<!--call Modal start--> ************/}
            <section className="modal-chat">
                <div 
                className= {showModal ? "modal fade show" : "modal fade"}
                id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <img src={successgif} />
                                </div>
                                <div className="text-holder text-center">
                                    <h5>OTP Sent Successfully...!</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}
        </>
    )
}

export default ForgetPassword