import React, {useEffect, useState} from "react";
import Card_1_img from "../Images/Card_1_img.png";
import Card_2_img from "../Images/Card_2_img.png";
import Card_3_img from "../Images/Card_3_img.png";
import Card_4_img from "../Images/Card_4_img.png";
import arrowimg from "../../home-page/image/forward.png";
import "./HoroscopeSlider.css"
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

import { useContext } from "react";
import { Context } from "../../../utils/context";
import { Rings } from 'react-loader-spinner';
const HoroscopeSlider = () => {


  
  const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl, ReactLoader, setReactLoader} = useContext(Context);

  const [dailyHoroscope, setDailyHoroscope] = useState();

  const [ring, setRing] = useState(true);
  const getDailyHoroscope = async () => {
    const res = await getData(`/dailyHoroscope`);
    if(res?.success){
      console.log(res.data, 'daily');
      setDailyHoroscope(res.data.daily);
      setRing(false);
    }
  }


  useEffect(() => {
    getDailyHoroscope();
}, []);
  return (
    <>
     {ring ? (
      <Rings color="#FF7E00" height={80} width={80} />
    ) : (
      <>
      <section className="HoroscopeSlider desktop-view-horoscope-slider">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 col-lg-11 col-md-12 mx-auto ">
              <div className="row">
                <div className='col-md-12'>
                  <Swiper
                    modules={[Navigation, Autoplay]}
                    pagination={false}
                    navigation={true}
                    breakpoints={{

                      768: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                      },
                      1024: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                      1200: {
                        slidesPerView: 3,
                        spaceBetween: 50,
                      },
                      1400: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                      },
                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                    // autoplay={{
                    //   delay: 2500,
                    //   disableOnInteraction: false,
                    //   loop: true,
                    //   pauseOnMouseEnter: true,
                    // }}
                    className="suportres-slider" >
                      {dailyHoroscope?.map((value,index) => (
                    <SwiperSlide>
                      <Link to={`/aries_horoscope/${index}`}>
                        <div className="card">
                          <img
                            src={Card_1_img}
                            className="card-img-top Card_1_img mx-auto"
                            alt="Card_1_img"
                          />
                          <div className="card-body">
                            <div className="card-title text-center">
                            {value?.sun_sign.toUpperCase()} TODAY'S HOROSCOPE
                            </div>
                            <div className="border-line mb-2"></div>
                            <p className="card-text">
                              {value?.prediction?.personal_life.slice(0, 55)}
                            </p>
                            <div className="text-center read_more_txt mb-2">
                              Read More &nbsp;
                              <img src={arrowimg} className="arrow_img" alt="" />
                            </div>
                          </div>
                        </div>

                      </Link>
                    </SwiperSlide>
                    ))}

                    {/* <SwiperSlide>
                      <Link to={"/tarus_horoscope"}>
                        <div className="card">
                          <img
                            src={Card_2_img}
                            className="card-img-top Card_2_img mx-auto"
                            alt="Card_2_img"
                          />
                          <div className="card-body">
                            <div className="card-title ">
                              TAURUS TODAY'S HOROSCOPE
                            </div>
                            <div className="border-line mb-2"></div>
                            <p className="card-text">
                              Consectetur adipiscing elit sed do eiusmod tempor
                              incididunt.
                            </p>
                            <div className="text-center read_more_txt mb-2">
                              Read More &nbsp;
                              <img src={arrowimg} className="arrow_img" alt="" />
                            </div>
                          </div>
                        </div>

                      </Link>
                    </SwiperSlide> */}

                    {/* <SwiperSlide>
                      <Link to={'/gemini_horoscope'}>
                        <div className="card">
                          <img
                            src={Card_3_img}
                            className="card-img-top Card_3_img mx-auto"
                            alt="Card_3_img"
                          />
                          <div className="card-body">
                            <div className="card-title ">
                              GEMINI TODAY'S HOROSCOPE
                            </div>
                            <div className="border-line mb-2"></div>
                            <p className="card-text">
                              Consectetur adipiscing elit sed do eiusmod tempor
                              incididunt.
                            </p>
                            <div className="text-center read_more_txt mb-2">
                              Read More &nbsp;
                              <img src={arrowimg} className="arrow_img" alt="" />
                            </div>
                          </div>
                        </div>

                      </Link>
                    </SwiperSlide> */}

                    {/* <SwiperSlide>
                      <Link to={"/cancer_horoscope"}>
                        <div className="card">
                          <img
                            src={Card_4_img}
                            className="card-img-top Card_4_img mx-auto"
                            alt="..."
                          />
                          <div className="card-body">
                            <div className="card-title">
                              CANCER TODAY'S HOROSCOPE
                            </div>
                            <div className="border-line mb-2"></div>
                            <p className="card-text ">
                              Consectetur adipiscing elit sed do eiusmod tempor
                              incididunt.
                            </p>
                            <div className="text-center read_more_txt mb-2">
                              Read More &nbsp;
                              <img src={arrowimg} className="arrow_img" alt="" />
                            </div>
                          </div>
                        </div>

                      </Link>
                    </SwiperSlide> */}

                    {/* <SwiperSlide>
                      <div className="card">
                        <img
                          src={Card_4_img}
                          className="card-img-top Card_4_img mx-auto"
                          alt="Card_4_img"
                        />
                        <div className="card-body">
                          <div className="card-title">
                            CANCER TODAY'S HOROSCOPE
                          </div>
                          <div className="border-line mb-2"></div>
                          <p className="card-text ">
                            Consectetur adipiscing elit sed do eiusmod tempor
                            incididunt.
                          </p>
                          <div className="text-center read_more_txt mb-2">
                            Read More &nbsp;
                            <img src={arrowimg} className="arrow_img" alt="" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}

                    {/* <SwiperSlide>
                      <div className="card">
                        <img
                          src={Card_4_img}
                          className="card-img-top Card_4_img mx-auto"
                          alt="..."
                        />
                        <div className="card-body">
                          <div className="card-title">
                            CANCER TODAY'S HOROSCOPE
                          </div>
                          <div className="border-line mb-2"></div>
                          <p className="card-text">
                            Consectetur adipiscing elit sed do eiusmod tempor
                            incididunt.
                          </p>
                          <div className="text-center read_more_txt mb-2">
                            Read More &nbsp;
                            <img src={arrowimg} className="arrow_img" alt="" />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                  </Swiper>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    

      <section className="HoroscopeSlider mobile-view-horoscope-slider">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-12'>
              <Swiper
                modules={[Navigation, Autoplay]}
                pagination={false}
                navigation={true}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  567: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                  },

                }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                // autoplay={{
                //   delay: 2500,
                //   disableOnInteraction: false,
                //   loop: true,
                //   pauseOnMouseEnter: true,
                // }}
                className="suportres-slider" >
                <SwiperSlide>
                  <Link to={"/aries_horoscope"}>
                    <div className="card mb-4">
                      <img
                        src={Card_1_img}
                        className="card-img-top Card_1_img mx-auto"
                        alt="Card_1_img"
                      />
                      <div className="card-body">
                        <div className="card-title text-center">
                          Aries Today's <br /> Horoscope
                        </div>
                        <div className="border-line my-2"></div>
                        <p className="card-text text-center">
                          Consectetur adipiscing elit sed do eiusmod tempor
                          incididunt.
                        </p>
                        <div className="text-center read_more_txt">
                          Read More &nbsp;
                          <img src={arrowimg} className="arrow_img" alt="" />
                        </div>
                      </div>
                    </div>

                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link to={"/tarus_horoscope"}>
                    <div className="card mb-4">
                      <img
                        src={Card_2_img}
                        className="card-img-top Card_2_img mx-auto"
                        alt="Card_2_img"
                      />
                      <div className="card-body">
                        <div className="card-title text-center">

                          Taurus Today's <br />Horoscope
                        </div>
                        <div className="border-line my-2"></div>
                        <p className="card-text text-center">
                          Consectetur adipiscing elit sed do eiusmod tempor
                          incididunt.
                        </p>
                        <div className="text-center read_more_txt">
                          Read More &nbsp;
                          <img src={arrowimg} className="arrow_img" alt="" />
                        </div>
                      </div>
                    </div>

                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link to={'/gemini_horoscope'}>
                    <div className="card mb-4">
                      <img
                        src={Card_3_img}
                        className="card-img-top Card_3_img mx-auto"
                        alt="Card_3_img"
                      />
                      <div className="card-body">
                        <div className="card-title text-center">
                          Gemini Today's <br />Horoscope
                        </div>
                        <div className="border-line my-2"></div>
                        <p className="card-text text-center">
                          Consectetur adipiscing elit sed do eiusmod tempor
                          incididunt.
                        </p>
                        <div className="text-center read_more_txt">
                          Read More &nbsp;
                          <img src={arrowimg} className="arrow_img" alt="" />
                        </div>
                      </div>
                    </div>

                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <Link to={"/cancer_horoscope"}>
                    <div className="card mb-4">
                      <img
                        src={Card_4_img}
                        className="card-img-top Card_4_img mx-auto"
                        alt="..."
                      />
                      <div className="card-body">
                        <div className="card-title text-center">
                          Cancer Today's <br />Horoscope
                        </div>
                        <div className="border-line my-2"></div>
                        <p className="card-text text-center">
                          Consectetur adipiscing elit sed do eiusmod tempor
                          incididunt.
                        </p>
                        <div className="text-center read_more_txt">
                          Read More &nbsp;
                          <img src={arrowimg} className="arrow_img" alt="" />
                        </div>
                      </div>
                    </div>

                  </Link>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="card mb-4">
                    <img
                      src={Card_4_img}
                      className="card-img-top Card_4_img mx-auto"
                      alt="Card_4_img"
                    />
                    <div className="card-body">
                      <div className="card-title text-center">
                        Cancer Today's <br />Horoscope
                      </div>
                      <div className="border-line my-2"></div>
                      <p className="card-text text-center">
                        Consectetur adipiscing elit sed do eiusmod tempor
                        incididunt.
                      </p>
                      <div className="text-center read_more_txt">
                        Read More &nbsp;
                        <img src={arrowimg} className="arrow_img" alt="" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="card mb-4">
                    <img
                      src={Card_4_img}
                      className="card-img-top Card_4_img mx-auto"
                      alt="..."
                    />
                    <div className="card-body">
                      <div className="card-title text-center">
                        Cancer Today's <br />Horoscope
                      </div>
                      <div className="border-line my-2"></div>
                      <p className="card-text text-center">
                        Consectetur adipiscing elit sed do eiusmod tempor
                        incididunt.
                      </p>
                      <div className="text-center read_more_txt">
                        Read More &nbsp;
                        <img src={arrowimg} className="arrow_img" alt="" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      </>
      )}
    </>
  );
};

export default HoroscopeSlider;
