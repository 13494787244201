import React from 'react'
import { Link } from 'react-router-dom'
import "./Dasha.css";
import nashkimg from '../Free_Report/Images/image-1.png'
import nashkimg1 from '../Free_Report/Images/image-2.png'
import ConnectAstrologer from '../../Kundali/connect-astrologer/ConnectAstrologer';
// import "../../free-kundali/Free_Report/Free_Report.css"
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-tabs/style/react-tabs.css';
const Dasha = () => {
  return (
    <>
      <section className='Free_Kundli desktop-view-free-kundali'>
        <div className='container'>
          <div className="row ">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"> <Link to="/">Home</Link> </li>
                <li className="breadcrumb-item"><Link to="/freekundali">Free Kundali </Link></li>
                <li className="breadcrumb-item active"><Link to="/dasha">Dasha</Link> </li>
              </ol>
            </nav>
            <div className="col-lg-12   mt-4">
              <ul className="nav nav-pills mb-3 " id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link to='/Basic-Kundali'><button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                    type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to='/second-Kundali'> <button className="nav-link " id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                    role="tab" aria-controls="pills-kundli" aria-selected="false">
                    Kundli
                  </button></Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to='/kp-Kundali'> <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                    type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                    KP
                  </button></Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/free-ashtkvarga">
                    <button className="nav-link " id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                      type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                      Ashtakvarga
                    </button></Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/free-chart">
                    <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                      type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                      Charts
                    </button></Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link to="/free-dasha">
                    <button className="nav-link active" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                      type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                      Dasha
                    </button></Link>
                </li>
                <li className="nav-item free-nav" role="presentation">
                  <Link to={"/free_Report"}>
                    <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                      type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                      Free Report
                    </button> </Link>
                </li>
              </ul>
            </div>
          </div>


          <div className='FreeReport'>
            <ul className="nav Dasha_navtab nav-pills tab-list justify-content-center" id="pills-tab" role="tablist" >
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="pills-Vimshottari-tab" data-bs-toggle="pill" data-bs-target="#pills-Vimshottari" type="button"
                  role="tab" aria-controls="pills-Vimshottari" aria-selected="true" > Vimshottari</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="pills-Yogini-tab" data-bs-toggle="pill" data-bs-target="#pills-Yogini" type="button"
                  role="tab" aria-controls="pills-Yogini" aria-selected="false"> Yogini</button>
              </li>
            </ul>

            <div className="main-heading">
              <div className="line me-2"></div>
              <div className="square"></div>
              <div className="line ms-2"></div>
            </div>

            <div className="tab-content mt-4" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-Vimshottari" role="tabpanel" aria-labelledby="pills-Vimshottari-tab" tabindex="0">

                <div className="General_sidepane align-items-start">
                  <div className="row">
                    <div className="col-xl-3 col-lg-4">

                      <div className="nav m-2 pe-0 sideBoxShadow flex-column nav-pills me-3" id="v-pills-tab"
                        role="tablist" aria-orientation="vertical">
                        <div className='scroller'>
                          <button className="nav-link active mb-3" id="v-pills-Ketu-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Ketu"
                            type="button" role="tab" aria-controls="v-pills-Ketu" aria-selected="true" >
                            Ketu Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>

                          <button className="nav-link mb-3" id="v-pills-Venus-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Venus" type="button"
                            role="tab" aria-controls="v-pills-Venus" aria-selected="false">
                            Venus Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>

                          <button className="nav-link mb-3" id="v-pills-Sun-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Sun"
                            type="button" role="tab" aria-controls="v-pills-Sun" aria-selected="false" >
                            Sun Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>

                          <button className="nav-link mb-3" id="v-pills-Moon-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Moon"
                            type="button" role="tab" aria-controls="v-pills-Moon" aria-selected="false" >
                            Moon Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>

                          <button className="nav-link mb-3" id="v-pills-Mars-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Mars"
                            type="button" role="tab" aria-controls="v-pills-Mars" aria-selected="false" >
                            Mars Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>

                          <button className="nav-link mb-3" id="v-pills-Rahu-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Rahu"
                            type="button" role="tab" aria-controls="v-pills-Rahu" aria-selected="false" >
                            Rahu Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>

                          <button className="nav-link mb-3" id="v-pills-Rahu-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Rahu"
                            type="button" role="tab" aria-controls="v-pills-Rahu" aria-selected="false" >
                            Jupiter Mahadasha
                            <p>(14-11-1996 - 15-11-2003)</p>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-9 col-lg-8 ">
                      <div className="tab-content" id="v-pills-tabContent">

                        <div className="tab-pane  fade show active" id="v-pills-Ketu" role="tabpanel" aria-labelledby="v-pills-Ketu-tab" tabindex="0">
                          <div className=" ps-4">
                            <h6 className="mb-2 mt-xl-0 mt-lg-0 mt-md-4">Ketu Mahadasha</h6>
                            <p className="mb-2 paragraph-holder">If Ketu is camping in the third house during the birth of the native,
                              then he or she will be blessed with extremely fortuitous circumstances. They will successfully conquer their enemies and succeed in undertakings.
                              Their income will also receive a considerable boost. However, there are a few downsides to Ketu’s presence in the first house.
                              These could present themselves in the native’s life in the form of disputes and disagreements with the siblings. Owing to his troubles,
                              he or she might rely on occult sciences and/or philosophy.</p>

                            <p className='paragraph-holder'>The planet Ketu is in the Pisces sign of the Kundli. This placement bodes well to the native to some degree.
                              The placement of Ketu in Pisces indicates that you will be bestowed with honour and happiness during this Dasha period.
                              You will influence properties such as land and vehicles. You will also have a grip over various attendants. However,
                              these are liable to be taken away towards the end of the Dasha period if you are not careful or acquire greed.
                              You will have a child, probably a boy, during this period if you are married. The Dasha period, however, wants you to be thankful for all that you garner.
                              Doing charity during this period will be further helpful for you.</p>
                          </div>
                        </div>

                        <div className="tab-pane fade" id="v-pills-Venus" role="tabpanel" aria-labelledby="v-pills-Venus-tab" tabindex="0" >
                          <div className=" ps-4">
                            <h6 className="mb-2 mt-xl-0 mt-lg-0 mt-md-4">Venus Mahadasha</h6>
                            <p className="mb-2 paragraph-holder">
                              The planet Venus is in the fourth house of the Kundli. During this Dasha period, you would acquire a big house and have arid vehicles.
                              With having increased position and fame, you can also anticipate auspicious events from the maternal family’s side.
                              Moreover, as the Dasha time passes, there will be a success in educational pursuits, and domestic happiness will be there.
                              Plus, there would be an increase in cultivation and crops. Also, with Venus in the fourth house in the Dasha period,
                              you will attain reputation by your exertions and determination and have prosperous relations, family and friends.
                            </p>

                            <p className='paragraph-holder'>
                              The time during which the planet Venus is camping with Aries will witness a kaleidoscope of events,
                              some of which might be positive while the others not so much. He or she might embark on several journeys,
                              mostly by land. These journeys won't be too long. The life of the native could be very nomadic for a time, with many simple features.
                              However, the occupation of the person will not be steady as he or she changes their place of employment often.
                              The time in which Venus is placed with Aries is a time when the person under this influence will lack purpose or a concentrated goal. </p>
                          </div>
                        </div>

                        <div className="tab-pane fade " id="v-pills-Sun" role="tabpanel" aria-labelledby="v-pills-Sun-tab" tabindex="0" >
                          <div className="ps-4">
                            <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Sun Mahadasha</h6>
                            <p className='paragraph-holder'>
                              The planet Sun is in the fourth house of the Kundli. During this Dasha period, you might go through some mental troubles and may possess some rakish tendencies.
                              With that, chances of having ailments like blood pressure, chest pain, etc., may surround you if you are not careful. As the Dasha proceeds,
                              you might see yourself getting into disagreements with your family. However, if you sit down and think about the advice they have to share or the suggestions they have to give,
                              you would be able to find practicality in them. Your spouse will support you immensely during this period, and you two will come even more closer.
                            </p>
                            <br />
                            <p className='paragraph-holder'>The planet Sun is camping with the Aries sign in the Kundli. This placement is to expose the hidden piousness in you. During this period,
                              you will feel the urge to take spiritual travels and will turn towards religion and its virtues. The placement of Sun in Aries is about financial and
                              materialistic gains from the family in the form of property, jewellery, land, etc. Moreover, the Sun in Aries will bless you with a fulfilling family.
                              You will be able to create a loyal relationship with your spouse and be happy with your children during this time.
                            </p>
                          </div>
                        </div>

                        <div className="tab-pane fade " id="v-pills-Moon" role="tabpanel" aria-labelledby="v-pills-Moon-tab" tabindex="0">
                          <div className=" ps-4">
                            <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Mars Mahadasha</h6>
                            <p className='paragraph-holder'>
                              The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                              You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain
                              amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period,
                              you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                            </p>
                            <br />
                            <p className='paragraph-holder' >The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children.
                              This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests.
                              The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort.
                              You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                          </div>
                        </div>

                        <div className="tab-pane fade " id="v-pills-Mars" role="tabpanel" aria-labelledby="v-pills-Mars-tab" tabindex="0" >
                          <div className=" ps-4">
                            <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Mars Mahadasha</h6>
                            <p className='paragraph-holder'>
                              The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                              You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period, you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                            </p>

                            <p className='paragraph-holder'>The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children.
                              This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests. The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort. You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                          </div>
                        </div>

                        <div className="tab-pane fade " id="v-pills-Rahu" role="tabpanel" aria-labelledby="v-pills-Rahu-tab" tabindex="0" >
                          <div className="ps-4">
                            <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Mars Mahadasha</h6>
                            <p className='paragraph-holder'>
                              The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                              You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period, you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                            </p>

                            <p className='paragraph-holder'>The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children. This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests. The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort. You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="tab-pane fade" id="pills-Yogini" role="tabpanel" aria-labelledby="pills-Yogini-tab" tabindex="0">
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className="basicDetails-txt text-center mb-2">
                      Ulka
                    </div>
                    <div className="table-responsive">
                      <table className="table table-bordered  ruling-table">
                        <thead>
                          <tr className="table-heading">
                            <th>Planets</th>
                            <th>Start Date</th>
                            <th>End Date</th>

                          </tr>
                        </thead>
                        <tbody>

                          <tr className="table-warning1">
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>
                          </tr>
                          <tr>
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>
                          </tr>
                          <tr className="table-warning1">
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>
                          </tr>
                          <tr>
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>
                          </tr>
                          <tr className="table-warning1">
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>
                          </tr>
                          <tr >
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>

                          </tr>
                          <tr className="table-warning1">
                            <td >Ulk</td>
                            <td>Birth</td>
                            <td>8-dec-2002</td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="basicDetails-txt text-center mb-2">Rashi Chart</div>
                    <div class="table-responsive ">
                      <table className='mx-auto'>
                        <div className="main_Kundli">
                          <div className="sub"></div>
                          <div className="line1"></div>
                          <div className="line2"></div>
                          <p className="p1">AA <br />01</p>
                          <p className="p2">AA<br />02</p>
                          <p className="p3">AA<br />03</p>
                          <p className="p4">AA<br />04</p>
                          <p className="p5">AA<br />05</p>
                          <p className="p6">AA<br />06</p>
                          <p className="p7">AA<br />07</p>
                          <p className="p8">AA<br />08</p>
                          <p className="p9">AA<br />09</p>
                          <p className="p10">AA<br />10</p>
                          <p className="p11">AA<br />11</p>
                          <p className="p12">AA<br />12</p>
                        </div>
                      </table>
                    </div>
                  </div>
                  <div className='col-md-11 '>
                    <div className='note-holder mt-5'>
                      <p>Note: MAN: Mangala, PIN: Pingala, DHA: Dhanya, BHR: Bhramari, BHA: Bhadrika, ULK: Ulka, SID: Siddha, SAN: Sankata</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <ConnectAstrologer />
          </div>

          <div className="image-naskhtra">
            <img src={nashkimg} className="nakshtra-img" />
          </div>
          <div className="image-naskhtra2">
            <img src={nashkimg1} className="nakshtra-img" />
          </div>

        </div>
      </section>

      {/* mobile-view-start */}
      <section className='Free_Kundli mobile-view-free-kundali'>
        <div className='container-fluid'>
          <div className="row ">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb my-3">
                <li className="breadcrumb-item"> <Link to="/">Home {'>'} </Link> </li>
                <li className="breadcrumb-item"><Link to="/freekundali">Free Kundali {'>'} </Link></li>
                <li className="breadcrumb-item active"><Link to="/dasha">Dasha</Link> </li>
              </ol>
            </nav>
            <div className="col-lg-12 p-0">
              <ul className="nav nav-pills  " id="pills-tab" role="tablist">
                <Swiper
                  modules={[Navigation, Autoplay]}
                  pagination={false}
                  navigation={false}
                  // loop={true}
                  breakpoints={{
                    0: {
                      slidesPerView: 3,
                      // spaceBetween: 10,
                    },
                    360: {
                      slidesPerView: 3,
                      // spaceBetween: 10,
                    },
                    567: {
                      slidesPerView: 4,
                      // spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 4,
                      // spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 4,
                      // spaceBetween: 10,
                    },

                  }}

                  className="suportres-slider" >
                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item " role="presentation">
                      <Link to='/Basic-Kundali'> <button className="nav-link " id="pills-basic-tab" data-bs-toggle="pill" data-bs-target="#pills-basic"
                        type="button" role="tab" aria-controls="pills-basic" aria-selected="true"> Basic </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to='/second-Kundali'>   <button className="nav-link " id="pills-kundli-tab" data-bs-toggle="pill" data-bs-target="#pills-kundli" type="button"
                        role="tab" aria-controls="pills-kundli" aria-selected="false">
                        Kundli
                      </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to='/kp-Kundali'>   <button className="nav-link " id="pills-kp-tab" data-bs-toggle="pill" data-bs-target="#pills-kp"
                        type="button" role="tab" aria-controls="pills-kp" aria-selected="false">
                        KP
                      </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to="/free-ashtkvarga">
                        <button className="nav-link" id="pills-ashtakvarga-tab" data-bs-toggle="pill" data-bs-target="#pills-ashtakvarga"
                          type="button" role="tab" aria-controls="pills-ashtakvarga" aria-selected="false">
                          Ashtakvarga
                        </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to="/free-chart">
                        <button className="nav-link" id="pills-charts-tab" data-bs-toggle="pill" data-bs-target="#pills-charts"
                          type="button" role="tab" aria-controls="pills-charts" aria-selected="false">
                          Charts
                        </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item" role="presentation">
                      <Link to="/free-dasha">
                        <button className="nav-link active" id="pills-dasha-tab" data-bs-toggle="pill" data-bs-target="#pills-dasha"
                          type="button" role="tab" aria-controls="pills-dasha" aria-selected="false">
                          Dasha
                        </button></Link>
                    </li>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide1">
                    <li className="nav-item free-nav" role="presentation">
                      <Link to={'/free_Report'}>
                        <button className="nav-link" id="pills-report-tab" data-bs-toggle="pill" data-bs-target="#pills-report"
                          type="button" role="tab" aria-controls="pills-report" aria-selected="false">
                          Free Report
                        </button>
                      </Link>
                    </li>
                  </SwiperSlide>
                </Swiper>
              </ul>
            </div>
          </div>


          <div className='FreeReport my-4'>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Nav variant="pills" className="nav Dasha_navtab nav-pills tab-list justify-content-center mb-2" role="tablist">
                <Nav.Item className="nav-item" role="presentation">
                  <Nav.Link eventKey="first" className="nav-link "> Vimshottari</Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item ">
                  <Nav.Link eventKey="second" className="nav-link">Yogini</Nav.Link>
                </Nav.Item>
              </Nav>

              <div className="main-heading mt-1">
                <div className="line me-1"></div>
                <div className="square"></div>
                <div className="line ms-1"></div>
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="General_sidepane">
                    <div className="row">
                      <div className="col-12">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                          <Nav variant="pills" className="nav sideBoxShadow flex-column nav-pills">
                            <Swiper
                              modules={[Navigation, Autoplay]}
                              pagination={false}
                              navigation={false}
                              loop={true}
                              breakpoints={{
                                0: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                420: {
                                  slidesPerView: 3,
                                  spaceBetween: 10,
                                },
                                567: {
                                  slidesPerView: 3,
                                  spaceBetween: 10,
                                },
                                640: {
                                  slidesPerView: 3,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 4,
                                  spaceBetween: 10,
                                },

                              }}

                              className="" >
                              <SwiperSlide className='day-swiper1'>
                                <Nav.Item >
                                  <Nav.Link eventKey="first" className="nav-link ">
                                    Ketu Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>

                              <SwiperSlide className='day-swiper1'>
                                <Nav.Item >
                                  <Nav.Link eventKey="second" className="nav-link">
                                    Venus Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>

                              <SwiperSlide className='day-swiper1'>
                                <Nav.Item >
                                  <Nav.Link eventKey="third" className="nav-link">
                                    Sun Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>

                              <SwiperSlide className='day-swiper1'>
                                <Nav.Item >
                                  <Nav.Link eventKey="four" className="nav-link">
                                    Moon Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>

                              <SwiperSlide className='day-swiper1'>
                                <Nav.Item >
                                  <Nav.Link eventKey="fifth" className="nav-link">
                                    Mars Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>

                              <SwiperSlide className='day-swiper1'>
                                <Nav.Item >
                                  <Nav.Link eventKey="six" className="nav-link">
                                    Rahu Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>

                              <SwiperSlide className='day-swiper1'>

                                <Nav.Item >
                                  <Nav.Link eventKey="seven" className="nav-link">
                                    Jupiter Mahadasha
                                    <p>(14-11-1996 - 15-11-2003)</p>
                                  </Nav.Link>
                                </Nav.Item>
                              </SwiperSlide>
                            </Swiper>
                          </Nav>


                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-2 mt-xl-0 mt-lg-0 mt-md-4">Ketu Mahadasha</h6>
                                <p className="mb-2 paragraph-holder">If Ketu is camping in the third house during the birth of the native,
                                  then he or she will be blessed with extremely fortuitous circumstances. They will successfully conquer their enemies and succeed in undertakings.
                                  Their income will also receive a considerable boost. However, there are a few downsides to Ketu’s presence in the first house.
                                  These could present themselves in the native’s life in the form of disputes and disagreements with the siblings. Owing to his troubles,
                                  he or she might rely on occult sciences and/or philosophy.</p>

                                <p className='paragraph-holder'>The planet Ketu is in the Pisces sign of the Kundli. This placement bodes well to the native to some degree.
                                  The placement of Ketu in Pisces indicates that you will be bestowed with honour and happiness during this Dasha period.
                                  You will influence properties such as land and vehicles. You will also have a grip over various attendants. However,
                                  these are liable to be taken away towards the end of the Dasha period if you are not careful or acquire greed.
                                  You will have a child, probably a boy, during this period if you are married. The Dasha period, however, wants you to be thankful for all that you garner.
                                  Doing charity during this period will be further helpful for you.</p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-2 mt-xl-0 mt-lg-0 mt-md-4">Venus Mahadasha</h6>
                                <p className="mb-2 paragraph-holder">
                                  The planet Venus is in the fourth house of the Kundli. During this Dasha period, you would acquire a big house and have arid vehicles.
                                  With having increased position and fame, you can also anticipate auspicious events from the maternal family’s side.
                                  Moreover, as the Dasha time passes, there will be a success in educational pursuits, and domestic happiness will be there.
                                  Plus, there would be an increase in cultivation and crops. Also, with Venus in the fourth house in the Dasha period,
                                  you will attain reputation by your exertions and determination and have prosperous relations, family and friends.
                                </p>

                                <p className='paragraph-holder'>
                                  The time during which the planet Venus is camping with Aries will witness a kaleidoscope of events,
                                  some of which might be positive while the others not so much. He or she might embark on several journeys,
                                  mostly by land. These journeys won't be too long. The life of the native could be very nomadic for a time, with many simple features.
                                  However, the occupation of the person will not be steady as he or she changes their place of employment often.
                                  The time in which Venus is placed with Aries is a time when the person under this influence will lack purpose or a concentrated goal. </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Sun Mahadasha</h6>
                                <p className='paragraph-holder'>
                                  The planet Sun is in the fourth house of the Kundli. During this Dasha period, you might go through some mental troubles and may possess some rakish tendencies.
                                  With that, chances of having ailments like blood pressure, chest pain, etc., may surround you if you are not careful. As the Dasha proceeds,
                                  you might see yourself getting into disagreements with your family. However, if you sit down and think about the advice they have to share or the suggestions they have to give,
                                  you would be able to find practicality in them. Your spouse will support you immensely during this period, and you two will come even more closer.
                                </p>
                                <br />
                                <p className='paragraph-holder'>The planet Sun is camping with the Aries sign in the Kundli. This placement is to expose the hidden piousness in you. During this period,
                                  you will feel the urge to take spiritual travels and will turn towards religion and its virtues. The placement of Sun in Aries is about financial and
                                  materialistic gains from the family in the form of property, jewellery, land, etc. Moreover, the Sun in Aries will bless you with a fulfilling family.
                                  You will be able to create a loyal relationship with your spouse and be happy with your children during this time.
                                </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="four">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Moon Mahadasha</h6>
                                <p className='paragraph-holder'>
                                  The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                                  You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain
                                  amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period,
                                  you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                                </p>
                                <br />
                                <p className='paragraph-holder' >The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children.
                                  This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests.
                                  The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort.
                                  You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">Mars Mahadasha</h6>
                                <p className='paragraph-holder'>
                                  The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                                  You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period, you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                                </p>

                                <p className='paragraph-holder'>The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children.
                                  This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests. The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort. You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="six">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4"> Rahu Mahadasha</h6>
                                <p className='paragraph-holder'>
                                  The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                                  You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period, you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                                </p>

                                <p className='paragraph-holder'>The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children. This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests. The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort. You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="seven">
                              <div className="mt-2 ps-2">
                                <h6 className="mb-3 mt-xl-0 mt-lg-0 mt-md-4">  Jupiter Mahadasha</h6>
                                <p className='paragraph-holder'>
                                  The planet Mars is in the eighth house of the Kundli. During this Dasha period, unfavourable possibilities may be a host if you are in bad company.
                                  You may have to bear a loss of money and good health if you try to be lazy. However, as you inch closer to the end of the dasha period you will attain amplified fame and respect from seniors. Overall happiness and pleasant possibilities shall be there in family life, and with Mars in the eighth house at the end of the Dasha period, you could attain leisure and pleasures of all sorts. Plus, health has slight chances of improving too.
                                </p>

                                <p className='paragraph-holder'>The planet Mars is camping with the Leo sign in the Kundli. This placement is a period of separation from the family and children. This will be not so much a result of disputes or quarrels, rather it will be from the fact that during this period the native is busy with all sorts of other interests. The placement of the Mars in Leo suggests that you might go in for politics. At the least, you will lead or organize some community or civic organization that requires your time and effort. You will be a public figure within your community. Further, you should be careful of falls during this Dasha period failing which, the consequences will be serious. The Mars in Leo also indicates that you will be considerably more susceptible to accidents, so be careful.</p>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>


                        </Tab.Container>

                      </div>


                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className="basicDetails-txt mb-2">
                        Ulka
                      </div>
                      <div className="table-responsive">
                        <table className="table table-bordered  ruling-table">
                          <thead>
                            <tr className="table-heading">
                              <th>Planets</th>
                              <th>Start Date</th>
                              <th>End Date</th>

                            </tr>
                          </thead>
                          <tbody>

                            <tr className="table-warning1">
                              <td >Ulk</td>
                              <td>Birth</td>
                              <td>8-dec-2002</td>
                            </tr>
                            <tr>
                              <td >Ulk</td>
                              <td>Birth</td>
                              <td>8-dec-2002</td>
                            </tr>
                            <tr className="table-warning1">
                              <td >Ulk</td>
                              <td>Birth</td>
                              <td>8-dec-2002</td>
                            </tr>
                            <tr>
                              <td >Ulk</td>
                              <td>Birth</td>
                              <td>8-dec-2002</td>
                            </tr>
                            <tr className="table-warning1">
                              <td >Ulk</td>
                              <td>Birth</td>
                              <td>8-dec-2002</td>
                            </tr>
                            <tr >
                              <td >Ulk</td>
                              <td>Birth</td>
                              <td>8-dec-2002</td>

                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className="basicDetails-txt text-center mb-2">Rashi Chart</div>
                      <div class="main-kundali mx-auto">
                        <div class="sub"></div>
                        <div class="line1"></div>
                        <div class="line2"></div>

                        <p class="p1">01</p>
                        <p class="p2">02</p>
                        <p class="p3">03</p>
                        <p class="p4">04</p>
                        <p class="p5">05</p>
                        <p class="p6">06</p>
                        <p class="p7">07</p>
                        <p class="p8">08</p>
                        <p class="p9">09</p>
                        <p class="p10">10</p>
                        <p class="p11">11</p>
                        <p class="p12">12</p>
                      </div>
                    </div>
                    <div className='col-md-11 '>
                      <div className='note-holder mt-4 mb-0'>
                        <p>Note: MAN: Mangala, PIN: Pingala, DHA: Dhanya, BHR: Bhramari, BHA: Bhadrika, ULK: Ulka, SID: Siddha, SAN: Sankata</p>
                      </div>
                    </div>
                  </div>

                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>

          <div className="row">
            <ConnectAstrologer />
          </div>

          <div className="image-naskhtra">
            <img src={nashkimg} className="nakshtra-img" />
          </div>
          <div className="image-naskhtra2">
            <img src={nashkimg1} className="nakshtra-img" />
          </div>

        </div>
      </section>
    </>
  )
}

export default Dasha