import React, { useEffect, useState } from 'react'
import '../../chat-astrologer/filter-modal/FilterModal.css';
import futureimg from '../../chat-astrologer/image/future.png';
import languageimg from '../../chat-astrologer/image/language.png';
import genderimg from '../../chat-astrologer/image/toilet.png';
import countryimg from '../../chat-astrologer/image/coronavirus.png';
import offerimg from '../../chat-astrologer/image/discount.png';
import topimg from '../../chat-astrologer/image/badge.png';
import { Link, useNavigate } from "react-router-dom";



import { useContext } from "react";
import { Context } from "../../../utils/context";
const FilterModalTalk = () => {



    const navigate = useNavigate();
    const [skillAll, setSkillAll] = useState(false);
    const [languageAll, setLanguageAll] = useState(false);
    const [genderAll, setGenderAll] = useState(false);
    const [countryAll, setCountryAll] = useState(false);
    
    const { getData, filterData, setFilterData,astrologers ,setAstrologer, settalkFilterURL } = useContext(Context);
    const [checkboxes, setCheckboxes] = useState([]);
    const [content, setContent] = useState([]);
    const [country, setCountry] = useState([]);
    
    const getFilterData = async () => {
        const res = await getData("/astrologerDetails");
        if (res?.success) {
            setFilterData(res.data);
        }
        console.log(res.data, 'filterData');

        setCheckboxes(res.data?.skills?.map(skill => ({
            id: skill?.id,
            text: skill?.name,
            isChecked: false,
          })));

          setContent(res.data?.language?.map(skill => ({
            id: skill?.id,
            text: skill?.name,
            isChecked: false,
          })));

          setCountry(res.data?.country?.map(skill => ({
            id: skill?.id,
            text: skill?.name,
            isChecked: false,
          })));
 
    }; 
    

    const [gender, setGender] = useState([
        { id: "male", text: 'Male', isChecked: false },
        { id: "female", text: 'Female', isChecked: false },
    ])
    
    const [choice, setChoice] = useState([
        { id: 1, text: 'All', isChecked: false },
        { id: 2, text: 'Must Try', isChecked: false },
        { id: 3, text: 'Top Choice', isChecked: false },
    ])

    const [offer, setOffer] = useState([
        { id: 1, text: 'Active', isChecked: false },
        { id: 2, text: 'Non-Active', isChecked: false },

    ])

    const toggleCheckbox = (id) => {
        setCheckboxes((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
        setSkillAll(false); // Toggle setSkillAll
    };
    const handleCheckbox = (id) => {
        setContent((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
        setLanguageAll(false); // Toggle setSkillAll
    };
    const genderCheckbox = (id) => {
        setGender((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
        setGenderAll(false); // Toggle setSkillAll
    };
    const countryCheckbox = (id) => {
        setCountry((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
        setCountryAll(false); // Toggle setSkillAll
    };

    const choiceCheckbox = (id) => {
        setChoice((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };
    const offereCheckbox = (id) => {
        setOffer((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );
    };


    const selectAll = (checkArray) => {
        if (checkArray === "skills") {
            // Check if any checkbox is currently false
            const anyUnchecked = checkboxes.some((checkbox) => !checkbox.isChecked);
    
            setCheckboxes((prevState) =>
                prevState.map((checkbox) => ({
                    ...checkbox,
                    isChecked: anyUnchecked // Toggle all checkboxes to the opposite of anyUnchecked
                }))
            );
            setSkillAll((prevSkillAll) => !prevSkillAll); // Toggle setSkillAll
        }
    
        if (checkArray === "languages") {
           
            // Check if any checkbox is currently false
            const anyUnchecked = content.some((checkbox) => !checkbox.isChecked);
    
            setContent((prevState) =>
                prevState.map((checkbox) => ({
                    ...checkbox,
                    isChecked: anyUnchecked // Toggle all checkboxes to the opposite of anyUnchecked
                }))
            );
            setLanguageAll((prevLanguageAll) => !prevLanguageAll); // Toggle setSkillAll
        }
        if (checkArray === "genders") {
           
             // Check if any checkbox is currently false
             const anyUnchecked = gender.some((checkbox) => !checkbox.isChecked);
    
             setGender((prevState) =>
                 prevState.map((checkbox) => ({
                     ...checkbox,
                     isChecked: anyUnchecked // Toggle all checkboxes to the opposite of anyUnchecked
                 }))
             );
             setGenderAll((prevGenderAll) => !prevGenderAll); // Toggle setSkillAll
        }
        if (checkArray === "countries") {
           
            // Check if any checkbox is currently false
            const anyUnchecked = country.some((checkbox) => !checkbox.isChecked);
    
            setCountry((prevState) =>
                prevState.map((checkbox) => ({
                    ...checkbox,
                    isChecked: anyUnchecked // Toggle all checkboxes to the opposite of anyUnchecked
                }))
            );
            setCountryAll((prevCountryAll) => !prevCountryAll); // Toggle setSkillAll
        }

    };
    



    //ASTROLOGER FILTER API
    const astrolgerFilter = async () => {


        //CHECKED SKILLS IN ARRAY PLACING
        var filter_skill = [];
    
        checkboxes?.forEach((skill,index) => {
            if (skill?.isChecked) {
                
                filter_skill.push(`filter_skill[${index}]=${skill.id}`);
            }
        });

       

        //CHECKED LANGUAGES IN ARRAY PLACING
        var filter_language = [];
    
        content?.forEach((language,index) => {
            if (language?.isChecked) {
                
                filter_language.push(`filter_language[${index}]=${language.id}`);
            }
        });

        //CHECKED GENDER IN ARRAY PLACING
        var filter_gender = [];
    
        gender?.forEach((gender,index) => {
            if (gender?.isChecked) {
                
                filter_gender.push(`filter_gender[${index}]=${gender.id}`);
            }
        });


        //CHECKED COUNTRY IN ARRAY PLACING
        var filter_country = [];
    
        country?.forEach((country,index) => {
            if (country?.isChecked) {
                
                filter_country.push(`filter_country[${index}]=${country.id}`);
            }
        });


            //JOIN ALL CHECKEDBOX IN URL qUERY
        const skillQueryString = filter_skill.join('&');
        const languageQueryString = filter_language.join('&');
        const genderQueryString = filter_gender.join('&');
        const countryQueryString = filter_country.join('&');


        const url = `/astrologerDetails?${skillQueryString}&${languageQueryString}&${genderQueryString}&${countryQueryString}`;
        settalkFilterURL(url);
        console.log(url,'talkFilterURL');
        const res = await getData(url);



        if (res?.success) {
            console.log(res.data, 'astrologersfilter');
            setAstrologer(res.data.astrologer);
        }


    }

    const resetFilter = async () => {
            settalkFilterURL("/astrologerDetails");
            window.location.reload();
    }
    

    useEffect(() => {

        getFilterData();

    }, []);
    return (
        <>
            {/* <!-- Modal --> */}
            <div className='filtermodal'>
                <div className="modal fade" id="filterModaltalk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/* <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1> */}
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body pb-0">
                                <div className='row'>
                                    <div className='col-sm-6 col-6'>
                                        <div className='sort mb-3'>
                                            <h5 className='ps-4 pb-1'>Filters</h5>
                                        </div>
                                        <div className="nav flex-column nav-pills ms-sm-4 ms-0 me-sm-3 me-1" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            <button className="nav-link mb-sm-3 mb-2 active" id="v-pills-Skill-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Skill" type="button" role="tab" aria-controls="v-pills-Skill" aria-selected="true"> <div className="triangle-right"></div><img src={futureimg} className='future-img me-2' />Skill</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Language-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Language" type="button" role="tab" aria-controls="v-pills-Language" aria-selected="false"> <div className="triangle-right"></div><img src={languageimg} className='future-img me-2' />Language</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Gender-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Gender" type="button" role="tab" aria-controls="v-pills-Gender" aria-selected="false" > <div className="triangle-right"></div><img src={genderimg} className='future-img me-2' />Gender</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Country-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Country" type="button" role="tab" aria-controls="v-pills-Country" aria-selected="false"> <div className="triangle-right"></div><img src={countryimg} className='future-img me-2' />Country</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Offer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Offer" type="button" role="tab" aria-controls="v-pills-Offer" aria-selected="false"> <div className="triangle-right"></div><img src={offerimg} className='future-img me-2' />Offer</button>
                                            <button className="nav-link mb-sm-3 mb-2" id="v-pills-Top-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Top" type="button" role="tab" aria-controls="v-pills-Top" aria-selected="false"> <div className="triangle-right"></div><img src={topimg} className='future-img me-2' />Top Choice</button>
                                        </div>
                                    </div>
                                    <div className='col-sm-6 col-6'>
                                        
                                        <div className="tab-content" id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" id="v-pills-Skill" role="tabpanel" aria-labelledby="v-pills-Skill-tab" tabindex="0">
                                            <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" checked={skillAll} onClick={() => selectAll("skills")}/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => selectAll("skills")}  />
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div> */}
                                        </div>
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            Face Reading
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked1" />
                                                        <label className="form-check-label" for="flexCheckChecked1">
                                                            Kp
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                                        <label className="form-check-label" for="flexCheckChecked2">
                                                            Life Coach
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
                                                        <label className="form-check-label" for="flexCheckChecked3">
                                                            Nadi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked4" />
                                                        <label className="form-check-label" for="flexCheckChecked4">
                                                            Numerology
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked5" />
                                                        <label className="form-check-label" for="flexCheckChecked5">
                                                            Palmistry
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked6" />
                                                        <label className="form-check-label" for="flexCheckChecked6">
                                                            Prashana
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked7" />
                                                        <label className="form-check-label" for="flexCheckChecked7">
                                                            Psychic
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked8" />
                                                        <label className="form-check-label" for="flexCheckChecked8">
                                                            Tarot
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked9" />
                                                        <label className="form-check-label" for="flexCheckChecked9">
                                                            Vastu
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked10" />
                                                        <label className="form-check-label" for="flexCheckChecked10">
                                                            Vedic
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {checkboxes?.map((checkbox) => (
                                                        <div key={checkbox?.id} className="mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox?.isChecked ? 'checked' : ''}`}
                                                                onClick={() => toggleCheckbox(checkbox?.id)}>
                                                                <input type="checkbox" checked={checkbox?.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox?.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Language" role="tabpanel" aria-labelledby="v-pills-Language-tab" tabindex="0">
                                            <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1"  checked={languageAll}  onClick={() => selectAll("languages")}/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => selectAll("languages")}/>
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div> */}
                                        </div>
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked />
                                                        <label className="form-check-label" for="flexCheckDefault">
                                                            English
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked18" />
                                                        <label className="form-check-label" for="flexCheckChecked18">
                                                            Gujarati
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked19" />
                                                        <label className="form-check-label" for="flexCheckChecked19">
                                                            Hindi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked20" />
                                                        <label className="form-check-label" for="flexCheckChecked20">
                                                            Bengali
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked21" />
                                                        <label className="form-check-label" for="flexCheckChecked21">
                                                            Marathi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked22" />
                                                        <label className="form-check-label" for="flexCheckChecked22">
                                                            Kannada
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked23" />
                                                        <label className="form-check-label" for="flexCheckChecked23">
                                                            Punjabi
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked24" />
                                                        <label className="form-check-label" for="flexCheckChecked24">
                                                            Tamil
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked25" />
                                                        <label className="form-check-label" for="flexCheckChecked25">
                                                            Telugu
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked26" />
                                                        <label className="form-check-label" for="flexCheckChecked26">
                                                            Urdu
                                                        </label>
                                                    </div>

                                                </div> */}

                                                <div className='check-icon-start1 ps-3'>
                                                    {content.map((checkbox) => (
                                                        <div key={checkbox?.id} className="mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox?.isChecked ? 'checked' : ''}`}
                                                                onClick={() => handleCheckbox(checkbox?.id)}>
                                                                <input type="checkbox" checked={checkbox?.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox?.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Gender" role="tabpanel" aria-labelledby="v-pills-Gender-tab" tabindex="0">
                                            <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1"  checked={genderAll}  onClick={() => selectAll("genders")}/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  onClick={() => selectAll("genders")}/>
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div> */}
                                        </div>
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefaul28" checked />
                                                        <label className="form-check-label" for="flexCheckDefaul28">
                                                            Male
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked27" />
                                                        <label className="form-check-label" for="flexCheckChecked27">
                                                            Female
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {gender.map((checkbox) => (
                                                        <div key={checkbox?.id} className=" mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox?.isChecked ? 'checked' : ''}`}
                                                                onClick={() => genderCheckbox(checkbox?.id)}>
                                                                <input type="checkbox" checked={checkbox?.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox?.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Country" role="tabpanel" aria-labelledby="v-pills-Country-tab" tabindex="0">
                                            <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1"  checked={countryAll} onClick={() => selectAll("countries")}/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={() => selectAll("countries")}/>
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div> */}
                                        </div>
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault29" checked />
                                                        <label className="form-check-label" for="flexCheckDefault29">
                                                            India
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked30" />
                                                        <label className="form-check-label" for="flexCheckChecked30">
                                                            Outside India
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {country.map((checkbox) => (
                                                        <div key={checkbox?.id} className="mb-sm-2 mb-0 ">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox?.isChecked ? 'checked' : ''}`}
                                                                onClick={() => countryCheckbox(checkbox?.id)}>
                                                                <input type="checkbox" checked={checkbox?.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox?.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Offer" role="tabpanel" aria-labelledby="v-pills-Offer-tab" tabindex="0">
                                            <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked  onClick={() => selectAll("offers")}/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  onClick={() => selectAll("offers")} />
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div> */}
                                        </div>
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault31" checked />
                                                        <label className="form-check-label" for="flexCheckDefault31">
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked32" />
                                                        <label className="form-check-label" for="flexCheckChecked32">
                                                            Non Active
                                                        </label>
                                                    </div>
                                                </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {offer.map((checkbox) => (
                                                        <div key={checkbox?.id} className="mb-sm-2 mb-0">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox?.isChecked ? 'checked' : ''}`}
                                                                onClick={() => offereCheckbox(checkbox?.id)}>
                                                                <input type="checkbox" checked={checkbox?.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox?.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="v-pills-Top" role="tabpanel" aria-labelledby="v-pills-Top-tab" tabindex="0">
                                            <div className='radio-icon mb-md-3 mb-sm-3 mb-0'>
                                            <div className="form-check me-1">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked  onClick={() => selectAll("tops")}/>
                                                <label className="form-check-label" for="flexRadioDefault1">
                                                    Select all
                                                </label>
                                            </div>
                                            {/* <div className="form-check">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  onClick={() => selectAll("tops")}/>
                                                <label className="form-check-label" for="flexRadioDefault2">
                                                    Clear all
                                                </label>
                                            </div> */}
                                        </div>
                                                {/* <div className='check-icon-start1 ps-3'>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault33" checked />
                                                        <label className="form-check-label" for="flexCheckDefault33">
                                                           All
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked34" />
                                                        <label className="form-check-label" for="flexCheckChecked34">
                                                           Must Try
                                                        </label>
                                                    </div>
                                                    <div className="form-check mb-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked35" />
                                                        <label className="form-check-label" for="flexCheckChecked35">
                                                        Top Choice
                                                        </label>
                                                    </div>
                                            </div> */}
                                                <div className='check-icon-start1 ps-3'>
                                                    {choice.map((checkbox) => (
                                                        <div key={checkbox?.id} className="mb-sm-2 mb-0 ">
                                                            <label
                                                                className={`form-check-label checkbox-label ${checkbox?.isChecked ? 'checked' : ''}`}
                                                                onClick={() => choiceCheckbox(checkbox?.id)}>
                                                                <input type="checkbox" checked={checkbox?.isChecked} className="form-check-input me-2" readOnly />
                                                                {checkbox?.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-start pt-0 me-md-4 me-sm-2 me-2">
                                <button type="button" className="btn btn-apply me-md-3 me-sm-2 me-1"  data-bs-dismiss="modal" aria-label="Close"
                                onClick={astrolgerFilter}
                                >Apply</button>
                                
                                <button type="button" className="btn btn-apply"
                                onClick={resetFilter}
                                >Reset</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterModalTalk