import React, { useEffect, useRef, useState } from 'react';
import videoimg from "../Video/login_video.mp4";
import successgif from "../../home-page/image/success.gif";
import astroVideo from "../Video/astroVideo.mp4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from "react-router-dom";


import { useContext } from "react";
import { Context } from "../../../utils/context";
const Register = () => {



    const { postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const [nameErr, setNameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [mobileErr, setMobileErr] = useState("");
    const [passwordErr, setPasswordErr] = useState("");
    const [conPasswordErr, setConPasswordErr] = useState("");


    const handleValidation = async () => {
        var username = document.getElementById('username').value;
        var email = document.getElementById('email').value;
        var mobile = document.getElementById('mobile').value;
        var password = document.getElementById('password').value;
        var con_password = document.getElementById('con_password').value;

        // Initialize an array to store error messages
        var errors = [];

        if (username.trim() === "") {
            setNameErr('Username is required');
            errors.push('Username is required');
        }
        else if (!/^[a-zA-Z ]+$/.test(username)) {
            setNameErr('Username should contain only letters and spaces');
            errors.push('Username should contain only letters and spaces');
        }

        if (email.trim() === "") {
            setEmailErr('Email is required');
            errors.push('Email is required');
        }
        else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailErr('Email is not valid');
            errors.push('Email is not valid');
        }

        if (mobile.trim() === "") {
            setMobileErr('Mobile is required');
            errors.push('Mobile is required');
        }
        else if (!/^\d{10}$/.test(mobile)) {
            setMobileErr('Mobile should be a 10-digit number');
            errors.push('Mobile should be a 10-digit number');
        }

        if (password === "") {
            setPasswordErr('Password is required');
            errors.push('Password is required');
        }

        if (con_password === "") {
            setConPasswordErr('Confirmation Password is required');
            errors.push('Confirmation Password is required');
        }
        else if (password !== con_password) {
            setConPasswordErr('Password and Confirmation Password do not match');
            errors.push('Password and Confirmation Password do not match');
        }

        if (errors.length === 0) {
            console.log(username + " " + email + " " + mobile + " " + password + " " + con_password, 'forms');
            const res = await postData(`/webregister?name=${username}&email=${email}&mobile=${mobile}&password=${password}`);
            console.log(res, 'register');
            if (res?.success) {


                let userid = res.data.user.id;
                sessionStorage.setItem('astrouserid', userid);
                console.log(userid, 'userid');

                let token = res.data.token;
                sessionStorage.setItem('astrotoken', token);
                console.log(token, 'token');

                    // Open the modal
                    setShowModal(true);

                    // Delay navigation by 2 seconds (2000 milliseconds)
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 2000);

            } else {
                console.log(res.response.data.message, 'fail');
                if (res.response.data.message.email) {
                    setEmailErr(res.response.data.message.email);
                }
                if (res.response.data.message.mobile) {
                    setMobileErr(res.response.data.message.mobile);
                }
            }

        }



    };


    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setshowConPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConPasswordVisibility = () => {
    setshowConPassword(!showConPassword);
  };

    const vidRef = useRef();

    useEffect(() => { vidRef.current.play(); }, []);
    return (
        <>
            <section className='Login'>
                <div className='container-fluid'>
                    <div className=" banner-slider ">
                        <video src={videoimg} ref={vidRef} muted loop className=" video-class-grid" />
                        <video src={astroVideo} ref={vidRef} muted loop className=" video-class1" />
                        <div className="main_login_section ">
                            <div className="login-page ">

                                <div className="login-container p-4">
                                    <h2 className="text-center mb-4 Login_heading">Register</h2>
                                    <p className='heading_info'>Hey, Enter your details to get sign in to your account</p>
                                    <form>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label email_label">UserName</label>
                                            <div className="inputField">
                                                <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2'>
                                                            <span className="input-icon">
                                                                <FontAwesomeIcon icon={faUser} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10 '>
                                                            <input type="text" id="username" className="content_input_field form-control mb-2" name="name" placeholder="UserName"
                                                                onKeyDown={(event) => {
                                                                    if (!/[A-Z-a-z ]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={() => (setNameErr(""))} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className='text text-danger'>{nameErr && (nameErr)}</span>

                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label password_label">Email</label>
                                            <div className="inputField">
                                                <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2'>
                                                            <span className="input-icon">
                                                                <FontAwesomeIcon icon={faEnvelope} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type="text" id="email" className="content_input_field form-control mb-2" name="email" placeholder="Email" onChange={() => (setEmailErr(""))} />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <span className='text text-danger'>{emailErr && (emailErr)}</span>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label password_label">Phone</label>
                                            <div className="inputField">
                                                <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2'>
                                                            <span className="input-icon">
                                                                <FontAwesomeIcon icon={faPhone} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type="text" id="mobile" minlength="10" maxlength="10" name="mobile" className="content_input_field form-control mb-2" placeholder="Phone" onKeyDown={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    if (event.key != 'Backspace')
                                                                        event.preventDefault();
                                                                }
                                                            }} onChange={() => (setMobileErr(""))} />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <span className='text text-danger'>{mobileErr && (mobileErr)}</span>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label password_label">Password</label>
                                            <div className="inputField">
                                                <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2'  onClick={togglePasswordVisibility}>
                                                            <span className="input-icon">
                                                                
                                                                <FontAwesomeIcon icon={showPassword ? faUnlock : faLock} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type={showPassword ? 'text' : 'password'} id="password" className="content_input_field form-control mb-2" placeholder="Password" onChange={() => (setPasswordErr(""))} />

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <span className='text text-danger'>{passwordErr && (passwordErr)}</span>
                                        <div className="form-group">
                                            <label for="exampleInputEmail1" class="form-label password_label">Confirm Password</label>
                                            <div className="inputField">
                                                <div className="input-container">
                                                    <div className='row'>
                                                        <div className='col-lg-1 col-2' onClick={toggleConPasswordVisibility}>
                                                            <span className="input-icon">
                                                                <FontAwesomeIcon icon={showConPassword ? faUnlock : faLock} />
                                                            </span>
                                                        </div>
                                                        <div className='col-lg-11 col-10'>
                                                            <input type={showConPassword ? 'text' : 'password'}  id="con_password" className="content_input_field form-control mb-2" placeholder="Confirm Password" onChange={() => (setConPasswordErr(""))} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <span className='text text-danger'>{conPasswordErr && (conPasswordErr)}</span>
                                        <div className='text-center'>
                                            <button type="button" className="btn btn-primary login_btn text-center mt-2" onClick={handleValidation}>
                                                Register
                                            </button>

                                        </div>
                                    </form>
                                </div>

                            </div>

                            <div className='row below_common_section mt-4'>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>100% </p>
                                        <p className='text-info'>Privacy</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className='first_sec'>
                                        <p className='data_txt'>10000+  </p>
                                        <p className='text-info'>Tap astrologers of India</p>

                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <div className=''>
                                        <p className='data_txt'>3Cr+ </p>
                                        <p className='text-info'>Happy Customers</p>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* **********<!--call Modal start--> ************/}
            <section className="modal-chat">
                <div 
                className= {showModal ? "modal fade show" : "modal fade"}
                id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <img src={successgif} />
                                </div>
                                <div className="text-holder text-center">
                                    <h5>Registered Successfully...!</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}

        </>
    )
}

export default Register