import React, { useState } from 'react'
import '../../chat-astrologer/filter-modal/FilterModal.css';
const SortTalkModal = () => {



    const [selectedRadio, setSelectedRadio] = useState(1);

    const radioOptions = [
        { id: "pop", text: 'Popularity' },
        { id: "expHi", text: 'Experience : High to Low' },
        { id: "expLo", text: 'Experience : Low to High' },
        { id: "totHi", text: 'Total orders : High to Low' },
        { id: "totLo", text: 'Total orders : Low to High' },
        { id: "priceHi", text: 'Price : High to Low' },
        { id: "priceLo", text: 'Price : Low to High' },
        { id: "ratHi", text: 'Rating : High to Low' },
        { id: "ratLo", text: 'Rating : Low to High' },
    ];

    const handleRadioChange = (id) => {
        setSelectedRadio(id);
    };

    const labelStyle = {
        color: selectedRadio ? '#F5770A' : 'black', // Change color when checked
    };
    return (
        <>
            {/* <!-- Modal --> */}

            <div className='filtermodal'>
                <div className="modal fade" id="sortModaltalk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body ps-md-5 ps-sm-4 ps-4">
                                <div className='d-flex mb-3'>
                                    <div className='sort'>
                                        <h5>Sort By</h5>
                                    </div>
                                    {/* <div className='radio-icon me-md-5 me-sm-2 me-0'>
                                        <div className="form-check me-1">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                            <label className="form-check-label" for="flexRadioDefault1">
                                                Select all
                                            </label>
                                        </div>
                                        <div className="form-check ms-2">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label className="form-check-label" for="flexRadioDefault2">
                                                Clear all
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                               

                                {radioOptions.map((option) => (
                                    <div key={option.id} className="checkbox-container mb-md-2 mb-sm-2 mb-0">
                                        <label
                                            className={`form-check-label checkbox-label ${selectedRadio === option.id ? 'checked' : ''}`}
                                            for={`radioOption${option.id}`}
                                        >
                                            <input
                                            className="form-check-input me-2"
                                            type="radio"
                                            id={`radioOption${option.id}`}
                                            name="sortRadioOptions"
                                            value={option.id}
                                            checked={selectedRadio === option.id}
                                            onChange={() => handleRadioChange(option.id)}
                                        />
                                            {option.text}
                                        </label>
                                    </div>
                                ))}
                                <div className="py-2">
                                    <button type="button" className="btn btn-apply me-3" >Apply</button>
                                    <button type="button" className="btn btn-apply">Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SortTalkModal