import React from 'react';
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Rings } from 'react-loader-spinner';

import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../../../utils/context";

const BasicFirstTab = () => {
  
      const navigate = useNavigate();
        const { getData, postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl, ReactLoader, setReactLoader} = useContext(Context)
        
        const cookiedata = JSON.parse(Cookies.get('freekundlidata'));

        const [kundliData, setKundliData] = useState();
        const [numerotable, setNumerotable] = useState();
        const [avkhadaDetails, setAvkhadaDetails] = useState();
        const postKundli = async () => {
          const res = await postData(`/freeKundli?name=${cookiedata?.name}&place=${cookiedata?.place}&dob=${cookiedata?.dob}&time=${cookiedata?.time}&ampm=${cookiedata?.ampm}`)
          if (res?.success) {
              console.log(res.data, 'kundliData');
              setKundliData(res.data);
              setNumerotable(res.data.numerotable);
              setAvkhadaDetails(res.data.avkhadaDetails);
              setReactLoader(false);
          }
      };

  useEffect(() => {
  
        if(cookiedata.name){
          postKundli();
        }else{
          navigate("/freekundali");
        }
      
}, []);
  return (
    <>
    {ReactLoader ? (
      <Rings color="#FF7E00" height={80} width={80} />
    ) : (
      <div className="row mt-lg-5 mt-md-5  mt-4">
        <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12">
          <div className="basicDetails-txt mb-2">Basic Details</div>
          <div className="table-responsive">
            <table className="table table-bordered  ">
              <tbody>
                <tr className="table-warning1">
                  <td>Name</td>
                  <td>{numerotable?.name}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{cookiedata?.dob} </td>
                </tr>
                <tr className="table-warning1">
                  <td>Time</td>
                  <td>{cookiedata?.time} {cookiedata?.ampm}</td>
                </tr>
                <tr>
                  <td>Place</td>
                  <td>{kundliData?.address}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Latitude</td>
                  <td>{Math.round(kundliData?.lat)}</td>
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td>{Math.round(kundliData?.lon)}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Timezone</td>
                  <td>GMT+5.5</td>
                </tr>
                <tr>
                  <td>Sunrise</td>
                  <td>5:27:46</td>
                </tr>
                <tr className="table-warning1">
                  <td>Sunset</td>
                  <td>18:31:20</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="basicDetails-txt mt-4 mb-3">Panchang Details</div>
          <div className="table-responsive ">
            <table className="table table-bordered  ">
              <tbody>
                <tr className="table-warning1">
                  <td>Tithi</td>
                  <td>{avkhadaDetails?.Tithi}</td>
                </tr>
                <tr>
                  <td>Karan</td>
                  <td>{avkhadaDetails?.Karan}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Yog</td>
                  <td>{avkhadaDetails?.Yog}</td>
                </tr>
                <tr>
                  <td>Nakshatra</td>
                  <td>{avkhadaDetails?.Naksahtra}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div className='col-xxl-1 col-xl-1 col-lg-1'></div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12">
          <div className="basicDetails-txt my-2">Avakhada Details</div>
          <div className="table-responsive">
            <table className="table table-bordered  ">
              <tbody>
                <tr className="table-warning1">
                  <td>Varna</td>
                  <td>{avkhadaDetails?.Varna}</td>
                </tr>
                <tr>
                  <td>Vashya</td>
                  <td>{avkhadaDetails?.Vashya}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Yoni</td>
                  <td>{avkhadaDetails?.Yoni}</td>
                </tr>
                <tr>
                  <td>Gan</td>
                  <td>{avkhadaDetails?.Gan}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Nadi</td>
                  <td>{avkhadaDetails?.Nadi}</td>
                </tr>
                <tr>
                  <td>Sign</td>
                  <td>{avkhadaDetails?.sign}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Sign Lord</td>
                  <td>{avkhadaDetails?.SignLord}</td>
                </tr>
                <tr>
                  <td>Nakshatra-Charan</td>
                  <td>{avkhadaDetails?.Charan}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Yog</td>
                  <td>{avkhadaDetails?.Yog}</td>
                </tr>
                <tr>
                  <td>Karan</td>
                  <td>{avkhadaDetails?.Karan}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Tithi</td>
                  <td>{avkhadaDetails?.Tithi}</td>
                </tr>
                <tr>
                  <td>Yunja</td>
                  <td>{avkhadaDetails?.yunja}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Tatva</td>
                  <td>{avkhadaDetails?.tatva}</td>
                </tr>
                <tr >
                  <td>Name alphabet</td>
                  <td>{avkhadaDetails?.name_alphabet}</td>
                </tr>
                <tr className="table-warning1">
                  <td>Paya</td>
                  <td>{avkhadaDetails?.paya}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
      )}
    </>
  )
}

export default BasicFirstTab