import React, { useState, useEffect } from "react";
import kundli_img from "../../component/Blog/Images/kndli-image_5e60fdc66100a.png";
import engagement_img from "./Images/golden-engagement-ring-hand.png";
import wedding_ring from "./Images/wedding_ring.png";
import chatmodalimg from "./Images/phone-call.png";
import { Link, useNavigate } from "react-router-dom";
import "./KundliMatching.css";
import '../chat-astrologer/Chatastrolger.css'
import ConnectAstrologer from "../Kundali/connect-astrologer/ConnectAstrologer";
import { Rings } from 'react-loader-spinner';

import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context";
const KundliMatching = () => {

  const navigate = useNavigate();
  const { getData, postData, ReactLoader,setReactLoader} = useContext(Context)
  
  const cookiedata = JSON.parse(Cookies.get('kundliMatch'));

  const [kundliMatchData, setKundliMatchData] = useState();
  const [matchTotal, setMatchTotal] = useState();
  const [matchConclusion, setMatchConclusion] = useState();
  const [numerotable, setNumerotable] = useState();
  const [avkhadaDetails, setAvkhadaDetails] = useState();
  const postKundli = async () => {
    console.log(cookiedata, 'matching data');
    const res = await postData(`/matchingAshtakoot?m_name=${cookiedata?.m_name}&m_place=${cookiedata?.m_place}&m_dob=${cookiedata?.m_dob}&m_time=${cookiedata?.m_time}&m_ampm=${cookiedata?.m_ampm}&f_name=${cookiedata?.f_name}&f_place=${cookiedata?.f_place}&f_dob=${cookiedata?.f_dob}&f_time=${cookiedata?.f_time}&f_ampm=${cookiedata?.f_ampm}`)
    if (res?.success) {
        console.log(res, 'kundliMatchData');
        setKundliMatchData(res.data.ashtakoot);
        setMatchTotal(res.data.total);
        setMatchConclusion(res.data.conslusion.report);
        // setNumerotable(res.data.numerotable);
        // setAvkhadaDetails(res.data.avkhadaDetails);
        setReactLoader(false);
    }
};

useEffect(() => {

  if(cookiedata.m_name){
    postKundli();
  }else{
    navigate("/Kundali");
  }

}, []);
  return (
    <>
     
      <section className="KundliMatching desktop-view-kundalimatch">
        <div className="container">

          <div className="row">
            <div className="col-md-12 mt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                  <li className="breadcrumb-item ">
                    <Link to='/kundali-match'>Kundali Matching</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to='/kundali'> Kundli</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="kundli_img-sec ">
                <img src={kundli_img} alt="kundli_img" className="kundli_img" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="kundli_matching-txt">Kundali Matching</div>
              <div className="main-heading mt-2">
                <div class="line me-2"></div>
                <div class="square"></div>
                <div class="line ms-2"></div>
              </div>

              <div className="text-center">
                <img
                  src={engagement_img}
                  className="engagement_img my-2"
                  alt="engagement_img"
                />
              </div>
              <div className="text-center">
                <span className="name-txt pt-3">{cookiedata?.m_name}</span> &nbsp; &nbsp;
                <img
                  src={wedding_ring}
                  alt="wedding_ring"
                  className="wedding_ring"
                />{" "}
                &nbsp; &nbsp;
                <span className="name-txt pt-3">{cookiedata?.f_name}</span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6 col-sm-8 col-10 mx-auto">
              <div className="Ashtakoot-heading text-center">
                Match Ashtakoot Points
              </div>
            </div>
          </div>
                {ReactLoader ? (
            <Rings color="#FF7E00" height={80} width={80} />
          ) : (
          <div className="row mt-4">
            <div className="col-md-12">
              {/* <div className="text-center table-name-txt mb-2">Shubham Kundali Point</div> */}
              <div className="table-responsive mt-4">
                <table className="table table-bordered   ">
                  <thead>
                    <tr className="table-warning1 table-heading ">
                      <th>Attribute</th>
                      <th>Male</th>
                      <th>Female</th>
                      <th>Received</th>
                      <th className="out-of">Out of</th>
                      {/* <th>Area of Life</th> */}
                      <th>Meaning</th>
                    </tr>
                  </thead>
                  <tbody>
                    {kundliMatchData?.map((val) => (
                    <tr>
                      <td className="varna-txt">{val?.attribute}</td>
                      <td>{val?.male_attribute}</td>
                      <td>{val?.female_attribute}</td>
                      <td>{val?.received_points}</td>
                      <td>{val?.total_points}</td>
                      {/* <td>Natural Refinement / Work</td> */}
                      <td>{val?.description}</td>
                    </tr>
                    ))}
                    {/* <tr>
                      <td className="varna-txt">Vashya</td>
                      <td>Nara</td>
                      <td>Keeta</td>
                      <td>1</td>
                      <td>2</td>
                      <td>Innate Giving / Attraction towards each other</td>
                      <td>Vashya indicates the bride and the groom's tendency to dominate or influence each other in a marri</td>
                    </tr> */}
                  
                    <tr className="table-warning1">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td></td>
                      <td></td>
                      <td>{matchTotal?.received_points}</td>
                      <td>{matchTotal?.total_points}</td>
                      <td>Minimum Required Points : {matchTotal?.minimum_required}</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12">
              <div className="total-point mt-4">
                <h5>{matchConclusion}</h5>
              </div>
            </div>
          </div>
          )}
          <div className="row">
            <ConnectAstrologer />
          </div>
        </div>
      </section>
   

      {/* mobile-view-start */}
      <section className="KundliMatching mobile-view-kundalimatch">
        <div className="container">

          <div className="row">
            <div className="col-md-12 mt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li class="breadcrumb-item"><Link to='/'>Home {'>'}</Link></li>
                  <li className="breadcrumb-item ">
                    <Link to='/kundali-match'>Kundali Matching {'>'}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to='/kundali'> Kundli</Link>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-12">
              <div className="kundli_matching-txt">Kundali Matching</div>
              <div className="main-heading">
                <div class="line me-2"></div>
                <div class="square"></div>
                <div class="line ms-2"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className=" col-6">
              <div className="kundli_img-sec text-start mt-3">
                <img src={kundli_img} alt="kundli_img" className="kundli_img" />
              </div>
            </div>
            <div className=" col-6">


              <div className="text-center mt-3">
                <img
                  src={engagement_img}
                  className="engagement_img mt-2"
                  alt="engagement_img"
                />
              </div>
              <div className="text-center">
                <span className="name-txt pt-2">Shubham</span>
                <img
                  src={wedding_ring}
                  alt="wedding_ring"
                  className="wedding_ring mx-1"
                />{" "}

                <span className="name-txt pt-2">Priya</span>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className=" col-sm-8 col-10 mx-auto">
              <div className="Ashtakoot-heading text-center">
                Match Ashtakoot Points
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              {/* <div className="text-center table-name-txt mb-2">Shubham Kundali Point</div> */}
              <div className="table-responsive mt-4">
                <table className="table table-bordered   ">
                  <thead>
                    <tr className="table-warning1 table-heading ">
                      <th>Attribute</th>
                      <th>Male</th>
                      <th>Female</th>
                      <th>Received</th>
                      <th className="out-of">Out of</th>
                      <th>Area of Life</th>
                      <th>Meaning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="varna-txt">Varna</td>
                      <td>Kshatriya</td>
                      <td>Brahmin</td>
                      <td>0</td>
                      <td>1</td>
                      <td>Natural Refinement / Work</td>
                      <td className="long-pg">Varna refers to the mental compatibility of the two persons involved.
                        It holds nominal effect in the matters of marriage compatibility.</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Vashya</td>
                      <td>Nara</td>
                      <td>Keeta</td>
                      <td>1</td>
                      <td>2</td>
                      <td>Innate Giving / Attraction towards each other</td>
                      <td className="long-pg">Vashya indicates the bride and the groom's tendency to dominate or influence each other in a marri</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Tara</td>
                      <td></td>
                      <td></td>
                      <td>3</td>
                      <td>3</td>
                      <td>Comfort - Prosperity - Health</td>
                      <td className="long-pg">Tara is the indicator of the birth star compatibility of the bride and the groom. It also indicates the fortune of the couple.</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Yoni</td>
                      <td>Shawan</td>
                      <td>Vyaghra</td>
                      <td>1</td>
                      <td>4</td>
                      <td>Physical Intimacy</td>
                      <td className="long-pg"> Yoni is the indicator of the sexual or physical compatibility between the bride and the groom in question.</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Maitri</td>
                      <td>Jupiter</td>
                      <td>Mars</td>
                      <td>1</td>
                      <td>4</td>
                      <td>Friendliness of Sign Lords</td>
                      <td className="long-pg">Graha Maitri is the indicator of the intellectual and mental connection between the prospective couple.</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Gan</td>
                      <td>Rakshasa</td>
                      <td>Rakshasa</td>
                      <td>6</td>
                      <td>6</td>
                      <td>Temperament</td>
                      <td className="long-pg">Gana is the indicator of the behaviour, character and temperament of the potential bride and groom towards each other.</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Bhakut</td>
                      <td>Sagittarius</td>
                      <td>Scorpio</td>
                      <td>7</td>
                      <td>7</td>
                      <td>Health and wealth</td>
                      <td className="long-pg">Bhakoota is related to the couple's joys and sorrows together and assesses the wealth and health after their wedding.</td>
                    </tr>
                    <tr>
                      <td className="varna-txt">Nadi</td>
                      <td>Adhya</td>
                      <td>Adhya</td>
                      <td>7</td>
                      <td>7</td>
                      <td>Progeny</td>
                      <td className="long-pg">Nadi is related to the health compatibility of the couple. Matters of childbirth and progeny are also determined with this Guna.</td>
                    </tr>
                    <tr className="table-warning1">
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td></td>
                      <td></td>
                      <td>28.5</td>
                      <td>36</td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-12">
              <div className="total-point mt-4">
                <h5>AstroBooster Matching between male and female is 28.5 points out of 36 points. This is a reasonably good score.
                  Hence, this is a favourable AstroBooster match.</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <ConnectAstrologer />
          </div>
        </div>
      </section>

    </>
  );
};

export default KundliMatching;
