import React from "react";
import { useState, useEffect } from "react";
import Category_icon from "../Images/Group 22562.png";
import Tarot_img from "../Images/fairytale-aurora-graphics-enchanting-elements-magical-charm.png";
import high_angle from "../Images/high-angle-tarot-cards-arrangement-table (1).png"
import kundli from "../Images/kndli-image_5e60fdc66100a.png"
import Tower from "../Images/Tower_img.png"
import sidebar_below from "../Images/Sidebar_below.png"
import "../Blog.css";
import "./ReadMore.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import whatappimg from '../Images/whatsapp.png';
import linkdinimg from '../Images/linkdin.png';
import twitterimg from '../Images/twitter.png';
import facebookimg from '../Images/facebook.png';




import { useContext } from "react";
import { Context } from "../../../utils/context";
const ReadMore = () => {

  const { id } = useParams();

  const navigate = useNavigate();
  const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl } = useContext(Context)

  const [blogDetail, setBlogDetail] = useState();
  const [blogCategory, setBlogCategory] = useState();
  const [category, setCategory] = useState();
  const getBlogDetail = async () => {
    const res = await getData(`/blog/${id}`)
    if (res?.success) {
      console.log(res.data, 'BlogDetail');
      setBlogDetail(res.data.blog);
    }
  };

  const getBlogs = async () => {
    const res = await getData("/blogCategory")
    if (res?.success) {
      console.log(res.data, 'BlogsCategory');
      setBlogCategory(res.data.category);
      setCategory(res.data.category[0].id);
    }
  };

  const passCategory = async (id) => {
          navigate(`/blog/${id}`);
  };

  useEffect(() => {

    getBlogDetail();
    getBlogs();
  }, []);
  return (
    <>
      <section className="Blog mb-5 desktop-view-blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb ms-5">
                  <li className="breadcrumb-item "><Link to='/'>Home  </Link></li>
                  <li className="breadcrumb-item " aria-current="page">
                    <Link to='/blog'> Blog</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {blogDetail?.heading}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row me-0 ms-0">
            <div className="col-xl-3 col-lg-4 col-md-6  p-0">
              <div className="card-sidebar pb-5">
                <div className="categary-class pt-3">
                  <img src={Category_icon} alt="Category_icon" className="Category_icon" /><span className="categories-txt"> Categories</span>
                  <div className="icon-txt my-2"></div>
                </div>
                {/* <div className="dashed-line"></div> */}

                <div
                  className="nav flex-column nav-pills me-3 mt-3 card-body sidebar-section"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {blogCategory?.map((val, index) => (
                  <button
                    className={blogDetail?.blog_category_id == val?.id ? "nav-link active" : "nav-link"}
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                    onClick={() => passCategory(val?.id)}
                  >
                    {val?.name}
                  </button>
                    ))}
                  {/* <button
                    className="nav-link"
                    id="v-pills-Tarat-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-Tarat"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Tarat
                  </button> */}
                  
                </div>
              </div>

              <div className=" sidebar_below_section  mt-4 mb-md-3">
                <img src={sidebar_below} className="sidebar_below" alt="img" />
                <div className="sidebar_below_txt">
                  <span className="vastu-txt">Vastu </span> <br />
                  <span className="blessful-txt">Tips For A Blessful Life</span>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 mx-auto">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                  tabindex="0">
                  <div className="row">
                    <div className="col-xl-4 col-lg-5 col-md-6">
                      <div className="Tarot_img_sec">
                        <img
                          src={imageUrl + blogDetail?.image}
                          alt="Tarot_img"
                          className="tarot_img"
                        />

                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-7 col-md-6">
                      <div className="tarot-heading">{blogDetail?.heading}</div>
                      <div className="main mt-2">
                        <div class="line me-2"></div>
                        <div class="square"></div>
                        <div class="line ms-2"></div>
                      </div>
                      <p className="tarot-info">
                        {blogDetail?.description}
                      </p>
                    </div>
                  </div>
                  {blogDetail?.blog_content?.map((val) => (


                    <div className="row mt-5">
                      <div className="col-lg-6 col-md-6">
                        <div className="Chariot">{val?.heading}
                          <div className="solid-line"></div>

                        </div>
                        <p className="tarot-info">
                          {val?.description}
                        </p>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="Charoit-img-sec">
                          <img
                            src={imageUrl + val?.image}
                            alt="Tarot_img"
                            className="high_angle_img"
                          />

                        </div>

                      </div>
                    </div>
                  ))}


                  <div className="row mt-5">
                    <div className="col-lg-12 col-md-12">
                      <div className="conclusion">Conclusion</div>

                      <p className="tarot-info">
                        {blogDetail?.conclusion}
                      </p>
                    </div>
                  </div>
                </div>




                <div
                  className="tab-pane fade"
                  id="v-pills-Tarat"
                  role="tabpanel"
                  aria-labelledby="v-pills-Tarat-tab"
                  tabindex="0"
                >
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mt-3"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6 mt-3"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6  mt-3"></div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-vastu"
                  role="tabpanel"
                  aria-labelledby="v-pills-disabled-tab"
                  tabindex="0"
                >
                  <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6"></div>
                    <div className="col-xl-4 col-lg-6 col-md-6 "></div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                  tabindex="0"
                >
                  ...
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="icons">
              <div className="social-image ">
                <img src={whatappimg} className="social-icon mb-3" />
              </div>
              <div className="social-image ">
                <img src={linkdinimg} className="social-icon mb-3" />
              </div>
              <div className="social-image ">
                <img src={twitterimg} className="social-icon mb-3" />
              </div>
              <div className="social-image ">
                <img src={facebookimg} className="social-icon mb-3" />
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="Blog mb-5 mobile-view-blog">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 mt-2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb my-3">
                  <li className="breadcrumb-item "><Link to='/'>Home {'>'} </Link></li>
                  <li className="breadcrumb-item " aria-current="page">
                    Blog {'>'}
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {blogDetail?.heading}
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="icons d-flex justify-content-end">
                <div className="social-image me-2">
                  <img src={whatappimg} className="social-icon mb-3" />
                </div>
                <div className="social-image me-2">
                  <img src={linkdinimg} className="social-icon mb-3" />
                </div>
                <div className="social-image me-2">
                  <img src={twitterimg} className="social-icon mb-3" />
                </div>
                <div className="social-image ">
                  <img src={facebookimg} className="social-icon mb-3" />
                </div>
              </div>

              <div className="Tarot_img_sec text-center">
                <img
                  src={imageUrl + blogDetail?.image}
                  alt="Tarot_img"
                  className="tarot_img"
                />
              </div>
              <div className="col-12">
                <div className="tarot-heading mt-3">{blogDetail?.heading}</div>
                <div className="main-heading mx-auto mt-2 mb-4">
                  <div class="line me-1"></div>
                  <div class="square"></div>
                  <div class="line ms-1"></div>
                </div>
                <p className="tarot-info">
                  {blogDetail?.description}
                </p>
                {blogDetail?.blog_content?.map((value) => (
                  <>
                    <div className="Charoit-img-sec text-center mt-5">
                      <img
                        src={imageUrl + value?.image}
                        alt="Tarot_img"
                        className="high_angle_img"
                      />
                    </div>
                    <div className="Chariot my-3">
                      {value?.heading}
                      <div className="solid-line"></div>
                    </div>
                    <p className="tarot-info">
                      {value?.description}
                    </p>
                  </>
                ))}
                {/* <div className="Hermit-img-sec my-3">
                  <img
                    src={kundli}
                    alt="Tarot_img"
                    className="high_angle_img"
                  />
                </div>
                <div className="Hermit">The Hermit
                  <div className="solid-line"></div>
                </div>
                <p className="tarot-info">
                  The Hermit card calls for introspection and solitude. It’s a day to seek inner wisdom and reflect on your path. Take some time to meditate and connect with your inner self. This card indicates that answers can be found within, and seeking solitude will lead to valuable insights.
                </p>

                <div className="Tower_img_sec mt-5">
                  <img
                    src={Tower}
                    alt="Tarot_img"
                    className="tower"
                  />

                </div>
                <div className="Tower-txt mt-3">The Tower (Reversed)</div>
                <div className="solid-line-Tower"></div>
                <p className="tarot-info">
                  The Tower card, in reverse, brings relief from sudden upheavals. It suggests that any challenges you’ve been facing are beginning to subside. While there might still be some residual effects, you’re on the path to recovery. Use this day to rebuild and reevaluate your plans.
                </p> */}

                <div className="conclusion">Conclusion</div>
                <p className="tarot-info">
                  {blogDetail?.conclusion}
                </p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReadMore;
