import React from 'react';
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../second-tab-kundali/SecondTabKundali.css';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'react-tabs/style/react-tabs.css';
import { Rings } from 'react-loader-spinner';




import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
const SecondTabKundali = () => {
    

    
    const { getData, postData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl, ReactLoader, setReactLoader, freeKundli,setFreeKundli} = useContext(Context)

    return (
        <>
            {ReactLoader ? (
                <Rings color="#FF7E00" height={80} width={80} />
                ) : (
            <div className='row south-north-tabs desktop-view-free-kundali'>
                <ul className="nav nav-pills justify-content-end mt-md-0 mt-4 mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item mb-md-0 mb-sm-2 mb-2  me-2" role="presentation">
                        <button className="nav-link active " id="pills-North-tab" data-bs-toggle="pill" data-bs-target="#pills-North" type="button" role="tab" aria-controls="pills-North" aria-selected="true">North Indian</button>
                    </li>
                    <li className="nav-item " role="presentation">
                        <button className="nav-link" id="pills-South-tab" data-bs-toggle="pill" data-bs-target="#pills-South" type="button" role="tab" aria-controls="pills-South" aria-selected="false">South Indian</button>
                    </li>

                </ul>

                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-North" role="tabpanel" aria-labelledby="pills-North-tab" tabindex="0">
                        <div className='row mt-3 mb-4'>
                            <div className='col-lg-6 '>
                                <div className="basicDetails-txt text-center  mb-2">Lagna / Ascendant / Basic Birth Chart</div>
                                <div class="table-responsive ">
                                    <table className='mx-auto'>
                                        <div className="main_Kundli ">
                                            <div className="sub"></div>
                                            <div className="line1"></div>
                                            <div className="line2"></div>
                                            {freeKundli?.lagnaKundli?.map((val, index) => (
                                            <p className={"p"+(index+1)}> <sub>{val?.planets}</sub> <br />{val?.place}</p>
                                            ))}
                                            {/* <p className="p2">AA<br />02</p>
                                            <p className="p3">AA<br />03</p>
                                            <p className="p4">AA<br />04</p>
                                            <p className="p5">AA<br />05</p>
                                            <p className="p6">AA<br />06</p>
                                            <p className="p7">AA<br />07</p>
                                            <p className="p8">AA<br />08</p>
                                            <p className="p9">AA<br />09</p>
                                            <p className="p10">AA<br />10</p>
                                            <p className="p11">AA<br />11</p>
                                            <p className="p12">AA<br />12</p> */}
                                        </div>
                                    </table>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="basicDetails-txt text-center mt-lg-0 mt-md-4">Navamsa</div>
                                <div class="table-responsive ">
                                    <table className='mx-auto'>
                                        <div className="main_Kundli mt-lg-0 mt-md-0 mt-sm-4 mt-4">
                                            <div className="sub"></div>
                                            <div className="line1"></div>
                                            <div className="line2"></div>
                                            <p className="p1">AA <br />01</p>
                                            <p className="p2">AA<br />02</p>
                                            <p className="p3">AA<br />03</p>
                                            <p className="p4">AA<br />04</p>
                                            <p className="p5">AA<br />05</p>
                                            <p className="p6">AA<br />06</p>
                                            <p className="p7">AA<br />07</p>
                                            <p className="p8">AA<br />08</p>
                                            <p className="p9">AA<br />09</p>
                                            <p className="p10">AA<br />10</p>
                                            <p className="p11">AA<br />11</p>
                                            <p className="p12">AA<br />12</p>
                                        </div>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="tab-pane fade" id="pills-South" role="tabpanel" aria-labelledby="pills-South-tab" tabindex="0">
                        <div className='row mb-4 '>
                            <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-12  mx-auto'>
                                <div className="basicDetails-txt text-center mb-2" >Lagna / Ascendant / Basic Birth Chart</div>
                                <div className="table-responsive">
                                    <table className="table table-bordered lagna-table mx-auto">
                                        <tbody>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index < 4 && (
                                                    <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td>
                                                )
                                                ))}
                                            </tr>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index > 3 && index < 8 && (
                                                    index === 5 && val === null ? (
                                                    <td colSpan={2} rowSpan={2}></td>
                                                    ) : (
                                                    index !== 6 ? <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td> : null
                                                    )
                                                )
                                                ))}
                                            </tr>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index > 7 && index < 12 && (
                                                val != null &&(
                                                    <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td>
                                                )
                                                )
                                                ))}
                                            </tr>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index > 11 && index < 16 && (
                                                    <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td>
                                                )
                                                ))}
                                            </tr>
                                            

                                        </tbody>
                                    </table>
                                            

                                </div>

                            </div>

                            <div className='col-xxl-5 col-xl-5 col-lg-6 col-md-12 mx-auto'>
                                <div className="basicDetails-txt text-center mt-lg-0 mt-md-4 mb-2">Navamsat</div>
                                <div className="table-responsive">
                                    <table className="table table-bordered lagna-table mx-auto">
                                        <tbody>
                                            <tr >
                                                <td>Name</td>
                                                <td>Shubham Yadav</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            <tr >
                                                <td >Time</td>
                                                <td colSpan={2} rowSpan={2}></td>
                                                <td  ></td>
                                            </tr>
                                            <tr >
                                                <td >Time</td>
                                                <td></td>
                                            </tr>
                                            <tr >
                                                <td>Timezone</td>
                                                <td>GMT+5.5</td>
                                                <td>Sunrise</td>
                                                <td>5:27:46</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
                        )}  


            {/* mobile-view-start */}
            {ReactLoader ? (
                <Rings color="#FF7E00" height={80} width={80} />
                ) : (
            <div className='row  south-north-tabs mobile-view-free-kundali'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="nav nav-pills justify-content-end  mt-4 mb-3" role="tablist">
                        <Nav.Item className="nav-item  north_indian_txt me-2" role="presentation">
                            <Nav.Link eventKey="first" className="nav-link pt-1">North Indian</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item north_indian_txt me-3">
                            <Nav.Link eventKey="second" className="nav-link pt-1">South Indian</Nav.Link>
                        </Nav.Item>
                    </Nav>


                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className='row '>
                                <div className='col-sm-12 '>
                                    <div className="basicDetails-txt  text-center mb-2">Lagna / Ascendant / Basic Birth Chart</div>
                                    <div class="main-kundali  mx-auto">
                                        <div class="sub"></div>
                                        <div class="line1"></div>
                                        <div class="line2"></div>
                                        {freeKundli?.lagnaKundli?.map((val, index) => (
                                            <p className={"p"+(index+1)}>{val?.place}<sub>{val?.planets}</sub></p>
                                        ))}
                                        {/* <p class="p1">01</p>
                                        <p class="p2">02</p>
                                        <p class="p3">03</p>
                                        <p class="p4">04</p>
                                        <p class="p5">05</p>
                                        <p class="p6">06</p>
                                        <p class="p7">07</p>
                                        <p class="p8">08</p>
                                        <p class="p9">09</p>
                                        <p class="p10">10</p>
                                        <p class="p11">11</p>
                                        <p class="p12">12</p> */}
                                    </div>

                                </div>
                                <div className='col-sm-12'>
                                    <div className="basicDetails-txt  text-center mt-3">Navamsa</div>
                                    <div class="main-kundali mx-auto">
                                        <div class="sub"></div>
                                        <div class="line1"></div>
                                        <div class="line2"></div>

                                        <p class="p1">01</p>
                                        <p class="p2">02</p>
                                        <p class="p3">03</p>
                                        <p class="p4">04</p>
                                        <p class="p5">05</p>
                                        <p class="p6">06</p>
                                        <p class="p7">07</p>
                                        <p class="p8">08</p>
                                        <p class="p9">09</p>
                                        <p class="p10">10</p>
                                        <p class="p11">11</p>
                                        <p class="p12">12</p>
                                    </div>

                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className='row mb-5'>
                                <div className='col-sm-12'>
                                    <div className="basicDetails-txt text-center mb-2">Lagna / Ascendant / Basic Birth Chart</div>
                                    <div className="table-responsive">
                                         <table className="table table-bordered  lagna-table mx-auto">
                                            <tbody>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index < 4 && (
                                                    <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td>
                                                )
                                                ))}
                                            </tr>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index > 3 && index < 8 && (
                                                    index === 5 && val === null ? (
                                                    <td colSpan={2} rowSpan={2}></td>
                                                    ) : (
                                                    index !== 6 ? <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td> : null
                                                    )
                                                )
                                                ))}
                                            </tr>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index > 7 && index < 12 && (
                                                val != null &&(
                                                    <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td>
                                                )
                                                )
                                                ))}
                                            </tr>
                                            <tr >
                                            {freeKundli?.southlagnaKundli?.map((val, index) => (
                                                index > 11 && index < 16 && (
                                                    <td key={index}>
                                                    {val?.place}<br />
                                                    {val?.planets}
                                                    </td>
                                                )
                                                ))}
                                            </tr>

                                            </tbody>
                                        </table> 



                                    </div>

                                </div>

                                <div className=' col-sm-12 '>
                                    <div className="basicDetails-txt text-center mb-2">Navamsat</div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered lagna-table mx-auto">
                                            <tbody>
                                                <tr >
                                                    <td>Name</td>
                                                    <td>Shubham Yadav</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr >
                                                    <td >Time</td>
                                                    <td colSpan={2} rowSpan={2}></td>
                                                    <td  ></td>
                                                </tr>
                                                <tr >
                                                    <td >Time</td>
                                                    <td></td>
                                                </tr>
                                                <tr >
                                                    <td>Timezone</td>
                                                    <td>GMT+5.5</td>
                                                    <td>Sunrise</td>
                                                    <td>5:27:46</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>


                </Tab.Container>


            </div>
                )}  
                


        </>
    )
}

export default SecondTabKundali