import React from 'react'
import Lottie from "react-lottie";
import circle_json from "../../animation_ll0jl2jh.json"
import profileimg from "../../../chat-astrologer/image/profile.png"
import "./TalkToAstrolger.css"
const TalkToAstrolger = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: circle_json,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
      const { profilePic } = props;
  return (
    <>
     <section className='lottie-animation-div'>
        <div className='talkToAstrolger-lottie mx-auto'>
          <Lottie 
            options={defaultOptions}/>
           <div class="second-img ">
                  <img src={profilePic} class="profile-img" />
                   
           </div>
        </div>
    </section>
    </>
  )
}

export default TalkToAstrolger