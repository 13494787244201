import React from 'react'
import { useState, useEffect } from "react";
import './FreeKundali.css';
import { Link, useNavigate } from "react-router-dom";
import '../Kundali/Kundali.css'
import searchimg from '../Kundali/image/search.png';
import deleteimg from '../Kundali/image/delete-sweep.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Date_icon from "../Images/Date_icon.png";


import Cookies from "js-cookie";
import { useContext } from "react";
import { Context } from "../../utils/context";
const FreeKundali = () => {
    const [startdate, setStartDate] = useState(new Date());

const navigate = useNavigate();


    const { getData, mainPage, setMainPage, zoneid, setZoneid, date, setDate, imageUrl} = useContext(Context)
    const [cookiedata, setSavedKundli] = useState();

    // const [banners, setBanner] = useState();
    const [validError, setValidError] = useState(false);
    const handleValidation = async () => {
        var name = document.getElementById('username').value;
        var birth = document.getElementById('birth').value;
        var gender = document.getElementById('gender').value;
        var dob = document.getElementById('dob').value;
        var time = document.getElementById('time').value;
        var ampm = document.querySelector('input[name="ampm"]:checked').value;

        console.log(name + " " + birth + " " + gender + " " + dob + " " + time + " " + ampm, 'forms');
        if (name == "" || birth == "" || gender == "" || dob == "" || time == "" || ampm == "") {
            setValidError(true);
        } else {

                    // Initialize saveData as an empty array
                    var saveData = [];

                    // Check if the 'savedFreeKundli' cookie exists
                    if (Cookies.get('savedFreeKundli')) {
                        // Parse the existing data from the cookie and assign it to saveData
                        saveData = JSON.parse(Cookies.get('savedFreeKundli'));
                    }

                    // Create a new userData object
                    var userData = {};
                    userData['name'] = name;
                    userData['place'] = birth;
                    userData['gender'] = gender;
                    userData['dob'] = dob;
                    userData['time'] = time;
                    userData['ampm'] = ampm;

                    // Push the new userData into saveData
                    saveData.push(userData);

                    // Store the updated saveData array in the 'savedFreeKundli' cookie as JSON
                    Cookies.set('savedFreeKundli', JSON.stringify(saveData), { expires: 1 });
               
                console.log(userData,'userData');

                // setArray(oldArray => [newValue,...oldArray] );
                Cookies.set('freekundlidata', JSON.stringify(userData), { expires: 1 });
                navigate('/Basic-Kundali');

        }
    };

    const handleChange = async () => {
        setValidError(false);
    };


    const savedCookieKundli = async (index) => {
        console.log(JSON.parse(Cookies.get('savedFreeKundli'))[index], 'SavedKundli');
        var savekundli = JSON.parse(Cookies.get('savedFreeKundli'))[index];
        Cookies.set('freekundlidata', JSON.stringify(savekundli), { expires: 1 });
        navigate('/Basic-Kundali');
    };



    // const getBanners = async () => {
    //     const res = await getData("/banner")
    //     if (res?.success) {
    //         console.log(res.data, 'banners');
    //         setBanner(res.data.list);
    //     }
    // };

    useEffect(() => {
        
        if(Cookies.get('savedFreeKundli')){
            console.log(JSON.parse(Cookies.get('savedFreeKundli')), 'SavedKundli');
            setSavedKundli(JSON.parse(Cookies.get('savedFreeKundli')));
        }
        // getBanners();

    }, []);
    return (
        <>
            <section className='Kundali desktop-view-kundali'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li className="breadcrumb-item active" ><Link to='/freekundali'>Free Kundli</Link></li>
                                </ol>
                            </nav>

                            <div className='heading-holder '>
                                <h2>New Kundali </h2>
                            </div>
                            <div className="main-heading mb-5">
                                <div className="line me-2 mt-1"></div>
                                <div className="square"></div>
                                <div className="line ms-2 mt-1"></div>
                            </div>
                        </div>
                    </div>
                    <div className='row get-kundali-modal '>
                        <div className='col-xl-6 col-lg-6 col-md-12'>
                            <div className='form-section-kundli'>
                                <div className='form-bg1 '>
                                    <form className="row g-3 " id="freeKundaliForm"

                                    >
                                        <div className="col-md-4">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="heading-holder">
                                                <h6>Details</h6>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="border-dott mt-md-3 mt-0"></div>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control input-mandatory"
                                                name="name"
                                                id="username"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputtext4" className="form-label">
                                                Birth Place*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control input-mandatory"
                                                id="birth"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputemail" className="form-label ">
                                                Gender*
                                            </label>
                                            <select
                                                className="form-select input-mandatory"
                                                aria-label="Default select example"
                                                id="gender"
                                                onChange={handleChange}
                                            >
                                                <option value="male" selected>Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-6 col-lg-7 col-md-6 col-xs-6 ">
                                                <div className="row">
                                                    <div className="col-lg-7 col-md-7 col-sm-7">
                                                        <div className="mt-1">
                                                            <label for="inputAddress2" className="form-label">
                                                                Date of Birth*
                                                            </label>
                                                            <div className="input-group">
                                                                <span
                                                                    class="input-group-text"
                                                                    id="basic-addon1"
                                                                >
                                                                    <img src={Date_icon} className="Date_icon" />
                                                                </span>

                                                                <div className="DatePick">
                                                                    <DatePicker
                                                                           
                                                                        selected={startdate}
                                                                        onChange={(date) => setStartDate(date)}
                                                                        id="dob"
                                                                        dateFormat="dd/MM/yyyy"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 ">
                                                        <label
                                                            for="inputCity"
                                                            className="form-label mt-3"
                                                        ></label>
                                                        <input
                                                            type="time"
                                                            className="form-control input-mandatory mt-2"
                                                            id="time"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-xl-6 col-lg-5 col-md-6 col-xs-6 ">
                                                <div className="form-radio1 ">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input input-mandatory"
                                                            type="radio"
                                                            name="ampm"
                                                            id="flexRadioDefault1"
                                                            value="AM"
                                                            onChange={handleChange}
                                                            checked
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault1"
                                                        >
                                                            AM
                                                        </label>
                                                    </div>
                                                    <div className="form-check  ">
                                                        <input
                                                            className="form-check-input input-mandatory"
                                                            type="radio"
                                                            name="ampm"
                                                            id="flexRadioDefault2"
                                                            onChange={handleChange}
                                                            value="PM"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            for="flexRadioDefault2"
                                                        >
                                                            PM
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <span className="text text-danger">{validError ? "All Fields Are Mandatory...!" : ""}</span>
                                </div>
                                <div className="col-lg-12 ">
                                    {/* <Link to='/Basic-Kundali'> */}
                                    <button className="btn btn-match " onClick={handleValidation}>Get Horoscope</button>
                                    {/* </Link> */}
                                </div>

                            </div>
                        </div>

                        <div className='col-xl-5 col-lg-6 col-md-12 mx-auto mt-lg-0 mt-md-3 mt-sm-3 mt-3'>
                            <div className='form-bg p-4 '>
                                <form className="row g-3">
                                    <div className='col-md-4'>
                                        <div className='border-dott mt-3'></div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='heading-holder'>
                                            <h6>Saved Kundali</h6>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='border-dott mt-md-3 mt-0'></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className='recently'> Recently Opened</p>

                                    </div>
                                    <div className="col-sm-6">
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control search-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                            <span class="input-group-text search_img" id="basic-addon1"><img src={searchimg} className='search-img' /></span>
                                        </div>
                                    </div>

                                   
                                    <div className="col-12">
                                    {cookiedata?.map((val, index) => ( 
                                        <div className='card mb-5'>
                                            <div className='card-body'>
                                                <div className='d-flex'>
                                                    <div className='name-holder me-3 mt-3'>
                                                        <h6 className='pt-2 name-identity'>{val?.name.split(" ").map((n)=>n[0]).join("")}</h6>
                                                    </div>
                                                        
                                                    <div className='address-holder'
                                                    onClick={() => {
                                                        savedCookieKundli(index);
                                                      }}
                                                    >
                                                        <h6>{val?.name}</h6>
                                                        <p>{val?.dob}, {val?.time} {val?.ampm}</p>
                                                        <span>{val?.place}</span>
                                                    </div>
                                                        

                                                    <div className='delete-icon '>
                                                        <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                        

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            {/* <!--delete Modal start--> */}
            <section class="modal-delete">
                <div class="modal fade" id="deleteModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            </div>
                            <div class="modal-body">
                                <div className='delete-text text-center'>
                                    <h5>Are you sure you want to Delete this  Kundali</h5>
                                </div>
                                <div className='delete-button text-center my-4'>
                                    <button className='btn btn-cancle me-3'>Cancel</button>
                                    <button className='btn btn-ok'>Ok</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <!---delete-modal-end */}

            {/* mobile-view-start */}

            {/* <section className='Kundali mobile-view-kundali'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to='/'>Home {'>'} </Link></li>
                                    <li className="breadcrumb-item active" ><Link to='/freekundali'>Free Kundli</Link></li>
                                </ol>
                            </nav>

                            <div className='heading-holder '>
                                <h2>New Kundali </h2>
                            </div>
                            <div className="main-heading mb-4">
                                <div className="line me-1 "></div>
                                <div className="square"></div>
                                <div className="line ms-1 "></div>
                            </div>
                        </div>
                    </div>
                    <div className='row get-kundali-modal '>
                        <div className='col-md-12'>
                            <div className='form-section-kundli'>
                                <div className='form-bg1 '>
                                    <form className="row ">
                                        <div className="col-sm-4 col-4 pe-0">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-sm-4 col-4">
                                            <div className="heading-holder">
                                                <h6>Detail</h6>
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-4 ps-0">
                                            <div className="border-dott mt-3"></div>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputEmail4" className="form-label">
                                                Name*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputEmail4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputtext4" className="form-label">
                                                Birth Place*
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="inputtext4"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <label for="inputemail" className="form-label ">
                                                Gender*
                                            </label>
                                            <select
                                                className="form-select "
                                                aria-label="Default select example"
                                            >
                                                <option selected>Male</option>
                                                <option value="1">Female</option>
                                            </select>
                                        </div>

                                        <div className=" col-sm-8 ">
                                            <div className="row">
                                                <div className=" col-sm-7 col-7 ">
                                                    <div className="mt-1">
                                                        <label for="inputAddress2" className="form-label">
                                                            Date of Birth*
                                                        </label>
                                                        <div className="input-group">
                                                            <span
                                                                class="input-group-text"
                                                                id="basic-addon1"
                                                            >
                                                                <img src={Date_icon} className="Date_icon" />
                                                            </span>

                                                            <div className="DatePick">
                                                                <DatePicker
                                                                    selected={startdate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5 col-5 ">
                                                    <label
                                                        for="inputCity"
                                                        className="form-label mt-3"
                                                    ></label>
                                                    <input
                                                        type="time"
                                                        className="form-control mt-2"
                                                        id="inputCity"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-sm-4  ps-0">
                                            <div className="form-radio1 ">
                                                <div className="form-check ">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault1"
                                                        checked
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault1"
                                                    >
                                                        AM
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="flexRadioDefault"
                                                        id="flexRadioDefault2"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        for="flexRadioDefault2"
                                                    >
                                                        PM
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <div className="col-lg-12 ">
                                    <Link to='/Basic-Kundali'><button className="btn btn-match ">Get Horoscope</button></Link>
                                </div>
                            </div>
                        </div>

                        <div className=' col-md-12 mb-5'>
                            <div className='form-bg mt-5 p-4 '>
                                <form className="row g-2">
                                    <div className="col-sm-4 col-3 ">
                                        <div className="border-dott mt-3"></div>
                                    </div>
                                    <div className='col-sm-4 col-6 mx-auto'>
                                        <div className='heading-holder'>
                                            <h6>Saved Kundali</h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-3 mx-auto">
                                        <div className="border-dott mt-3"></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <p className='recently'> Recently Opened</p>

                                    </div>
                                    <div className="col-sm-6 ">
                                        <div class="input-group mb-3">
                                            <input type="text" class="form-control search-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" />
                                            <span class="input-group-text search_img" id="basic-addon1"><img src={searchimg} className='search-img' /></span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className='card '>
                                            <div className='card-body'>
                                                <div className='d-flex'>
                                                    <div className='name-holder me-3 mt-3'>
                                                        <h6 className='pt-2 name-identity'>SK</h6>
                                                    </div>

                                                    <div className='address-holder'>
                                                        <h6>Shubham Kumar</h6>
                                                        <p>21 Aug 2023, 12:45 PM</p>
                                                        <span>Pune, Maharashtra. India.</span>
                                                    </div>

                                                    <div className='delete-icon '>
                                                        <img src={deleteimg} className='delete-img me-md-3 me-0' type="button" data-bs-toggle="modal"
                                                            data-bs-target="#deleteModal1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </section> */}

        </>
    )
}

export default FreeKundali