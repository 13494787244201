import React, { useEffect, useState, useRef } from 'react'
import profileimg from "../../../home-page/image/profile.png";
import successgif from "../../../home-page/image/success.gif";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faLock, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import dummyimg from '../../../home-page/image/dummy-user.png';
import editImg from "../Images/Edit_img.png";



import { useContext } from "react";
import { Context } from "../../../../utils/context";
const EditProfile = () => {

  
  const [showModal, setShowModal] = useState(false);
  const { getData, postData,postFormData, imageUrl } = useContext(Context)

  const [userData , setUserData] = useState();
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);

  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [fileName, setFileName] = useState("null");
  const [file, setFile] = useState("null");
  
  const getUserData = async () => {
    const res = await getData('/userData');
    console.log(res,'userData');
    if(res?.success){
      setUserData(res.data.user);
    }
  }

  const handleSubmit = async () => {
    var username = document.getElementById('username').value;
    var email = document.getElementById('email').value;
    var mobile = document.getElementById('mobile').value;

    // Initialize an array to store error messages
    var errors = [];

    if (username.trim() !== "") {
      if (!/^[a-zA-Z ]+$/.test(username)) {
        setNameErr('Username should contain only letters and spaces');
        errors.push('Username should contain only letters and spaces');
    }
  }

    if (email.trim() !== "") {
       if (!/^\S+@\S+\.\S+$/.test(email)) {
        setEmailErr('Email is not valid');
        errors.push('Email is not valid');
    }
  }

    if (mobile.trim() === "") {
        setMobileErr('Mobile is required');
        errors.push('Mobile is required');
    }
    else if (!/^\d{10}$/.test(mobile)) {
        setMobileErr('Mobile should be a 10-digit number');
        errors.push('Mobile should be a 10-digit number');
    }

    if (errors.length === 0) {
      console.log(username + " " + email + " " + mobile , 'forms');
      var res;
        if(file != "null"){
          const profile = new FormData();
            profile.append("profile", file);

            // First request for updating user information
            if (email.trim() !== "") {
            res = await postData(`/userUpdate?name=${username}&email=${email}&mobile=${mobile}`);
            }else{
              res = await postData(`/userUpdate?name=${username}&mobile=${mobile}`);
            }

            // Second request for updating user profile pic
            await postFormData(`/userProfilePic`, profile);

        }else{
           if (email.trim() !== "") {
            res = await postData(`/userUpdate?name=${username}&email=${email}&mobile=${mobile}`);
            }else{
              res = await postData(`/userUpdate?name=${username}&mobile=${mobile}`);
            }
        }
      
      console.log(res, 'register'); 
      if (res?.success) { 

         // Open the modal
         setShowModal(true);

         // Delay navigation by 2 seconds (2000 milliseconds)
         setTimeout(() => {
          window.location.href = '/profle';
         }, 2000);

      }else{
          console.log(res.response.data.message, 'fail');
          
          if(res.response.data.message.email){
              setEmailErr(res.response.data.message.email);
          }
          if(res.response.data.message.mobile){
              setMobileErr(res.response.data.message.mobile);
          }
      }

  } 
  }
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files.length != 0) {
      console.log(event.target.files[0]);
      setFileName(event.target.files[0].name);
      setFile(event.target.files[0]);

      var image = document.getElementById('profile_pic');
      const files = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      image.src = e.target.result;
    };

    reader.readAsDataURL(files);
    }
  };

  useEffect(() => {
   
    getUserData();
  }, []);
  return (
    <>
     <section className='Profile'>
        <div className='container-fluid'>
          <div className='main_profile_section'>
            <div className='profile_img_holder text-center'>
              <img src={userData?.profile_pic == null ? dummyimg : imageUrl + userData?.profile_pic} className='profile_img' id="profile_pic"/>
              <div className='edit_img' onClick={handleClick}>
                <img src={editImg} />
              </div>
              </div>
              <p className='profileName_txt mt-3'>{userData?.name}</p>
              <p className='profileNo'>{userData?.mobile_number}</p>
              <div className="main-heading mb-4 mt-2">
                <div class="line me-2"></div>
                <div class="square"></div>
                <div class="line ms-2"></div>
              </div>
             
              <input
                            type="file"
                            id="bloodpdf"
                            ref={hiddenFileInput}
                            accept="image/jpeg, image/gif"
                            value=""
                            onChange={handleChange}
                            hidden
                          ></input>
              <div class="input-group  mb-3">
                <span class="input-group-text" id="basic-addon1">   <FontAwesomeIcon icon={faUser} /></span>
                <input type="text" class="form-control " placeholder="Username" id="username" defaultValue={userData?.name} aria-label="Username" aria-describedby="basic-addon1" />
              </div>
              <span className='text text-danger'>{nameErr && (nameErr)}</span>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                <input type="text" class="form-control" placeholder="Email" id="email" defaultValue={userData?.email} aria-label="Username" aria-describedby="basic-addon1" 
                 onChange={() => (setEmailErr(""))}
                />
              </div>
                <span className='text text-danger'>{emailErr && (emailErr)}</span>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faPhone} /></span>
                <input type="text" class="form-control" placeholder="Contact No"  minlength="10" maxlength="10"  id="mobile" defaultValue={userData?.mobile_number} aria-label="Username" aria-describedby="basic-addon1" 
                onKeyDown={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                  if(event.key != 'Backspace')
                  event.preventDefault();
                  }
              }}  onChange={() => (setMobileErr(""))}
                />
              </div>
                <span className='text text-danger'>{mobileErr && (mobileErr)}</span>

              <div className='text-center'>
              <button className='btn save_btn' type='button' onClick={handleSubmit}> Save</button>

            </div>
          </div>
        </div>
      </section>

      
            {/* **********<!--call Modal start--> ************/}
            <section className="modal-chat">
                <div 
                className= {showModal ? "modal fade show" : "modal fade"}
                id="talkModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                style={{ 
                    display: showModal ? 'block' : 'none'
                }}
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-holder text-center">
                                    <img src={successgif} />
                                </div>
                                <div className="text-holder text-center">
                                    <h5>Profile Updated Successfully...!</h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* **********<!--call Modal end--> ************/}
    </>
  )
}

export default EditProfile