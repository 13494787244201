import React, { useEffect, useState } from 'react'
import "./Profile.css"
import profileimg from "../../home-page/image/profile.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faLock, faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import dummyimg from '../../home-page/image/dummy-user.png';



import { useContext } from "react";
import { Context } from "../../../utils/context";
const Profile = () => {

  
  const { getData, imageUrl } = useContext(Context)

  const [userData , setUserData] = useState();
  
  const getUserData = async () => {
    const res = await getData('/userData');
    console.log(res,'userData');
    if(res?.success){
      setUserData(res.data.user);
    }
  }
  

  useEffect(() => {
   
    getUserData();
  }, []);
  return (
    <>
      <section className='Profile'>
        <div className='container-fluid'>
          <div className='main_profile_section'>
            <div className='profile_img_holder text-center'>
              <img src={userData?.profile_pic == null ? dummyimg : imageUrl + userData?.profile_pic} className='profile_img' />
              </div>
              <p className='profileName_txt mt-3'>{userData?.name}</p>
              <p className='profileNo'>{userData?.mobile_number}</p>
              <div className="main-heading mt-2">
                <div class="line me-2"></div>
                <div class="square"></div>
                <div class="line ms-2"></div>
              </div>
              <Link to={'/editprofile'}>
                <p className='text-center mt-3 edit_txt_profile'>Edit <FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></p>
              </Link>

              <div class="input-group  mb-3">
                <span class="input-group-text" id="basic-addon1">   <FontAwesomeIcon icon={faUser} /></span>
                <input type="text" class="form-control " placeholder={userData?.name} aria-label="Username" aria-describedby="basic-addon1" readOnly/>
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faEnvelope} /></span>
                <input type="text" class="form-control" placeholder={userData?.email}  aria-label="Username" aria-describedby="basic-addon1" readOnly/>
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faPhone} /></span>
                <input type="text" class="form-control" placeholder={userData?.mobile_number} aria-label="Username" aria-describedby="basic-addon1" readOnly/>
              </div>
           
          </div>
        </div>
      </section>
    </>
  )
}

export default Profile